import React, { forwardRef } from 'react';
import './style.scss';

export default forwardRef(({ id, accept }, ref) =>
    <div className='uploadFile'>
        <label className="filePlaceholder" htmlFor={id}>
            <img src='/images/uploadImg.svg' alt='upload' />
        </label>
        <input type="file" id={id} ref={ref} accept={accept || ''} />
    </div>
)