import React, { useState } from 'react';
import Header from '../../components/header';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import Chat from './chat';
import { formatDate } from '../../lib/helpers';
import './style.scss';

const pageSize = 12

const ChatBlock = ({ username, anotherUsername, userPhoto, anotherUserPhoto, lastMsg, msgDate, onClick }) =>
    <div className='col-md-6 col-lg-4'>
        <div className='chatBlock' onClick={onClick}>
            <div className='top'>
                <span className='name'>{username} - {anotherUsername}</span>
                <span>{formatDate(new Date(msgDate))} - {new Date(msgDate).getHours()}:{new Date(msgDate).getMinutes()}</span>
            </div>

            <div className="bottom">
                <div className='lastMsg'>
                    {lastMsg.length > 30 ? lastMsg.substring(0, 27) + '...' : lastMsg}
                </div>
                <div>
                    <img src={userPhoto} alt="shopper" className="avatar" />
                    <img src={anotherUserPhoto} alt="traveler" className="avatar" />
                </div>
            </div>
        </div>
    </div>

export default () => {
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const { data, totalCount } = useFetch(`usersToUserChat/all?page=${page}${search?`&search=${search}`:''}&limit=${pageSize}`)
    const [chatID, setChatID] = useState(null)

    return (
        <div className='usersChat'>
            <Header title="Users Chat" setSearch={setSearch} />

            <div className='row'>
                {data && data.map((chat, i) =>
                    <ChatBlock key={i} username={chat.shopperId?.username} anotherUsername={chat.travelerId?.username}
                        userPhoto={chat.shopperId?.photo} anotherUserPhoto={chat.travelerId?.photo}
                        lastMsg={chat.unReadMessage} msgDate={chat.updatedAt}
                        onClick={() => setChatID(chat._id)}
                    />
                )}
            </div>

            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />

            {chatID && <Chat chatID={chatID} onClose={() => setChatID(null)} />}
        </div>
    );
}