import { useEffect, useState, useRef } from 'react';
import jwt_decode from "jwt-decode";
import axios from 'axios'
import { useAuth } from '../state/auth';
import { useError } from "../state/error";
import { baseUrl } from '../lib/constants';
import { useLoading } from '../state/loading';

export const useFetch = (url, reload = false, hideLoading) => {
  const isCurrent = useRef(true);
  const [state, setState] = useState({
    data: null,
    loading: true,
    totalCount: null
  });
  const { authTokens, setAuthTokens } = useAuth();
  const { setIsError } = useError();
  const { setLoader } = useLoading()

  const handleChange = (e) => {
    let tmp = state.data

    if (e.target.name.includes('.')) {
      let splited = e.target.name.split('.')
      tmp[splited[0]][splited[1]] = e.target.value
    }
    else
      tmp[e.target.name] = e.target.value;

    setState({
      ...state,
      ...tmp
    })
  }


  const callAPI = (newToken) => {
    setState(state => ({ data: state.data, loading: true }))
    axios.get(`${baseUrl}/${url}`, { headers: { Authorization: newToken || authTokens } })
      .then(response => {
        if (response.status === 200 || response.data.data.status === 200) {
          debugger
          if (isCurrent.current) {
            if (!hideLoading)
              setLoader(l => l - 1)
            setState({
              data: response.data.data,
              loading: false,
              totalCount: response.data.totalCount
            });
          }
        }
        else {
          setIsError(response.data.data)
          setLoader(l => l - 1)
        }
      }).catch(error => {
        setIsError(error?.response?.data?.message || error.response?.data || error.message)
        console.log(error?.response)
        setLoader(l => l - 1)
      })
  }

  useEffect(() => { return () => { isCurrent.current = false } }, []);

  useEffect(() => {
    if (url.includes('undefined') || url.includes('null'))
      return

    if (!hideLoading)
      setLoader(l => l + 1)

    let decodedToken = jwt_decode(authTokens)
    if (new Date(decodedToken.exp * 1000) < new Date()) {
      axios.get(`${baseUrl}/admins/refreshToken`, { headers: { Authorization: authTokens } }).then(response => {
        setAuthTokens(response.data.token)
        callAPI(response.data.token)
      }).catch(error =>
        setIsError(error?.response?.data?.message || error.response?.data || error.message)
      )
    }
    else
      callAPI()
  }, [url, reload]);
  return { ...state, handleChange: handleChange };
}
