import React, { useEffect, useState } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { useFetch } from "../../../lib/useFetch";
import "./style.scss";

export default ({ show, hide, priceID, reload }) => {
  const { data, loading } = useFetch(`prices/${priceID}`);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [rewardPercent, setRewardPercent] = useState("");
  const [deliveryFees, setDeliveryFees] = useState("");
  const [percentFromReward, setPercentFromReward] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (data && !loading) {
      setPriceFrom(data.priceFrom);
      setPriceTo(data.priceTo);
      setRewardPercent(data.rewardPercent);
      setDeliveryFees(data.deliveryFees);
      setPercentFromReward(data.voyshipPercentFromTravelerReward);
    }
  }, [loading]);

  useEffect(() => {
    if (priceID)
      return

    setPriceFrom("");
    setPriceTo("");
    setRewardPercent("");
    setDeliveryFees("");
    setPercentFromReward("");
  }, [show,priceID]);

  const savePrice = async (e) => {
    e.preventDefault();

    if (
      !Number(priceFrom) || !Number(priceTo) ||
      !Number(rewardPercent) || !Number(deliveryFees) ||
      !Number(percentFromReward)
    ) {
      Alert("please fill all fields");
      return;
    }

    let price = {
      priceFrom,
      priceTo,
      rewardPercent,
      deliveryFees,
      voyshipPercentFromTravelerReward: percentFromReward
    };

    if (priceID) price._id = priceID;

    confirmAlert(`${priceID ? "edit" : "add"} this price?`, () => {
      setIsloading(true);

      if (!priceID)
        PostObject("prices", price).then(() => {
          afterOperation();
        });
      else
        PutObject("prices", price).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
  };

  return (
    <Popup
      show={show}
      className="priceAddEdit"
      modalID="addEditPriceModal"
      title={`${priceID ? "Edit" : "Add"} Price`}
    >
      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="Price From"
              value={priceFrom}
              setValue={setPriceFrom}
              placeholder="write price from"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Price To"
              value={priceTo}
              setValue={setPriceTo}
              placeholder="write price to"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Delivery Fees(%)"
              value={deliveryFees}
              setValue={setDeliveryFees}
              placeholder="write deliveryFees"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Reward Fees(%)"
              value={rewardPercent}
              setValue={setRewardPercent}
              placeholder="write reward fees"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Voyship Percent From Reward(%)"
              value={percentFromReward}
              setValue={setPercentFromReward}
              placeholder="write Voyship percent from reward"
            />
          </div>
        </div>

        <button className="saveBtn" onClick={savePrice} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {priceID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
