import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/header';
import Card, { Controls } from '../../../components/infoCard';
import PaginationBar from '../../../components/pagination';
import ImageModal from '../../../components/imageModal';
import { useFetch } from '../../../lib/useFetch';
import DeleteObject from '../../../lib/deleteObject';
import { confirmAlert } from '../../../components/alert';
import { pageSize } from '../../../lib/constants';
import AddEditCity from './addEdit';

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { countryID } = useParams()
    const { data, totalCount } = useFetch(`cities?limit=10&countryId=${countryID}&page=${page}${search ? `&search=${search}` : ''}&limit=${pageSize}`, reload)
    const [zoomedImage, setZoomedImage] = useState(null)
    const [editedCityID, setEditedCityID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)

    const onDelete = (cityID) => {
        DeleteObject('cities', cityID, () => toggleReload(r => !r))
    }

    return (
        <div className='cities'>
            <Header title="Cities" setSearch={setSearch}>
                <button onClick={() => { setEditedCityID(null); toggleShowAddEdit(s => !s) }}>
                    Add City
                </button>
            </Header>

            <div className='row pt-4'>
                {data &&
                    data.map(city =>
                        <Card key={city._id}
                            image={city.photo}
                            setZoomedImage={setZoomedImage}>
                            <div className='mb-2 text-center'>{city.name}</div>
                            <div className='text-center'>{city.cityCode}</div>

                            <Controls>
                                <button onClick={() => { setEditedCityID(city._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => confirmAlert('delete this city?', () => onDelete(city._id))}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            <AddEditCity cityID={editedCityID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedCityID(null) }} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }
        </div>
    );
}