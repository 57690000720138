import React from 'react';
import './style.scss';

export default ({ children, image, leftText, rightText, handleLeftOnClick, handleRightOnClick, setZoomedImage }) =>
    <div className='col-md-6 col-lg-4'>
        <div className='infoCard'>
            {leftText &&
                <span className={`topInfo topLeft ${handleLeftOnClick ? 'pointer' : ''}`} onClick={handleLeftOnClick}>
                    {leftText}</span>
            }

            <img src={image} className={`profile ${setZoomedImage?'pointer':''}`} alt='profile' onClick={() => { if (setZoomedImage) setZoomedImage(image) }} />

            {rightText &&
                <span className={`topInfo topRight ${handleRightOnClick ? 'pointer' : ''}`} onClick={handleRightOnClick}>
                    {rightText}</span>
            }

            {children}
        </div>
    </div>

export const Controls = ({ children }) =>
    <div className='controls'>{children}</div>