import React, { useState } from 'react';
import { useFetch } from '../../../lib/useFetch';
import PostObject from '../../../lib/postObject';
import { confirmAlert } from '../../../components/alert';
import PayoutInfo from '../../../components/payoutInfo';

export default ({ id, reload }) => {
    const { data: payout } = useFetch(`payouts/${id}`)
    const { data: payoutInfo } = useFetch(`payoutsInfo/${payout?.payoutInfo}`)
    const [isLoading, setIsLoading] = useState(false)

    const approve = () => {
        confirmAlert('approve this payout?', () => {
            setIsLoading(true)
            PostObject(`payouts/${id}/approve`, {}).then(() => {
                setIsLoading(false)
                reload()
            })
        })
    }

    return (
        payoutInfo &&
        <div className='requestedPayout'>
            <div className='head'>
                <div>
                    <img src={payoutInfo.payoutMethod === 'Wallet' ? '/images/wallet.png' : '/images/bank.png'} alt='method' className='method' />
                    <span className='name'>{payoutInfo.payoutMethod}</span>
                </div>

                <span className='amount'>${payout.amount}</span>
            </div>
            <div>
                <PayoutInfo payoutInfo={payoutInfo} />

                {payout.state === "Pending" &&
                    <button onClick={approve} disabled={isLoading} className='approveBtn'>
                        <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                        Approve
                    </button>
                }
            </div>
        </div>
    );
}