import React, { useState } from "react";
import Popup from "../../components/popup";
import Field from "../../components/field";
import { Alert, confirmAlert } from "../../components/alert";
import PostObject from "../../lib/postObject";
import "./style.scss";

export default ({ show, hide, notificationID }) => {
  const [emails, setEmails] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const send = (e) => {
    e.preventDefault();

    if (emails.trim() === "") {
      Alert("please fill all fields");
      return;
    }

    const notification = {
      notificationId: notificationID,
      emails: emails.split(',').map(e => e.trim())
    }

    confirmAlert(`send this notification?`, () => {
      setIsloading(true);
      PostObject('notifications/notifyUsersByEmail', notification).then(() => {
        setEmails('')
        setIsloading(false)
        hide()
      });
    });
  }

  return (
    <Popup
      show={show}
      className="emailsNotificationAddEdit"
      modalID="addEditEmailsNotificationModal"
      title='Send Notification To Emails'
    >
      <form>
        <div className="row">
          <div className="col-12">
            <Field
              label="Emails"
              value={emails}
              setValue={setEmails}
              placeholder="write emails with ',' between each one and another"
            />
          </div>
        </div>

        <button className="saveBtn" onClick={send} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          Send
        </button>
      </form>
    </Popup>
  );
};
