import React from 'react';
import Popup from '../../../components/popup';
import { useFetch } from '../../../lib/useFetch';
import './style.scss';

const Block = ({ photo, fullname, username, usageCount }) =>
    <div className='userBlock'>
        <img src={photo} alt='user' />

        <div className='info'>
            <div>{fullname}</div>
            <div className='smallFont'>{username}</div>
            <div className='smallFont'>{usageCount} times</div>
        </div>
    </div>

export default ({ show, promoID }) => {
    const { data } = useFetch(`promocodes/${promoID}/consumers`)

    return (
        <Popup show={show} className='promoStats' modalID='promoStatsModal' title="Promocode Statistics">
            <img src='/images/promocode.png' className='promoImage' alt='promocode' />
            <div className='promoName'>{data?.name}</div>

            <div className='title'>Users use promocode</div>
            {data?.consumers.map((c, i) =>
                <Block photo={c.photo} usageCount={c.count} username={c.username} fullname={c.firstName + ' ' + c.lastName} />
            )}
        </Popup>
    );
}