import React from 'react';
import { Dimmer, Table, Card, Button, Page, Form } from "tabler-react";
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';

const RecordsTable = ({
  headers,
  records,
  openMainModal,
  loading,
  reload
}) => {
  const onDelete = (FAQID) => {
    DeleteObject('settings/FAQ', FAQID, reload)
  }

  return (
    <>
      <Page.Header>
        <Page.Title>FAQ</Page.Title>
      </Page.Header>
      <Card>
        <Dimmer
          active={loading}
          loader={loading}
        >
          <Card.Header>
            <Card.Options>
              <Form.Input
                icon="search"
                placeholder="Search for..."
                position="append"
              />
              <Button
                className="ml-2"
                icon="plus"
                color="primary"
                size="md"
                onClick={() => openMainModal('faq-create', null, reload)}
              >
                Create FAQ
              </Button>
            </Card.Options>
          </Card.Header>
          {!loading && records.length < 1 ?
            <div className="alert alert-primary m-0">
              No FAQ to show!
            </div>
            :
            <Table
              hasOutline
              striped
            >
              <Table.Header>
                {headers.map((head, i) => (
                  <Table.ColHeader
                    key={`tableHeader-${i}`}
                  >
                    {head}
                  </Table.ColHeader>
                ))}
                <Table.ColHeader>Actions</Table.ColHeader>
              </Table.Header>
              <Table.Body>

                {records.settings.FAQ.map((r, i) => (
                  <Table.Row key={i}>
                    {Object.keys(r).map((k, i) => {
                      if (k === 'question') {
                        return (<Table.Col>
                          <p>{r['question']}</p>
                        </Table.Col>);
                      } else if (k === 'id') {
                        return false;
                      } else if (k === 'answer') {
                        return (<Table.Col>
                          <p>{r['answer']}</p>
                        </Table.Col>);
                      }
                    })}
                    <Table.Col>
                      <Button
                        outline
                        color="secondary"
                        icon=""
                        size="sm"
                        onClick={() => openMainModal('faq-edit', r['_id'], reload)}
                      >
                        Edit
                      </Button>

                      <Button
                        outline
                        color="danger"
                        icon=""
                        className="ml-2 mr-2"
                        size="sm"
                        onClick={() => confirmAlert('delete this FAQ?', () => onDelete(r._id))}
                      >
                        Delete
                      </Button>
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          }
        </Dimmer>
      </Card>
    </>
  );
}

export default RecordsTable;