import React, { useEffect, useState } from 'react';
import PostObject from '../../../../lib/postObject';
import { useFetch } from '../../../../lib/useFetch';
import './style.scss';

export default ({ shipment, offerID, actualInvoice }) => {
    const [invoice, setInvoice] = useState({})
    const { data: offer, loading } = useFetch(`offers/${offerID}`)

    useEffect(() => {
        if (!loading && offer)
            setInvoice(offer.offer.offerDetails)
        else if (!offerID && shipment) {
            const priceObj = {
                fromCity: shipment.fromCity,
                toCity: shipment.toCity,
                isHomeDelivery: shipment.isHomeDelivery,
                products: shipment.products
            }

            PostObject('prices/calculate', priceObj, true).then(data => {
                setInvoice(data.data)
            })
        }
        else if (actualInvoice)
            setInvoice(actualInvoice)
    }, [shipment, loading])

    return (
        <div className='invoice'>
            {invoice && <>
                <div className='details'>
                    <div className='spaceBetween'>
                        <span>Shipment Price</span><span>$ {invoice.shipmentPrice}</span>
                    </div>
                    <div className='spaceBetween'>
                        <span>Traveler Reward</span><span>$ {invoice.reward || invoice.travellerReward}</span>
                    </div>
                    <div className='spaceBetween'>
                        <span>VoyShip Fees</span><span>$ {invoice.voyshipFees}</span>
                    </div>
                    <div className='spaceBetween'>
                        <span>Delivery Fees</span><span>$ {invoice.deliveryFees}</span>
                    </div>
                    <div className='spaceBetween'>
                        <span>Payment Fees</span><span>$ {invoice.paymentFees?.toFixed(2)}</span>
                    </div>
                    <div className='spaceBetween'>
                        <span>Insurance Fees</span><span>$ {invoice.insuranceFees?.toFixed(2)}</span>
                    </div>

                    {invoice.discount > 0 &&
                        <div className='spaceBetween'>
                            <span>Discount</span><span>$ {invoice.discount}</span>
                        </div>
                    }

                    {offer?.offer.partialPayment > 0 &&
                        <div className='spaceBetween'>
                            <span className='text-danger'>Partial Payment</span><span className='text-danger'>- $ {offer.offer.partialPayment}</span>
                        </div>
                    }

                    {offer?.offer.usedPromocode &&
                        <div className='spaceBetween'>
                            <span>Used Promocode</span><span>{offer.offer.usedPromocode}</span>
                        </div>
                    }
                </div>

                <div className='spaceBetween total'>
                    <span>Total</span>
                    {invoice.total?.from ?
                        <span>$ {invoice.total.from} - $ {invoice.total.to}</span> :
                        <span>$ {(invoice.total + (offer?.offer.partialPayment || 0)).toFixed(2)}</span>
                    }
                </div>

                {offer?.offer.partialPayment > 0 &&
                    <div className='spaceBetween total'>
                        <span>Remaning</span>
                        <span className='text-danger'>$ {invoice.total?.toFixed(2)}</span>
                    </div>
                }
            </>}
        </div>
    );
}