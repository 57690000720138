import React from 'react';
import { useFetch } from '../../../lib/useFetch';
import PayoutInfo from '../../payoutInfo';
import './style.scss';

export default ({ userID }) => {
    const { data } = useFetch(`payoutsInfo?user=${userID}`)

    return (
        <div className='accordion' id='payouts'>
            {data && data.payoutsInfo.map((payout, i) =>
                <div key={i} className="payout">
                    <div className='head' data-toggle="collapse" data-target={`#payout${i}`} aria-expanded="false">
                        <div>
                            <img src={payout.payoutMethod === 'Wallet' ? '/images/wallet.png' : '/images/bank.png'} alt='method' className='method' />
                            <span className='name'>{payout.payoutMethod}</span>
                        </div>

                        <img src='/images/collapseArrowDown.svg' alt='arrow' className='arrow' />
                    </div>

                    <div id={`payout${i}`} className="collapse" data-parent="#payouts">
                        <PayoutInfo payoutInfo={payout} />
                    </div>
                </div>
            )}
        </div>
    );
}