export const baseUrl = "https://backend-hibagmvyaa-ez.a.run.app/api/v0"  //"http://localhost:3000/api/v0"//"http://localhost:3000/api/v0"//"https://api.voyship.com/api/v0"
export const userImg = "https://react.semantic-ui.com/images/avatar/small/matthew.png"
export const productImg = "https://i.stack.imgur.com/y9DpT.jpg"
export const googleMapAPIKey = "AIzaSyB1XPIEtDc1ftlgjui48MxcQ3u9PPH7hbI"
export const pageSize = 12
export const mainModalStyle = {
  content: {
    border: '0',
    borderRadius: '4px',
    bottom: 'auto',
    left: '50%',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '20rem',
    width: '80%',
    maxWidth: '60rem',
    background: 'transparent',
    height: 'calc(100% - 50px)',
    overlfow: 'auto'
  }
}
