import React from 'react';
import './style.scss';

export default ({ imageIcon, label, children }) => {
    return (
        <div className='infoCardBlock'>
            <div className='bLabel'>
                <img src={`/images/${imageIcon}.svg`} alt={imageIcon} className='icon' />
                {label}
            </div>

            <div className='value'>
                {children}
            </div>
        </div>
    );
}