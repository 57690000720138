import React, { useState } from 'react';
import Header from '../../components/header';
import { useFetch } from '../../lib/useFetch';
import { formatDate } from '../../lib/helpers';
import ImageModal from '../../components/imageModal';
import { useLoading } from '../../state/loading';
import { confirmAlert } from '../../components/alert';
import DeleteObject from '../../lib/deleteObject';
import AddMedia from './addMedia';
import Select from "react-select";
import './style.scss';

const pages = ["Home", "Trip", "Shipment", "Make Order"]

export default () => {
    const [showBanners, setShowBanners] = useState(false)
    const [reload, toggleReload] = useState(false)
    const [selectedPage, setSelectedPage] = useState({ label: pages[0], value: pages[0] })
    const { data } = useFetch(`media?isBanner=${showBanners}${showBanners ? `&relatedPage=${selectedPage.value}` : ''}`, reload)
    const [showAddModal, toggleShowAddModal] = useState(false)
    const [viewedImage, setViewedImage] = useState(null)
    const { setLoader } = useLoading()

    const formatTime = (d) => {
        let date = new Date(d)
        return date.getHours() + ':' + date.getMinutes()
    }

    const onDelete = (mediaID) => {
        confirmAlert('delete this media?', () => {
            setLoader(l => l + 1)
            DeleteObject('media', mediaID, () => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    return (
        <div className='mediaDiv'>
            <Header title="Media">
                <button onClick={() => toggleShowAddModal(s => !s)}>
                    Add {showBanners ? 'Banner' : 'Video'}
                </button>
            </Header>

            <div className='buttonsTabs'>
                <div className={!showBanners ? 'active' : ''} onClick={() => setShowBanners(false)}>Videos</div>
                <div className={showBanners ? 'active' : ''} onClick={() => setShowBanners(true)}>Banners</div>
            </div>

            {showBanners &&
                <div className='pagesDiv'>
                    <div className='subtitle'>{selectedPage.value}</div>

                    <Select
                        className='select'
                        value={selectedPage}
                        onChange={setSelectedPage}
                        options={pages.map(p => ({ label: p, value: p }))}
                    />
                </div>
            }

            {data?.map(d =>
                <div className='element' key={d._id}>
                    <div className='row'>
                        <div className='col-md-4 col-lg-5 col-xl-6'>
                            {!showBanners &&
                                <span>
                                    <i className="fas fa-bars"></i>
                                </span>
                            }
                            <span className='name'>{d.name}</span>
                        </div>

                        <div className='right col-md-8 col-lg-7 col-xl-6 mt-2 mt-md-0'>
                            <div className='part'>{formatDate(d.createdAt)}</div>
                            <div className='part'>{formatTime(d.createdAt)}</div>
                            <div className='part'>
                                {d.isBanner ?
                                    <span className='icons' onClick={() => setViewedImage(d.path)}>
                                        <img src='images/eye.svg' alt='show' />
                                    </span> :
                                    <a href={d.path} target="_blank" rel="noopener noreferrer">
                                        <img src='images/eye.svg' alt='show' />
                                    </a>
                                }
                            </div>
                            <div className='part'>
                                <span className='icons' onClick={() => onDelete(d._id)}>
                                    <i className="fas fa-trash-alt"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <AddMedia show={showAddModal} hide={() => toggleShowAddModal(s => !s)} isBanner={showBanners}
                currentPage={selectedPage.value} reload={() => toggleReload(r => !r)} />

            {viewedImage &&
                <ImageModal photo={showBanners ? viewedImage : null} video={showBanners ? null : viewedImage} close={() => setViewedImage(null)} />
            }
        </div>
    );
}