import React from 'react';
import './style.scss';

export default ({ tabs, selectedTab, setSelectedTab }) => {
    return (
        <div className='typesTabs'>
            {/* <div className={`tab ${'' === selectedTab ? 'active' : ''}`}
                onClick={() => setSelectedTab('')}>All {name}</div> */}
            {tabs.map((tab, i) =>
                <div className={`tab ${tab.value === selectedTab || tab === selectedTab ? 'active' : ''}`}
                    onClick={() => setSelectedTab(tab.value || tab)} key={i}>{tab.name || tab}</div>
            )}
        </div>
    );
}