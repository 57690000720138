import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import PostObject from '../../lib/postObject';
import PutObject from '../../lib/putObject';
import { formatDate } from '../../lib/helpers';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import Chat from '../../components/chat';
import Tabs from '../../components/typesTabs';
import ImageModal from '../../components/imageModal';
import UserView from './userView';
import UserAddEdit from './addEdit';
import AddPilot from './addPilot';
import PassportVerificationView from '../passportVerification/View';
import { useLoading } from '../../state/loading';
import { pageSize } from '../../lib/constants';
import './style.scss';

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [selectedRole, setSelectedRole] = useState('')
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`users?page=${page}&${selectedRole ? `role=${selectedRole}&` : ''}${search ? `search=${search}&` : ''}sort=createdAt:-1&limit=${pageSize}`, reload)
    const [chatUser, setChatUser] = useState(null)
    const [zoomedImage, setZoomedImage] = useState(null)
    const [viewedUser, setViewedUser] = useState(null)
    const [editedUserID, setEditedUserID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)
    const [showViewModal, toggleShowViewModal] = useState(false)
    const [showAddPilot, toggleShowAddPilot] = useState(false)
    const [showPassport, toggleShowPassport] = useState(false)
    const [viewedPassportID, setViewedPassportID] = useState(null)
    const { setLoader } = useLoading()
    const history = useHistory()

    const makePrime = (travelerID) => {
        confirmAlert('make this user prime?', () => {
            setLoader(l => l + 1)
            PostObject('admins/primeTraveller', { usersIds: [travelerID] }).then(() => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    const block = (userID) => {
        confirmAlert('block this user?', () => {
            setLoader(l => l + 1)
            PutObject(`users/${userID}/block`, {}).then(() => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    const unblock = (userID) => {
        confirmAlert('unblock this user?', () => {
            setLoader(l => l + 1)
            PutObject(`users/${userID}/unblock`, {}).then(() => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    const onDelete = (userID) => {
        confirmAlert('delete this user?', () => {
            setLoader(l => l + 1)
            DeleteObject('users', userID, () => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    return (
        <div className='users'>
            <Header title="Users" setSearch={setSearch}>
                <button onClick={() => toggleShowAddPilot(s => !s)}>
                    Add Pilot
                </button>

                <button onClick={() => { setEditedUserID(null); toggleShowAddEdit(s => !s) }}>
                    Add User
                </button>
            </Header>

            <Tabs tabs={["Shopper", "Traveller", "Pilot"]}
                selectedTab={selectedRole} setSelectedTab={setSelectedRole} />

            <div className='row'>
                {data &&
                    data.map(user =>
                        <Card key={user._id}
                            image={user.photo}
                            leftText="Shipments" rightText="Trips"
                            handleRightOnClick={() => history.push(`/trips/${user._id}`)}
                            handleLeftOnClick={() => history.push(`/shipments/${user._id}`)}
                            setZoomedImage={setZoomedImage}>
                            <div className='mb-2 text-center'>{user.username}</div>
                            <div className='text-center'>{user.firstName} {user.lastName}</div>

                            <div className='mt-3 spaceBetween'>
                                <div>
                                    <img src='/images/mail.svg' alt='mail' className='icon' />
                                    <span>
                                        {user.email?.length > 24 ? user.email?.substring(0, 22) + '...' : (user.email || '######')}
                                    </span>
                                </div>

                                {user.roles.indexOf('Traveller') > -1 && user.roles.indexOf('PrimeTraveller') === -1 &&
                                    <span className='green' onClick={() => makePrime(user._id)}>
                                        Make Prime
                                    </span>
                                }
                            </div>

                            <div className='mt-3 spaceBetween'>
                                <div>
                                    <img src='/images/calendar.svg' alt='calendar' className='icon' />
                                    {formatDate(new Date(user.createdAt))}
                                </div>

                                {user.roles.indexOf('Traveller') > -1 &&
                                    <span className='green' onClick={() => { setViewedPassportID(user._id); toggleShowPassport(s => !s) }}>
                                        View passport
                                    </span>
                                }
                            </div>

                            <Controls>
                                <button onClick={() => { setViewedUser(user); toggleShowViewModal(s => !s) }}>
                                    <img src='/images/view.svg' alt='view' />
                                </button>

                                <button onClick={() => setChatUser({ ID: user._id, username: user.username, photo: user.photo })}>
                                    <img src='/images/chat.svg' alt='chat' />
                                </button>

                                <button onClick={() => { setEditedUserID(user._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                {user.isBlocked ?
                                    <button className='greenBtn' onClick={() => unblock(user._id)} title='unblock'>
                                        <img src='/images/block.svg' alt='block' />
                                    </button> :
                                    <button className='danger' onClick={() => block(user._id)} title='block'>
                                        <img src='/images/block.svg' alt='block' />
                                    </button>
                                }

                                <button className='danger' onClick={() => onDelete(user._id)}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            {chatUser && <Chat user={chatUser} onClose={() => setChatUser(null)} />}

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }

            <UserView userID={viewedUser?._id} show={showViewModal} openChat={setChatUser} />

            <UserAddEdit userID={editedUserID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedUserID(null) }} />

            <AddPilot show={showAddPilot} hide={() => toggleShowAddPilot(s => !s)} />

            <PassportVerificationView userID={viewedPassportID} show={showPassport}
                hide={() => { toggleShowPassport(s => !s); setViewedPassportID(null) }} />

            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}