import React from 'react';
import LoadingProvider from '../state/loading';
import Routes from './routes';

export default () => {
    return (
        <LoadingProvider>
            <Routes />
        </LoadingProvider>
    );
}