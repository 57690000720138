import React from 'react';
import './style.scss';

export default ({ products, setZoomedImage }) =>
    products.map((product, i) =>
        <div key={i} className='popupProduct'>
            <div className='imgContainer'>
                <img src={product.picture || product.screenShotLink} alt="product"
                    onClick={() => setZoomedImage(product.picture || product.screenShotLink)} />
            </div>
            <div className='info'>
                <div className='name'>{product.name}</div>
                <div className='data'>
                    <img src='/images/category.svg' alt='category' className='icon' />
                    {product.categoryObj?.name}
                </div>

                <div className='spaceBetween'>
                    <div>
                        <div className='data'>
                            <img src='/images/link.svg' alt='icon' className='icon' />
                            <a href={product.link} target='_blank' rel='noopener noreferrer'>Visit Link</a>
                        </div>
                        <div className='data'>
                            <img src='/images/weight.svg' alt='icon' className='icon' />
                            {product.visableWeight || product.weight} kg
                        </div>
                    </div>

                    <div>
                        <div className='data'>
                            <img src='/images/cash.svg' alt='icon' className='icon' />
                            $ {product.price}
                        </div>
                        <div className='data'>
                            <i className='fas fa-shopping-bag yellowIcon'></i>
                            {product.quantity} item
                        </div>
                    </div>

                    {product.color &&
                        <div>
                            <div className='data'>
                                <i className="fas fa-window-maximize yellowIcon"></i>
                                {product.size}
                            </div>
                            <div className='data'>
                                <i className="fas fa-palette yellowIcon"></i>
                                {product.color}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )