import React, { useState } from "react";
import Header from "../../../components/header";
import Card, { Controls } from "../../../components/infoCard";
import PaginationBar from "../../../components/pagination";
import { userImg } from "../../../lib/constants";
import ImageModal from "../../../components/imageModal";
import { formatDate } from "../../../lib/helpers";
import { Link } from "react-router-dom";
import DeleteObject from "../../../lib/deleteObject";
import { confirmAlert } from "../../../components/alert";
import { useLoading } from "../../../state/loading";
import { pageSize } from "../../../lib/constants";
import { useEffect } from "react";
import axios from "axios";
import TripAddEdit from "./addEdit/index";
import "./style.scss";

const milestones = [
  "Placed",
  "In Progress",
  "Arrived",
  "Completed",
  "In Receipt",
  "Archived",
  "Rejected",
  "InActive",
];

export default () => {
  const [page, setPage] = useState(1);
  const [zoomedImage, setZoomedImage] = useState(null);
  const { setLoader } = useLoading();
  const [totalCount, setTotalCount] = useState(0);
  const [trips, setTrips] = useState([]);
  const [showAddEdit, toggleShowAddEdit] = useState(false);
  const [editedTipID, setEditedTripID] = useState(null);
  const [search, setSearch] = useState("");  
  const [reload, toggleReload] = useState(false);
  const [viewedTrip, setViewedTrip] = useState(null);
  const [showViewModal, toggleShowViewModal] = useState(false);
  
  const onDelete = (tripID) => {
    confirmAlert("delete this trip?", () => {
      setLoader((l) => l + 1);
      DeleteObject("manualTrips", tripID, (success) => {
        setLoader((l) => l - 1);

        if (success) toggleReload((r) => !r);
      });
    });
  };

  useEffect(() => {
    axios
      .get(`http://localhost:3000/api/v0/dashboardTrips?page=${page}`)
      .then((res) => {
        console.log(res.data.data.trips);
        setTrips(res.data.data.trips);
        setTotalCount(res.data.data.totalCount);
      });
  }, []);

  return (
    <div className="trips">
      <Header title="Trips" setSearch={setSearch}>
        <button onClick={() => toggleShowAddEdit((s) => !s)}>Add Trip</button>
      </Header>

      <div className="row pt-4">
        {console.log(trips)}
        {trips &&
          trips.map((trip) => (
            <Card
              key={trip._id}
              image={userImg}
              leftText="a"
              rightText={formatDate(new Date())}
              setZoomedImage={setZoomedImage}
            >
              <div className="mb-2 text-center">
                {trip.ownerObj?.firstName + " " + trip.ownerObj?.lastName}
              </div>

              <div className="mt-3 spaceBetween">
                <div>
                  <img
                    src="/images/calendar.svg"
                    alt="calendar"
                    className="icon"
                  />
                  {formatDate(new Date(trip.tripDate))}
                </div>

                {trip.milestone === milestones[1] && (
                  <Link
                    className="greenBtn"
                    to={`/Shipments?match=${trip._id}`}
                  >
                    Matched
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </Link>
                )}

                {milestones.indexOf(trip.milestone) > 0 && (
                  <Link
                    className="greenBtn"
                    to={`/shipments?tripID=${trip._id}`}
                  >
                    Shipments
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </Link>
                )}
              </div>

              <Controls>
                <button
                  onClick={() => {
                    setViewedTrip(trip._id);
                    toggleShowViewModal((s) => !s);
                  }}
                >
                  <img src="/images/view.svg" alt="view" />
                </button>

                <button className="danger" onClick={() => onDelete(trip._id)}>
                  <img src="/images/bin.svg" alt="bin" />
                </button>
              </Controls>
            </Card>
          ))}
      </div>

      {zoomedImage && (
        <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
      )}

      <TripAddEdit
        userID={editedTipID}
        show={showAddEdit}
        hide={() => toggleShowAddEdit((s) => !s)}
        reload={() => {
          toggleReload((r) => !r);
          setEditedTripID(null);
        }}
      />

      <PaginationBar
        page={page}
        totalCount={totalCount}
        setPage={setPage}
        pageSize={pageSize}
      />
    </div>
  );
};
