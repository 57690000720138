import React, { useEffect, useState } from 'react';
import Popup from '../../../components/popup';
import Field from '../../../components/field';
import { Alert, confirmAlert } from '../../../components/alert';
import PostObject from '../../../lib/postObject';
import PutObject from '../../../lib/putObject';
import Select from 'react-select';
import Stepper from './stepper';
import Products from './products';
import { useFetch } from '../../../lib/useFetch';
import DatePicker from '../../../components/dateRangePicker';
import moment from 'moment';
import './style.scss';

const shipmentTypes = [
    { label: "Shipment Only", value: "ShipmentOnly" },
    { label: "Prime Shipment", value: "PrimeShipment" },
    { label: "Purchase & Ship", value: "PurchaseAndShip" }
]

export default ({ show, hide, shipmentID, reload }) => {
    const { data, loading } = useFetch(`shipments/${shipmentID}`)
    const [shipment, setShipment] = useState({})
    const [products, setProducts] = useState([])
    const [selectedFromCountry, setSelectedFromCountry] = useState(null)
    const [selectedFromCity, setSelectedFromCity] = useState(null)
    const [selectedToCountry, setSelectedToCountry] = useState(null)
    const [selectedToCity, setSelectedToCity] = useState(null)
    const { data: countries } = useFetch(`countries/lookup?withUnions=true`);
    const { data: fromCities, loading: fromCitesLoading } = useFetch(`cities/lookup?countryId=${selectedFromCountry?.value}`);
    const { data: toCities, loading: toCitesLoading } = useFetch(`cities/lookup?countryId=${selectedToCountry?.value}`);
    const [isLoading, setIsloading] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)

    useEffect(() => {
        if (data && !loading && countries) {
            let fromCountry = countries.find(c => c._id === data.fromCity.countryId)
            setSelectedFromCountry({ label: fromCountry.name, value: fromCountry._id })

            let toCountry = countries.find(c => c._id === data.toCity.countryId)
            setSelectedToCountry({ label: toCountry.name, value: toCountry._id })

            setShipment({
                isHomeDelivery: data.isHomeDelivery,
                shipmentType: shipmentTypes.find(t => t.value === data.shipmentType),
                email: data.owner.email,
                shipmentArrivalDate: moment(data.shipmentArrivalDate).format('YYYY-MM-DD')
            })

            setProducts(data.products)
            setCurrentStep(1)
        }
    }, [loading, countries])

    useEffect(() => {
        if (data && !fromCitesLoading) {
            let city = fromCities.find(c => c._id === data.fromCity.cityId)
            setSelectedFromCity(city ? { label: city.name, value: city._id } : null)
        }
    }, [selectedFromCountry, fromCitesLoading])

    useEffect(() => {
        if (data && !toCitesLoading) {
            let city = toCities.find(c => c._id === data.toCity.cityId)
            setSelectedToCity(city ? { label: city.name, value: city._id } : null)
        }
    }, [selectedToCountry, toCitesLoading])

    useEffect(() => {
        if (!shipmentID) {
            setShipment({})
            setProducts([])
        }
    }, [show, shipmentID])

    const saveShipment = async () => {
        if (shipment.email.trim() === '' || !shipment.shipmentType
            || !selectedFromCity || !selectedToCity) {
            Alert('please fill all fields')
            return
        }

        shipment.fromCity = {
            cityId: selectedFromCity.value,
            countryId: selectedFromCountry.value,
        }

        shipment.toCity = {
            cityId: selectedToCity.value,
            countryId: selectedToCountry.value
        }

        shipment.shipmentType = shipment.shipmentType.value
        shipment.products = products

        if (shipmentID)
            shipment._id = shipmentID

        confirmAlert(`${shipmentID ? 'edit' : 'add'} this shipment?`, () => {
            setIsloading(true)

            if (!shipmentID)
                PostObject('shipments', shipment).then(() => {
                    afterOperation()
                })
            else
                PutObject('shipments', shipment).then((data) => {
                    if (data)
                        afterOperation()
                })
        })
    }

    const afterOperation = () => {
        setIsloading(false)
        reload()
        hide()
    }

    return (
        <Popup show={show} className='shipmentAddEdit' modalID='addEditShipmentModal' title={`${shipmentID ? 'Edit' : 'Add'} Shipment`}>
            <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} />

            {currentStep === 1 &&
                <>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='label'>Shipment Type</div>
                            <Select
                                className='select'
                                value={shipment.shipmentType}
                                onChange={value => setShipment(s => ({ ...s, shipmentType: value }))}
                                options={shipmentTypes}
                                placeholder="Choose Type"
                            />
                        </div>
                        <div className='col-sm-6'>
                            <Field label='User Email' value={shipment.email} setValue={(value) => setShipment(s => ({ ...s, email: value }))}
                                placeholder='write email' icon='envelope' />
                        </div>
                        <div className='col-sm-6'>
                            <div className='label'>Arrival Date</div>

                            {shipment.shipmentArrivalDate &&
                                <DatePicker startD={shipment.shipmentArrivalDate} single={true} onApply={(date) => setShipment(s => ({ ...s, shipmentArrivalDate: date }))} />
                            }
                        </div>
                    </div>

                    <div>
                        <div className='label'>Pick From</div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <Select
                                    className='select'
                                    value={selectedFromCountry}
                                    onChange={setSelectedFromCountry}
                                    options={countries?.map(c => ({ label: c.name, value: c._id }))}
                                    placeholder="From Country"
                                />
                            </div>

                            <div className='col-sm-6'>
                                <Select
                                    className='select'
                                    value={selectedFromCity}
                                    onChange={setSelectedFromCity}
                                    options={fromCities?.map(c => ({ label: c.name, value: c._id }))}
                                    placeholder="From City"
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='label'>Deliver To</div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <Select
                                    className='select'
                                    value={selectedToCountry}
                                    onChange={setSelectedToCountry}
                                    options={countries?.filter(c => !c.isUnion).map(c => ({ label: c.name, value: c._id }))}
                                    placeholder="To Country"
                                />
                            </div>
                            <div className='col-sm-6'>
                                <Select
                                    className='select'
                                    value={selectedToCity}
                                    onChange={setSelectedToCity}
                                    options={toCities?.map(c => ({ label: c.name, value: c._id }))}
                                    placeholder="To City"
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='label'>Home Delivery?</div>
                        <input type="checkbox" checked={shipment.isHomeDelivery} onChange={() => setShipment(s => ({ ...s, isHomeDelivery: !s.isHomeDelivery }))} />
                    </div>

                    <button className='nextBtn saveBtn' onClick={() => setCurrentStep(2)}>
                        Next Step
                    </button>
                </>
            }

            {currentStep === 2 &&
                <Products products={products} setProducts={setProducts} saveShipment={saveShipment} isLoading={isLoading} />
            }

        </Popup>
    );
}