import React, { useEffect, useState, useRef } from 'react';
import Popup from '../../../components/popup';
import Field from '../../../components/field';
import { Alert, confirmAlert } from '../../../components/alert';
import UploadFile from '../../../components/uploadFile';
import PostObject from '../../../lib/postObject';
import PutObject from '../../../lib/putObject';
import { useFetch } from '../../../lib/useFetch';
import DatePicker from '../../../components/dateRangePicker';
import Select from 'react-select';
import moment from 'moment';
import './style.scss';

const promoTypes = [{ label: "Cash ($)", value: "Cash" }, { label: "Percentage (%)", value: "Percentage" }]

export default ({ show, hide, promoID, reload }) => {
    const { data, loading } = useFetch(`promocodes/${promoID}`)
    const [name, setName] = useState('')
    const [fromDate, setFromDate] = useState(undefined)
    const [toDate, setToDate] = useState(undefined)
    const [selectedType, setSelectedType] = useState(promoTypes[0])
    const [minCash, setMinCash] = useState('')
    const [amount, setAmount] = useState('')
    const [limit, setLimit] = useState('')
    const [countPerUser, setCountPerUser] = useState('')
    const [countPerAll, setCountPerAll] = useState('')
    const [isLoading, setIsloading] = useState(false)
    const sheetRef = useRef()

    useEffect(() => {
        if (data && !loading) {
            setName(data.name)
            setFromDate(moment(data.fromDate).format('YYYY-MM-DD'))
            setToDate(moment(data.toDate).format('YYYY-MM-DD'))
            setAmount(data.amount + '')
            setMinCash(data.minCash + '')
            setSelectedType(promoTypes.find(p => p.value === data.type))
            setLimit(data.limit + '')
            setCountPerUser(data.countPerUser + '')
            setCountPerAll(data.countPerAll + '')
        }
    }, [loading])

    useEffect(() => {
        if (promoID)
            return
        setName('')
        setFromDate(undefined)
        setToDate(undefined)
        setAmount('')
        setLimit('')
        setCountPerUser('')
        setCountPerAll('')
        setMinCash('')
    }, [show, promoID])

    const savePromocode = async (e) => {
        e.preventDefault();

        if (name.trim() === '' || !Number(amount)
            || !Number(countPerUser) || !Number(countPerAll) || fromDate.trim() === ''
            || toDate.trim() === '' || !Number(minCash)) {
            Alert('please fill all fields')
            return
        }

        if ((!promoID && !sheetRef.current.files[0])) {
            Alert('please upload users sheet')
            return
        }

        let promo = {
            name, fromDate, toDate, amount, limit: Number(limit), countPerUser, countPerAll,
            type: selectedType.value, minCash
        }

        if (promoID)
            promo._id = promoID
        else {
            let formdata = new FormData()
            formdata.append("file", sheetRef.current.files[0])
            formdata.append("data", JSON.stringify(promo))
            promo = formdata
        }

        confirmAlert(`${promoID ? 'edit' : 'add'} this promocode?`, () => {
            setIsloading(true)

            if (!promoID)
                PostObject('promocodes', promo).then(() => {
                    afterOperation()
                })
            else
                PutObject('promocodes', promo).then((data) => {
                    if (data)
                        afterOperation()
                })
        })
    }

    const afterOperation = () => {
        setIsloading(false)
        reload()
        hide()
        sheetRef.current.value = null
    }

    return (
        <Popup show={show} className='promoAddEdit' modalID='addEditPromoModal' title={`${promoID ? 'Edit' : 'Add'} Promocode`}>
            <form>
                {!promoID &&
                    <UploadFile ref={sheetRef} id="promocodeUsersSheet" accept='.csv,.xlsx' />
                }

                <div className='row'>
                    <div className='col-12'>
                        <Field label='Promocode Letters' value={name} setValue={setName}
                            placeholder='type promocode letters' />
                    </div>

                    <div className='col-sm-6'>
                        <div className='label'>From Date</div>
                        <DatePicker startD={fromDate} single={true} onApply={(date) => setFromDate(date)} />
                    </div>

                    <div className='col-sm-6'>
                        <div className='label'>To Date</div>
                        <DatePicker startD={toDate} single={true} onApply={(date) => setToDate(date)} />
                    </div>

                    <div className='col-sm-6'>
                        <div className='label'>Type</div>
                        <Select
                            className='select'
                            value={selectedType}
                            onChange={setSelectedType}
                            options={promoTypes}
                        />
                    </div>
                    <div className='col-sm-6'>
                        {selectedType.value === promoTypes[1].value &&
                            <Field label='Discount Limit' value={limit} setValue={setLimit}
                                placeholder='type discount limit' />}
                    </div>

                    <div className='col-sm-6'>
                        <Field label={'Discount Amount ' + (selectedType.value === promoTypes[0].value ? '($)' : '(%)')}
                            value={amount} setValue={setAmount} placeholder='type discount amount' />
                    </div>
                    <div className='col-sm-6'>
                        <Field label='Minimum Cash ($)' value={minCash} setValue={setMinCash}
                            placeholder='type min cash' />
                    </div>
                    
                    <div className='col-sm-6'>
                        <Field label='Frequency of use for everyone' value={countPerAll} setValue={setCountPerAll}
                            placeholder='type frequency of use for everyone' />
                    </div>
                    <div className='col-sm-6'>
                        <Field label='Frequency of use per user' value={countPerUser} setValue={setCountPerUser}
                            placeholder='type frequency of use per user' />
                    </div>
                </div>

                <button className='saveBtn' onClick={savePromocode} disabled={isLoading}>
                    <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                    {promoID ? 'Edit' : 'Add'}
                </button>
            </form>
        </Popup>
    );
}