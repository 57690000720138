import React, { useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import { useLoading } from '../../state/loading';
import { pageSize } from '../../lib/constants';
import { formatDate } from '../../lib/helpers';
import AddEditPrice from './addEdit';
import './style.scss';

const Block = ({ title, value }) =>
    <div className='block'>
        <div className='title'>{title}</div>
        <div className='value'>{value}</div>
    </div>

export default () => {
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`prices?page=${page}`, reload)
    const [editedPriceID, setEditedPriceID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)
    const { setLoader } = useLoading()

    const onDelete = (priceID) => {
        confirmAlert('delete this price?', () => {
            setLoader(l => l + 1)
            DeleteObject('prices', priceID, () => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    return (
        <div className='prices'>
            <Header title="Prices">
                <button onClick={() => { setEditedPriceID(null); toggleShowAddEdit(s => !s) }}>
                    Add Price
                </button>
            </Header>

            <div className='row pt-4'>
                {data?.map(price =>
                    <Card key={price._id}
                        rightText={formatDate(price.createdAt)}
                        image='/images/money.png'>

                        <div className='spaceBetween'>
                            <Block title='Price From' value={'$' + price.priceFrom} />
                            <Block title='Price To' value={'$' + price.priceTo} />
                        </div>

                        <div className='spaceBetween'>
                            <Block title='Delivery Fees' value={price.deliveryFees + '%'} />
                            <Block title='Reward Percent' value={price.rewardPercent + '%'} />
                        </div>

                        <div className='spaceBetween'>
                            <Block title='Voyship Percent From Reward' value={price.voyshipPercentFromTravelerReward + '%'} />
                        </div>

                        <Controls>
                            <button onClick={() => { setEditedPriceID(price._id); toggleShowAddEdit(s => !s) }}>
                                <img src='/images/edit.svg' alt='edit' />
                            </button>

                            <button className='danger' onClick={() => onDelete(price._id)}>
                                <img src='/images/bin.svg' alt='bin' />
                            </button>
                        </Controls>
                    </Card>
                )}
            </div>

            <AddEditPrice priceID={editedPriceID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedPriceID(null) }} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}