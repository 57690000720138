import React, { useEffect, useState } from "react";
import Popup from "../../../../components/popup";
import { Alert, confirmAlert } from "../../../../components/alert/index";
import PostObject from "../../../../lib/postObject";
import PutObject from "../../../../lib/putObject";
import { useFetch } from "../../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";
import Field from "../../../../components/field";

export default ({ show, hide, userID, reload }) => {
  const { data, loading } = useFetch(`dashboardShipments/${userID}`);

  const { data: Trips, loading: TripsLoading } = useFetch(`dashboardTrips`);
  const { data: shopers, loading: ShoppersLoading } =
    useFetch(`dashboardShoppers`);
  const { data: products, loading: ProductsLoading } = useFetch(`products`);

  const [selectedTrip, setTrip] = useState(null);
  const [selectedShopper, setShopper] = useState(null);
  const [selectedProduct, setProduct] = useState(null);
  const [estimatedWeight, setEstimatedWeight] = useState(null);
  const [placed, setPlaced] = useState(null);
  const [hubFees, setHubgees] = useState(null);
  const [shippingCompanyFees, setshippingCompanyFees] = useState(null);
  const [voyShipFees, setvoyShipFees] = useState(null);
  const [extra, setEtra] = useState(null);
  const [addressName, setAddressName] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {}, [
    loading,
    TripsLoading,
    ShoppersLoading,
    ProductsLoading,
    data,
    Trips,
  ]);

  useEffect(() => {
    setTrip("");
    setProduct("");
    setShopper("");
  }, [show]);

  const saveUser = async (e) => {
    e.preventDefault();

    if (!selectedShopper || !selectedTrip || !selectedProduct) {
      Alert("please fill all fields");
      return;
    }

    let shipment = {
      shopperId: selectedShopper.value,
      tripsId: [selectedTrip.value],
      deliveryDate: new Date(),
      products: selectedProduct.value,
      estimatedWeight,
      placed: placed.value,
      hubFees: Number(hubFees) || 0,
      shippingCompanyFees: Number(shippingCompanyFees) || 0,
      voyShipFees: Number(voyShipFees) || 0,
      extra: Number(extra) || 0,
      addressName,
    };

    if (userID) shipment._id = userID;

    confirmAlert(`${userID ? "edit" : "add"} this Shipment?`, () => {
      setIsloading(true);
      if (!userID)
        PostObject("dashboardShipments", shipment).then(() => {
          afterOperation();
        });
      else
        PutObject("dashboardShipments", shipment).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
  };
  return (
    <Popup
      show={show}
      className="userAddEdit"
      modalID="addEditUserModal"
      title={`${userID ? "Edit" : "Add"} User`}
    >
      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="estimatedWeight"
              value={estimatedWeight}
              setValue={setEstimatedWeight}
              placeholder="estimatedWeight for shipment"
              icon="estimatedWeight"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="addressName"
              value={addressName}
              setValue={setAddressName}
              placeholder="write address for shipment"
              icon="user"
            />
          </div>
        </div>
        ,
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="hubFees"
              value={hubFees}
              setValue={setHubgees}
              placeholder="hubFees for shipment"
              icon="hubFees"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="shippingCompanyFees"
              value={shippingCompanyFees}
              setValue={setshippingCompanyFees}
              placeholder="write shippingCompanyFees for shipment"
              icon="user"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="voyShipFees"
              value={voyShipFees}
              setValue={setvoyShipFees}
              placeholder="voyShipFees for shipment"
              icon="voyShipFees"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="extra"
              value={extra}
              setValue={setEtra}
              placeholder="write extra for shipment"
              icon="user"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <Select
              className="select"
              value={placed}
              onChange={setPlaced}
              options={[
                {
                  label: "Traveler",
                  value: "Traveler",
                },
                {
                  label: "Hub",
                  value: "Hub",
                },
              ]}
              placeholder="placed"
            />
          </div>
          <div className="col-sm-3">
            <Select
              className="select"
              value={selectedTrip}
              onChange={setTrip}
              options={Trips?.trips?.map((c) => ({
                label: c.fromCityId.name + "=>" + c.toCityId.name,
                value: c._id,
              }))}
              placeholder="Trip"
            />
          </div>
          <div className="col-sm-3">
            <Select
              className="select"
              value={selectedProduct}
              onChange={setProduct}
              options={products?.map((c) => ({
                label: c.name,
                value: c._id,
              }))}
              placeholder="products"
            />
          </div>
          <div className="col-sm-3">
            <Select
              className="select"
              value={selectedShopper}
              onChange={setShopper}
              options={shopers?.shoppers?.map((c) => ({
                label: c.firstName + c.lastName,
                value: c._id,
              }))}
              placeholder="Shopper"
            />
          </div>
        </div>
        <div className="row"></div>
        <button className="saveBtn" onClick={saveUser} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {userID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
