import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import Card, { Controls } from "../../../components/infoCard";
import PaginationBar from "../../../components/pagination";
import { userImg } from "../../../lib/constants";
import { formatDate } from "../../../lib/helpers";
import DeleteObject from "../../../lib/deleteObject";
import ImageModal from "../../../components/imageModal";
import { confirmAlert } from "../../../components/alert";
import { useLoading } from "../../../state/loading";
import { pageSize } from "../../../lib/constants";
import InfoCardBlock from "../../../components/infoCardBlock";
import axios from "axios";
import ShipmentAddEdit from "./addEdit/index";
import "./style.scss";

export default () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [reload, toggleReload] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [showAddEdit, toggleShowAddEdit] = useState(false);
  const [editedShipmentID, setEditedShipmentID] = useState(null);
  const { setLoader } = useLoading();
  const [shipments, setShipments] = useState([]);
  const [totalCountofDocument, setCount] = useState(0);

  const onDelete = (shipmentID) => {
    confirmAlert("delete this shipment?", () => {
      setLoader((l) => l + 1);
      DeleteObject("dashboardShipments", shipmentID, () => {
        setLoader((l) => l - 1);
        toggleReload((r) => !r);
      });
    });
  };

  const openView = (shipment, selectedTab) => {};

  useEffect(() => {
    axios
      .get(`http://localhost:3000/api/v0/dashboardShipments?page=${page}`)
      .then((res) => {
        console.log(res.data.data.shipments);
        setShipments(res.data.data.shipments);
        setCount(res.data.data.totalCount);
      });
  }, []);
  return (
    <div className="manualShipments">
      <Header title="Manual Shipments" setSearch={setSearch}>
        <button onClick={() => toggleShowAddEdit((s) => !s)}>
          Add Shipment
        </button>
      </Header>

      <div className="row pt-4">
        {shipments &&
          shipments.map((shipment) => (
            <Card
              key={shipment._id}
              image={userImg}
              setZoomedImage={setZoomedImage}
              leftText={"800 gm"}
            >
              <div className="row">
                <div className="col-6">
                  <InfoCardBlock imageIcon="user" label="Shopper">
                    {shipment.shopperId.firstName +
                      "  " +
                      shipment.shopperId.lastName}
                  </InfoCardBlock>
                </div>
                <div className="col-6">
                  <InfoCardBlock imageIcon="user" label="Traveler">
                    {shipment.tripsId[0].userName}
                  </InfoCardBlock>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <InfoCardBlock imageIcon="cash" label="Shipment Price">
                    {shipment.estimatedTotalPrice}
                  </InfoCardBlock>
                </div>
                <div className="col-6">
                  <InfoCardBlock imageIcon="calendar" label="Creation Date">
                    {formatDate(shipment.createdAt)}
                  </InfoCardBlock>
                </div>
              </div>

              <Controls>
                <button onClick={() => openView(shipment, 0)}>
                  <img src="/images/view.svg" alt="view" />
                </button>

                <button
                  onClick={() => {
                    setEditedShipmentID(shipment._id);
                    toggleShowAddEdit((s) => !s);
                  }}
                >
                  <img src="/images/edit.svg" alt="edit" />
                </button>

                <button
                  className="danger"
                  onClick={() => onDelete(shipment._id)}
                >
                  <img src="/images/bin.svg" alt="bin" />
                </button>
              </Controls>
            </Card>
          ))}
      </div>

      {zoomedImage && (
        <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
      )}

      <ShipmentAddEdit
        userID={editedShipmentID}
        show={showAddEdit}
        hide={() => toggleShowAddEdit((s) => !s)}
        reload={() => {
          toggleReload((r) => !r);
          setEditedShipmentID(null);
        }}
      />
      <PaginationBar
        page={page}
        totalCount={totalCountofDocument}
        setPage={setPage}
        pageSize={pageSize}
      />
    </div>
  );
};
