import React, { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/app';
import { userImg } from '../../lib/constants';
import PutObject from '../../lib/putObject';
import PostObject from '../../lib/postObject';
import ImageModal from '../../components/imageModal';
import moment from 'moment';
import { toBase64 } from '../../lib/helpers';
import 'firebase/auth';
import 'firebase/firestore';
import './style.scss';

firebase.initializeApp({
    apiKey: "AIzaSyC1M0_Iyau93p1O3JzcJ8wnxf5f5wCYVlM",
    authDomain: "voyship-master.firebaseapp.com",
    projectId: "voyship-master",
    storageBucket: "voyship-master.appspot.com",
    messagingSenderId: "745819872444",
    appId: "1:745819872444:web:86f554eb41a9ffa1d104b9",
    measurementId: "G-ZP8Y8RKW02"
});

const db = firebase.firestore()

const Chat = ({ user: { ID, username, photo }, onClose, openUserDetails }) => {
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState('')
    const [zoomedImage, setZoomedImage] = useState(null)
    const sendBtnRef = useRef()
    const messagesRef = useRef()

    useEffect(() => {
        if (db) {
            const unsubscribe = db.collection(`Admin_To_${ID}`).orderBy('createdAt').limit(1000)
                .onSnapshot(querySnapShot => {
                    PutObject(`adminsToUserChat/${ID}/read`, { senderRole: "admin" }, true)

                    const data = querySnapShot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))

                    setMessages(data)
                    scrollToBottom()
                })

            return unsubscribe
        }
    }, [db, ID])

    const sendMessage = () => {
        if (newMessage.trim() === "")
            return

        PutObject(`adminsToUserChat/${ID}`, { senderRole: "admin", unReadMessage: newMessage }, true)

        if (db) {
            db.collection(`Admin_To_${ID}`).add({
                text: newMessage,
                type: 'admin',
                msgType: 'text',
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
            })

            setNewMessage('')
        }
    }

    const uploadFile = async (e) => {
        let file = e.target.files[0]
        let type = "image"

        if (!file.type.includes('image/') && !file.type.includes('/pdf'))
            return

        if (file.type.includes('/pdf'))
            type = "file"

        PostObject('adminsToUserChat/uploadBase64File', { file: await toBase64(file) }, true)
            .then((data) => {
                if (data.status) {
                    PutObject(`adminsToUserChat/${ID}`, { senderRole: "admin", unReadMessage: type }, true)

                    if (db) {
                        db.collection(`Admin_To_${ID}`).add({
                            text: data.data,
                            type: 'admin',
                            msgType: type,
                            createdAt: firebase.firestore.FieldValue.serverTimestamp()
                        })
                    }
                }
            })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.target.value.trim() !== '') {
            sendBtnRef.current.click()
        }
    }

    const scrollToBottom = () => {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }

    return (
        <>
            <div className="chatDiv">
                <div className="header">
                    <div>Chat
                        {openUserDetails &&
                            <span className='userDetailsBtn' onClick={openUserDetails}>User Details</span>
                        }
                    </div>

                    <span className="closeChat" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </span>
                </div>

                <div className="messages" ref={messagesRef}>
                    {messages.map(message =>
                        <div key={message.id} className={`message ${message.type}`}>
                            <div className="username">{message.type === "admin" ? 'Me' : username}</div>

                            <div className="content">
                                <img className='avatar' src={message.type === "admin" ? userImg : photo} alt='avatar' />
                                <div className="text">
                                    {message.msgType === 'image' ?
                                        <img src={message.text} alt='Msg' className='imageMsg'
                                            onClick={() => setZoomedImage(message.text)} />
                                        : message.msgType === 'file' ?
                                            <a href={message.text} target='_blank' rel='noopener noreferrer'>
                                                <img src='/images/file.png' alt='file' className='imageMsg' />
                                            </a>
                                            : message.text
                                    }

                                    {message.createdAt &&
                                        <div className='date'>{(moment(message.createdAt.toDate()).format('YYYY-MM-DD HH:ss'))}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="newMessage">
                    <input value={newMessage} onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Enter Message" className='msgField' onKeyDown={handleKeyPress} />
                    <button onClick={sendMessage} ref={sendBtnRef}>Send</button>

                    <label className="uploadBtn" title='attach file' htmlFor="uploadFile">
                        <i className="fas fa-paperclip"></i>
                    </label>
                    <input type="file" id="uploadFile" onChange={(e) => uploadFile(e)} accept="image/*,.pdf" />
                </div>
            </div>

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }
        </>
    );
}

export default Chat