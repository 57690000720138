import React, { useRef, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Sidebar from '../../components/sidebar'
import MainModal from '../../components/modal'
import { useAuth } from "../../state/auth";
import '../style.scss';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authTokens } = useAuth();
  const reloadFun = useRef()

  const [state, setState] = useState({
    modalVisible: false,
    modalVariant: null,
    recordId: null,
    extraData: null
  });

  const closeMainModal = () => {
    setState({
      modalVisible: false,
      modalVariant: null,
      recordId: null,
      extraData: null
    })
  }
  const openMainModal = (modalVariant, recordId, reload, extraData) => {
    const modalVisible = true;
    reloadFun.current = reload
    setState({
      modalVisible,
      modalVariant,
      recordId,
      extraData
    })
  }

  return (
    <Route {...rest}
      render={props => (
        authTokens ?
          <>
            <div className='wrapper'>
              <Sidebar activeItem={props.location} />

              <div className="content">
                <div className="min-height">
                  <div className='container'>
                    <Component {...props}
                      openMainModal={openMainModal}
                      closeMainModal={closeMainModal}
                    />
                  </div>
                </div>
              </div>
            </div>

            <MainModal
              modalVisible={state.modalVisible}
              modalVariant={state.modalVariant}
              recordId={state.recordId}
              closeMainModal={closeMainModal}
              openMainModal={openMainModal}
              reload={reloadFun.current}
              extraData={state.extraData}
            />
          </>
          :
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )}
    />
  )
};

export default PrivateRoute