import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import UploadFile from "../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { toBase64 } from "../../../lib/helpers";
import { useFetch } from "../../../lib/useFetch";
import "./style.scss";

export default ({ show, hide, categoryID, reload }) => {
  const { data, loading } = useFetch(`categories/${categoryID}`);
  const [name, setName] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [nameFR, setNameFR] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const photoFile = useRef(null)
  const iconFile = useRef(null)

  useEffect(() => {
    if (data && !loading) {
      setName(data.name);
      setNameAR(data.nameAR);
      setNameFR(data.nameFR);
    }
  }, [loading]);

  useEffect(() => {
    if (categoryID)
      return

    setName("");
    setNameAR("");
    setNameFR("");
  }, [show, categoryID]);

  const saveCategory = async (e) => {
    e.preventDefault();

    if (name.trim() === "" || nameAR.trim() === "" || nameFR.trim() === "") {
      Alert("please fill all fields");
      return;
    }

    let category = {
      name, nameAR, nameFR
    };

    if (categoryID) category._id = categoryID;

    if (photoFile.current.files.length > 0)
      category.image = await toBase64(photoFile.current.files[0]);
    if (iconFile.current.files.length > 0)
      category.icon = await toBase64(iconFile.current.files[0]);

    confirmAlert(`${categoryID ? "edit" : "add"} this category?`, () => {
      setIsloading(true);

      if (!categoryID)
        PostObject("categories", category).then(() => {
          afterOperation();
        });
      else
        PutObject("categories", category).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoFile.current.value = null;
    iconFile.current.value = null;
  };

  return (
    <Popup show={show} className="categoryAddEdit" modalID="addEditCategoryModal" title={`${categoryID ? "Edit" : "Add"} category`}>
      <div className='images'>
        <div>
          <div className='label'>Image</div>
          <UploadFile ref={photoFile} id="photoUpload" />
        </div>

        <div>
          <div className='label'>Icon</div>
          <UploadFile ref={iconFile} id="iconUpload" />
        </div>
      </div>

      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="Name"
              value={name}
              setValue={setName}
              placeholder="write name"
              image="/images/category.svg"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Arabic Name"
              value={nameAR}
              setValue={setNameAR}
              placeholder="write Arabic name"
              image="/images/category.svg"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="French Name"
              value={nameFR}
              setValue={setNameFR}
              placeholder="write French name"
              image="/images/category.svg"
            />
          </div>
        </div>

        <button className="saveBtn" onClick={saveCategory} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {categoryID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
