import React, { useState } from 'react';
import Header from '../../../components/header';
import Card, { Controls } from '../../../components/infoCard';
import PaginationBar from '../../../components/pagination';
import { useFetch } from '../../../lib/useFetch';
import { useParams } from 'react-router-dom';
import DeleteObject from '../../../lib/deleteObject';
import { confirmAlert } from '../../../components/alert';
import { pageSize } from '../../../lib/constants';
import SubcategoryAddEdit from './addEdit';
import '../style.scss';

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { id } = useParams()
    const { data, totalCount } = useFetch(`categories/${id}/sub-category?page=${page}${search ? `&search=${search}` : ''}&limit=${pageSize}`, reload)
    const [editedSubcategoryID, setEditedSubcategoryID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)

    const onDelete = (categoryID) => {
        DeleteObject('categories/sub-category', categoryID, () => toggleReload(r => !r))
    }

    return (
        <div className='categories'>
            <Header title="Subcategories" setSearch={setSearch}>
                <button onClick={() => { setEditedSubcategoryID(null); toggleShowAddEdit(s => !s) }}>
                    Add Subcategory
                </button>
            </Header>

            <div className='row pt-4'>
                {data &&
                    data.map(category =>
                        <Card key={category._id}
                            image={category.image}>
                            <div className='text-center'>{category.name}</div>

                            <div className='mt-3 spaceBetween'>
                                <div>
                                    <img src='/images/cash.svg' alt='cash' className='icon' />
                                    Min Reward ($ {category.minTravelerReward})
                                </div>

                                <div>
                                    <img src='/images/weight.svg' alt='weight' className='icon' />
                                    {category.pricePerKg} / kg
                                </div>
                            </div>

                            <div className='mt-3 spaceBetween'>
                                <div>
                                    <img src='/images/cash.svg' alt='cash' className='icon' />
                                    Prime Min Reward ($ {category.primeMinReward || 0})
                                </div>

                                <div>
                                    <img src='/images/weight.svg' alt='weight' className='icon' />
                                    {category.primePricePerKg || 0} / kg
                                </div>
                            </div>

                            <div className='mt-3'>
                                <img src='/images/weight.svg' alt='weight' className='icon' />
                                {category.weight} kg
                            </div>

                            <Controls>
                                <button onClick={() => { setEditedSubcategoryID(category._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => confirmAlert('delete this subcategory?', () => onDelete(category._id))}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            <SubcategoryAddEdit subcategoryID={editedSubcategoryID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedSubcategoryID(null) }} />

            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}