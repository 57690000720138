import React, { useState } from 'react';
import Field from '../../../components/field';
import Popup from '../../../components/popup';
import { confirmAlert, Alert } from '../../../components/alert';
import PutObject from '../../../lib/putObject';
import './style.scss';

export default ({ show, shipmentID, reload }) => {
    const [refundAmount, setRefundAmount] = useState('')
    const [refundType, setRefundType] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const refund = () => {
        if (!refundType && !Number(refundAmount)) {
            Alert('please enter a valid amount')
            return
        }

        confirmAlert('refund this shipment?', () => {
            setIsLoading(true)
            PutObject(`shipments/${shipmentID}/refundPayment`, { toWallet: refundType ? false : true, amount: refundAmount }).then((data) => {
                setIsLoading(false)
                if (data) {
                    setRefundAmount('')
                    reload()
                }
            })
        })
    }

    return (
        <Popup show={show} className='refundForm' modalID='refundFormModal' title='Refund'>
            <div className='options'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <input id="appWallet" type='radio' checked={refundType === 0} onChange={() => setRefundType(0)} />
                        <label htmlFor='appWallet'>App Wallet</label>
                    </div>

                    <div className='col-sm-6'>
                        <input id='endUser' type='radio' checked={refundType === 1} onChange={() => setRefundType(1)} />
                        <label htmlFor='endUser'>End User</label>
                    </div>
                </div>
            </div>

            {refundType === 0 &&
                <Field value={refundAmount} setValue={setRefundAmount} placeholder='type refund amount' />}

            <button className='saveBtn' onClick={refund} disabled={isLoading}>
                <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                Confirm
            </button>
        </Popup>
    );
}