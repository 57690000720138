import React, { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/app';
import ImageModal from '../../components/imageModal';
import { useFetch } from '../../lib/useFetch';
import moment from 'moment';
import 'firebase/auth';
import 'firebase/firestore';

const db = firebase.firestore()

const Chat = ({ chatID, onClose }) => {
    const { data } = useFetch(`usersToUserChat/details/${chatID}`)
    const [messages, setMessages] = useState([])
    const [zoomedImage, setZoomedImage] = useState(null)
    const messagesRef = useRef()

    useEffect(() => {
        if (db) {
            const unsubscribe = db.collection('rooms-chat').doc(chatID)//.orderBy('createdAt').limit(1000)
                .onSnapshot(querySnapShot => {
                    let data = querySnapShot.data()
                    let formatedData = []

                    for (let msg in data) {
                        formatedData.push({ ...data[msg], id: msg, createdAt: moment(data[msg].createdAt.toDate()) })
                    }

                    // console.log(formatedData.sort(sortMessages))
                    setMessages(formatedData.sort((a, b) => a.createdAt - b.createdAt))
                    scrollToBottom()
                })

            return unsubscribe
        }
    }, [db, data])

    const scrollToBottom = () => {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }

    return (
        <>
            <div className="chatDiv">
                <div className="header">
                    <div>Chat</div>

                    <span className="closeChat" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </span>
                </div>

                <div className="messages" ref={messagesRef}>
                    {data && messages.map(message =>
                        <div key={message.id} className={`message ${message.from === data.shopperId?.username ? '' : 'anotherUser'}`}>
                            <div className="username">{message.from}</div>

                            <div className="content">
                                <img className='avatar' src={message.from === data.shopperId?.username ? data.shopperId?.photo : data.travelerId?.photo} alt='avatar' />
                                <div className="text">
                                    {message.msgType === 'image' ?
                                        <img src={message.answer} alt='Msg' className='imageMsg'
                                            onClick={() => setZoomedImage(message.answer)} />
                                        : message.answer
                                    }

                                    <div className='date'>{(message.createdAt.format('DD/MM/YYYY HH:mm'))}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }
        </>
    );
}

export default Chat