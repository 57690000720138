import React, { useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import { formatDate } from '../../lib/helpers';
import Chat from '../../components/chat';
import Tabs from '../../components/typesTabs';
import ImageModal from '../../components/imageModal';
import PassportVerificationView from './View';
import PassportEdit from './Edit';
import { pageSize } from '../../lib/constants';

let tabs = ["Pending", "Approved", "Unapproved", "Rejected"]

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [selectedState, setSelectedState] = useState(tabs[0])
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`personalsIdentity?page=${page}&${selectedState ? `state=${selectedState}&` : ''}${search ? `search=${search}&` : ''}&limit=${pageSize}`, reload)
    const [chatUser, setChatUser] = useState(null)
    const [zoomedImage, setZoomedImage] = useState(null)
    const [viewedPassportID, setViewedPassportID] = useState(null)
    const [showViewModal, toggleShowViewModal] = useState(false)
    const [editedPassportID, setEditedPassportID] = useState(null)
    const [showEditModal, toggleShowEditModal] = useState(false)

    return (
        <div className='passports'>
            <Header title="Passports" setSearch={setSearch} />

            <Tabs tabs={tabs}
                selectedTab={selectedState} setSelectedTab={setSelectedState} />

            <div className='row'>
                {data && data.map(user =>
                    <Card key={user.userInfo._id}
                        leftText={user.state}
                        image={user.userInfo.photo}
                        setZoomedImage={setZoomedImage}>
                        <div className='mb-2 text-center'>{user.userInfo.username}</div>
                        <div className='text-center'>{user.userInfo.firstName} {user.userInfo.lastName}</div>

                        <div className='mt-3'>
                            <img src='/images/mail.svg' alt='mail' className='icon' />

                            <span>
                                {user.userInfo.email?.length > 24 ? user.userInfo.email?.substring(0, 22) + '...' : (user.userInfo.email || '###')}
                            </span>
                        </div>

                        <div className='mt-3'>
                            <img src='/images/calendar.svg' alt='calendar' className='icon' />
                            {formatDate(new Date(user.createdAt))}
                        </div>

                        <Controls>
                            <button onClick={() => { setViewedPassportID(user.userInfo._id); toggleShowViewModal(s => !s) }}>
                                <img src='/images/view.svg' alt='view' />
                            </button>

                            <button onClick={() => { setEditedPassportID(user._id); toggleShowEditModal(s => !s) }}>
                                <img src='/images/edit.svg' alt='edit' />
                            </button>

                            <button onClick={() => setChatUser({ ID: user.userInfo._id, username: user.userInfo.username, photo: user.userInfo.photo })}>
                                <img src='/images/chat.svg' alt='chat' />
                            </button>
                        </Controls>
                    </Card>
                )}
            </div>
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />

            {chatUser && <Chat user={chatUser} onClose={() => setChatUser(null)} />}

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }

            <PassportVerificationView userID={viewedPassportID}
                show={showViewModal} hide={() => toggleShowViewModal(s => !s)}
                setChatUser={setChatUser}
                reload={() => toggleReload(r => !r)} />

            <PassportEdit show={showEditModal} hide={() => toggleShowEditModal(s => !s)}
                passportID={editedPassportID} onSuccess={() => setViewedPassportID(null)} />
        </div>
    );
}