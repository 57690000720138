import React from 'react';
import './style.scss';

const ImageModal = ({ photo, video, close }) => {
    return (
        <div className="imageModal" onClick={close}>
            {photo ?
                <img src={photo} alt="Zoom" /> :
                <video controls>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            }
        </div>
    )
}

export default ImageModal;