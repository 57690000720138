import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import UploadFile from "../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { toBase64 } from "../../../lib/helpers";
import { useFetch } from "../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, adminID, reload }) => {
  const { data, loading } = useFetch(`admins/${adminID}`);
  const { data: roles } = useFetch('admins/allowableRoles')
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const photoRef = useRef();

  useEffect(() => {
    if (data && !loading) {
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setUsername(data.username);
      setSelectedRole({ label: data.roles[0], value: data.roles[0] })
    }
  }, [loading]);

  useEffect(() => {
    if (adminID)
      return

    setFirstName("");
    setLastName("");
    setUsername("");
    setPassword("")
    setSelectedRole(null);
  }, [show, adminID]);

  const saveAdmin = async (e) => {
    e.preventDefault();

    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      username.trim() === "" ||
      !selectedRole
    ) {
      Alert("please fill all fields");
      return;
    }

    let admin = {
      firstName,
      lastName,
      username,
      roles: [selectedRole.value],
    };

    if (password.trim() !== "")
      admin.password = password

    if (adminID) admin._id = adminID;

    if (photoRef.current.files.length > 0)
      admin.photo = await toBase64(photoRef.current.files[0]);

    confirmAlert(`${adminID ? "edit" : "add"} this admin?`, () => {
      setIsloading(true);

      if (!adminID)
        PostObject("admins", admin).then(() => {
          afterOperation();
        });
      else
        PutObject("admins", admin).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoRef.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="adminAddEdit"
      modalID="addEditAdminModal"
      title={`${adminID ? "Edit" : "Add"} Admin`}
    >
      <UploadFile ref={photoRef} id="adminUpload" />

      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="First Name"
              value={firstName}
              setValue={setFirstName}
              placeholder="write first name"
              icon="user"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Last Name"
              value={lastName}
              setValue={setLastName}
              placeholder="write last name"
              icon="user"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Username"
              value={username}
              setValue={setUsername}
              placeholder="write username"
              icon="user"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Password"
              value={password}
              setValue={setPassword}
              placeholder="write password"
              type='password'
              icon="lock"
            />
          </div>

          <div className='col-sm-6'>
            <div className='label'>Role</div>
            <Select
              className='select'
              value={selectedRole}
              onChange={setSelectedRole}
              options={roles?.map(r => ({ label: r, value: r }))}
            />
          </div>
        </div>

        <button className="saveBtn" onClick={saveAdmin} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {adminID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
