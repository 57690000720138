import React from 'react';
import {Card, Button} from "tabler-react";
import DeleteObject from '../../lib/deleteObject';

const ConfirmDeleteModal = ({recordId, closeMainModal, type, reload}) => {
  async function confirmDelete() {
    DeleteObject(type, recordId, reload)
    closeMainModal()
  }

  return (
    <div>
    <Card>
      <Card.Header>
        <Card.Title>Confirm Delete</Card.Title>
      </Card.Header>
      <Card.Body>
        Are you sure you want to delete this?!
      </Card.Body>
      <Card.Footer>
        <div style={{textAlign:'right'}}>
          <Button
            color="danger"
            size="sm"
            className="ml-2"
            type="submit"
            onClick={confirmDelete}
          >
            Confirm Delete
          </Button>
          <Button
            outline
            color="secondary"
            size="sm"
            className="ml-2"
            onClick={()=>closeMainModal()}
          >
            cancel
          </Button>
        </div>
      </Card.Footer>
    </Card></div>
  )
}

export default ConfirmDeleteModal;