import React, { useEffect, useState } from 'react';
import { useFetch } from '../../../lib/useFetch';
import { formatDate } from '../../../lib/helpers';
import Popup, { PopupTabs } from '../../../components/popup';
import TripDestination from '../../../components/tripDestination';
import UserDetails from '../../../components/userDetails';
import Offers from '../../../components/offers';
import PopupProducts from './popupProducts';
import Invoice from './invoice';

const tabs = ["Shipment Details", "Products", "Price Estimates", "Offers", "Shopper Info"]

export default ({ show, shipmentID, openChat, defaultTab, openTripDetails, setZoomedImage }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const { data: shipment, loading } = useFetch(`shipments/${shipmentID}`)

    useEffect(() => {
        if (!loading) {
            if (shipment.offerAcceptedId)
                tabs[2] = "Shipment Invoice"
            else
                tabs[2] = "Price Estimates"
        }
    }, [loading])

    useEffect(() => {
        if (defaultTab >= 0)
            setSelectedTab(defaultTab)
    }, [defaultTab])

    return (
        <Popup show={show} className='viewShipment' modalID='shipmentViewModal' title={tabs[selectedTab]}>
            <PopupTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

            {shipment &&
                <>
                    {selectedTab === 0 &&
                        <div>
                            <TripDestination fromCity={{ name: shipment.fromCityObj.name, countryName: shipment.fromCountryObj.name }}
                                toCity={{ name: shipment.toCityObj.name, countryName: shipment.toCountryObj.name }}
                            />

                            <div className='spaceBetween'>
                                <div>
                                    <div>
                                        <img src='/images/calendar.svg' alt='calendar' className='icon' />
                                        {formatDate(new Date(shipment.createdAt))}
                                    </div>

                                    <div className='mt-4'>
                                        <i className='fas fa-check-circle yellowIcon'></i>
                                        {shipment.state}
                                    </div>

                                    <div className='mt-4'>
                                        <i className='fas fa-boxes yellowIcon'></i>
                                        {shipment.shipmentType}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img src='/images/weight.svg' alt='weight' className='icon' />
                                        {shipment.totalWeight} kg
                                    </div>

                                    <div className='mt-4'>
                                        <i className='fas fa-shopping-bag yellowIcon'></i>
                                        {shipment.quantity} item
                                    </div>

                                    <div className='mt-4'>
                                        <img src='/images/calendar.svg' alt='calendar' className='icon' />
                                        {formatDate(new Date(shipment.shipmentArrivalDate))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {selectedTab === 1 &&
                        <>
                        <PopupProducts products={shipment.products} setZoomedImage={setZoomedImage}/>
                            {/* {shipment.products.map((product, i) =>
                                <div key={i} className='product'>
                                    <div className='imgContainer'>
                                        <img src={product.picture} alt="product"
                                            onClick={() => setZoomedImage(product.picture)} />
                                    </div>
                                    <div className='info'>
                                        <div className='name'>{product.name}</div>
                                        <div className='data'>
                                            <img src='/images/category.svg' alt='category' className='icon' />
                                            {product.categoryObj?.name}
                                        </div>

                                        <div className='spaceBetween'>
                                            <div>
                                                <div className='data'>
                                                    <img src='/images/link.svg' alt='icon' className='icon' />
                                                    <a href={product.link} target='_blank' rel='noopener noreferrer'>Visit Link</a>
                                                </div>
                                                <div className='data'>
                                                    <img src='/images/weight.svg' alt='icon' className='icon' />
                                                    {product.visableWeight} kg
                                                </div>
                                            </div>

                                            <div>
                                                <div className='data'>
                                                    <img src='/images/cash.svg' alt='icon' className='icon' />
                                                    $ {product.price}
                                                </div>
                                                <div className='data'>
                                                    <i className='fas fa-shopping-bag yellowIcon'></i>
                                                    {product.quantity} item
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                        </>
                    }

                    {selectedTab === 2 &&
                        <Invoice shipment={shipment} offerID={shipment.offerAcceptedId} />
                    }

                    {selectedTab === 3 && <Offers shipmentID={shipment._id} openTripDetails={openTripDetails} />}

                    {selectedTab === 4 && <UserDetails userID={shipment.ownerObj?._id} openChat={openChat} />}
                </>
            }
        </Popup>
    );
}