import React, { useState } from 'react';
import './style.scss';

export default ({ products, setZoomedImage }) => {
    const [currentProdct, setCurrentProduct] = useState(0)
    
    return (
        <div className='mt-3 cardProducts' >
            <button onClick={() => setCurrentProduct(p => p - 1)}
                disabled={currentProdct === 0}>
                <i className='fas fa-chevron-left'></i>
            </button>

            <div>
                {products.map((product, i) =>
                    <div key={i} className={`product ${currentProdct === i ? 'current' : ''}`}>
                        <img src={product.picture || product.screenShotLink} className='productImg' alt='product'
                            onClick={() => setZoomedImage(product.picture)} />

                        <div className='info'>
                            <div className='name'>
                                {product.name.length > 18 ? product.name.substring(0, 16) + '...' : product.name}
                            </div>
                            <div>
                                <img src='/images/category.svg' alt='category' className='icon'/>
                                {product.categoryObj?.name?.length > 20 ? product.categoryObj?.name?.substring(0, 18) + '...' : product.categoryObj?.name}
                            </div>
                            <div className='mt-1'>
                                <img src='/images/category.svg' alt='category' className='icon'/>
                                {product.subCategoryObj?.name?.length > 20 ? product.subCategoryObj?.name?.substring(0, 18) + '...' : product.subCategoryObj?.name}
                            </div>
                            <div className='mt-1'>
                                <img src='/images/weight.svg' alt='icon' className='icon' />
                                {product.visableWeight || product.weight} kg
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <button onClick={() => setCurrentProduct(p => p + 1)}
                disabled={currentProdct === products.length - 1}>
                <i className='fas fa-chevron-right'></i>
            </button>
        </div>
    );
}