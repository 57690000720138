import React from 'react';
import { confirmAlert as Confirm } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './style.scss';

export const confirmAlert = (msg, onSuccess) => {
    Confirm({
        customUI: ({ onClose }) =>
            <div className='alertBox'>
                <div className='msg'>Are you sure you want to {msg}</div>

                <div className='buttons'>
                    <button onClick={() => { onSuccess(); onClose() }}>Yes</button>
                    <button onClick={onClose}>No</button>
                </div>
            </div>
    })
}

export const Alert = (msg, isSuccess) => {
    Confirm({
        customUI: () =>
            <div className='alertBox'>
                {isSuccess ?
                    <img src='/images/success.svg' alt='success' /> :
                    <img src='/images/error.svg' alt='error' />
                }

                <div className='msg'>{msg}</div>
            </div>
    })
} 