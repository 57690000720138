import React, { useState } from 'react';
import { useFetch } from '../../../lib/useFetch';
import { formatDate } from '../../../lib/helpers';
import Popup, { PopupTabs } from '../../../components/popup';
import TripDestination from '../../../components/tripDestination';
import UserDetails from '../../../components/userDetails';
import RequestedPayout from './requestedPayout';
import Offers from '../../../components/offers';
import ImageModal from '../../../components/imageModal';
import { confirmAlert } from '../../../components/alert';
import PutObject from '../../../lib/putObject';
import './style.scss';

const tabs = ["Trip Details", "Booking Info", "Traveler Info", "Offers"]

const TripDetailsInfoItem = ({ icon, value, withMargin }) =>
    <div className={`${withMargin ? 'mb-3' : ''}`}>
        <img src={icon} alt='icon' className='icon' />
        {value}
    </div>

const BookingInfoField = ({ label, icon, value }) =>
    <div className='col-sm-6'>
        <div className='label'>{label}</div>
        <div className='value'>
            <img src={icon} alt='icon' />
            {value}
        </div>
    </div>

export default ({ show, tripID, openChat, openPassportModal, openShipmentDetails, reload }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const { data: trip } = useFetch(`trips/${tripID}`)
    const [zoomedImage, setZoomedImage] = useState(null)
    const [updateIsLoading, setUpdateIsLoading] = useState(false)

    const makeArrived = () => {
        confirmAlert('update this trip to be arrived?', () => {
            setUpdateIsLoading(true)
            PutObject(`trips/${tripID}/arrived`, {}).then(() => {
                setUpdateIsLoading(false)
            })
        })
    }

    return (
        <>
            <Popup show={show} className='viewTrip' modalID='tripView' title={tabs[selectedTab]} large={true}>
                {trip &&
                    <>
                        <PopupTabs tabs={trip.payoutId ? [...tabs, "Payout"] : tabs}
                            selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

                        {selectedTab === 0 &&
                            <div className='tripDetails'>
                                <TripDestination fromCity={{ name: trip.fromCityObj.name, countryName: trip.fromCountryObj.name }}
                                    toCity={{ name: trip.toCityObj.name, countryName: trip.toCountryObj.name }}
                                />

                                <div className='spaceBetween'>
                                    <div>
                                        <TripDetailsInfoItem icon='/images/calendar.svg'
                                            value={formatDate(new Date(trip.tripDate))}
                                            withMargin={true} />
                                        <TripDetailsInfoItem icon='/images/clock.svg'
                                            value={trip.milestone} />
                                    </div>

                                    <div>
                                        <TripDetailsInfoItem icon='/images/weight.svg'
                                            value={`${trip.tripCapacity} kg`}
                                            withMargin={true} />
                                        <TripDetailsInfoItem icon='/images/trips/plane.svg'
                                            value={trip.tripType} />
                                    </div>
                                </div>

                                {trip.activeTripsCount ?
                                    <div className='otherTripsAlert'>{trip.activeTripsCount} Active trips</div> : ''
                                }

                                {trip.milestone === "In Progress" &&
                                    <button className='saveBtn' onClick={makeArrived} disabled={updateIsLoading}>
                                        <span className={updateIsLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                                        Pass Arrival Date
                                    </button>
                                }
                            </div>
                        }

                        {selectedTab === 1 &&
                            <div className='bookingInfo'>
                                <div className='row'>
                                    <BookingInfoField label='First Name' icon='/images/user.svg'
                                        value={trip.bookingInfo.firstName} />
                                    <BookingInfoField label='Last Name' icon='/images/user.svg'
                                        value={trip.bookingInfo.lastName} />
                                    <BookingInfoField label='Flight ID' icon='/images/trips/bigPlane.svg'
                                        value={trip.bookingInfo.flightNo} />
                                    <BookingInfoField label='Airline' icon='/images/trips/bigPlane.svg'
                                        value={trip.bookingInfo.notes} />
                                </div>

                                {trip.bookingInfo.notCarriedCategories.length > 0 &&
                                    <>
                                        <div className='unWanted'>Unwanted Categories</div>
                                        <div className='categories'>
                                            {trip.bookingInfo.notCarriedCategories.map((category, i) =>
                                                <span key={i} className='category'>{category.name}</span>
                                            )}
                                        </div>
                                    </>
                                }

                                <div className='imagesContainer'>
                                    {trip.tripTicketImage &&
                                        <div className='images'>
                                            <img src={trip.tripTicketImage} onClick={() => setZoomedImage(trip.tripTicketImage)}
                                                alt="ticket" />
                                        </div>
                                    }
                                    {trip.passportImage &&
                                        <div className='images'>
                                            <img src={trip.passportImage} onClick={() => setZoomedImage(trip.passportImage)}
                                                alt="Passport" />
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {selectedTab === 2 &&
                            <UserDetails userID={trip.ownerObj?._id} openChat={openChat} showPayouts={true} isChild={true}
                                openPassportModal={openPassportModal} />
                        }

                        {selectedTab === 3 && <Offers tripID={trip._id} openShipmentDetails={openShipmentDetails} />}

                        {selectedTab === 4 &&
                            <RequestedPayout id={trip.payoutId} reload={reload} />
                        }
                    </>
                }
            </Popup>

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }
        </>
    );
}