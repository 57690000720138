import React, { useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import { userImg } from '../../lib/constants';
import PostObject from '../../lib/postObject';
import Chat from '../../components/chat';
import Tabs from '../../components/typesTabs';
import ImageModal from '../../components/imageModal';
import TripDestination from '../../components/tripDestination';
import TripView from './tripView';
import { formatDate } from '../../lib/helpers';
import { Link, useParams } from 'react-router-dom';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import { useLoading } from '../../state/loading';
import PassportVerificationView from '../passportVerification/View';
import ShipmentView from '../shipments/shipmentView';
import { pageSize, baseUrl } from '../../lib/constants';
import TripAddEdit from './addEdit';
import Select from 'react-select';
import './style.scss';

const milestones = ['Placed', 'In Progress', 'Arrived', 'Completed', 'In Receipt', 'Archived', 'Rejected', 'InActive']
const extraFilters = ["", "Without Matched", "With Matched", "Without Offers", "With Offers"]

export default ({ location }) => {
    const { data: countries } = useFetch(`countries/lookup`);
    const [selectedFromCountry, setSelectedFromCountry] = useState(null)
    const [selectedToCountry, setSelectedToCountry] = useState(null)
    const [extraFilter, setExtraFilter] = useState({ label: 'All', value: '' })
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1);
    const [selectedMilestone, setSelectedMilestone] = useState(location.search.includes('milestone') ? milestones[location.search.split('?milestone=')[1]] : '')
    const [reload, toggleReload] = useState(false)
    const { userID } = useParams()
    const { data, totalCount } = useFetch(!location.search.includes('match') ? `trips/v2?page=${page}${selectedMilestone ? `&milestone=${selectedMilestone}` : ''}${userID ? `&userId=${userID}` : ''}${extraFilter.value ? `&extraFilter=${extraFilter.value}` : ''}${search ? `&search=${search}` : ''}${selectedFromCountry ? `&fromCountryId=${selectedFromCountry.value}` : ''}${selectedToCountry ? `&toCountryId=${selectedToCountry.value}` : ''}&sort=updatedAt:-1&limit=${pageSize}&all=true` : `shipments/${location.search.split('?match=')[1]}/matched-trips/v2?page=${page}`, reload);
    const [chatUser, setChatUser] = useState(null)
    const [zoomedImage, setZoomedImage] = useState(null)
    const [viewedTrip, setViewedTrip] = useState(null)
    const [showViewModal, toggleShowViewModal] = useState(false)
    const [viewedShipmentID, setViewedShipmentID] = useState(null)
    const [showShipmentModal, toggleShowShipmentModal] = useState(false)
    const [showPassport, toggleShowPassport] = useState(false)
    const [showAddEdit, toggleShowAddEdit] = useState(false)
    const [editedTripID, setEditedTripID] = useState(null)
    const { setLoader } = useLoading()

    const acceptTrip = (tripID) => {
        confirmAlert('accept this trip?', () => {
            setLoader(l => l + 1)
            PostObject(`trips/${tripID}/approve`, {}).then(() => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    const rejectTrip = (tripID) => {
        confirmAlert('reject this trip?', () => {
            setLoader(l => l + 1)
            PostObject(`trips/${tripID}/reject`, {}).then(() => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    const onDelete = (tripID) => {
        confirmAlert('delete this trip?', () => {
            setLoader(l => l + 1)
            DeleteObject('trips', tripID, (success) => {
                setLoader(l => l - 1)

                if (success)
                    toggleReload(r => !r)
            })
        })
    }

    const reloadTrips = () => {
        toggleReload(r => !r)
        toggleShowViewModal(s => !s)
    }

    const exportReport = () => {
        window.open(`${baseUrl}/trips/v2?Export=true&all=true${selectedMilestone ? `&milestone=${selectedMilestone}` : ''}${userID ? `&userId=${userID}` : ''}${extraFilter.value ? `&extraFilter=${extraFilter.value}` : ''}${search ? `&search=${search}` : ''}${selectedFromCountry ? `&fromCountryId=${selectedFromCountry.value}` : ''}${selectedToCountry ? `&toCountryId=${selectedToCountry.value}` : ''}`, '_blank')
    }

    return (
        <div className='trips'>
            <Header title="Trips" setSearch={setSearch}>
                <Select
                    className='select'
                    value={selectedFromCountry}
                    onChange={setSelectedFromCountry}
                    options={countries?.map(c => ({ label: c.name, value: c._id }))}
                    placeholder="From Country"
                />
                <Select
                    className='select'
                    value={selectedToCountry}
                    onChange={setSelectedToCountry}
                    options={countries?.map(c => ({ label: c.name, value: c._id }))}
                    placeholder="To Country"
                />

                <Select
                    className='select'
                    value={extraFilter}
                    onChange={setExtraFilter}
                    options={extraFilters.map(f => ({ label: f || 'All', value: f }))}
                />

                <button onClick={exportReport} className='exportBtn'>
                    Export
                </button>
            </Header>

            <Tabs tabs={milestones}
                selectedTab={selectedMilestone} setSelectedTab={setSelectedMilestone} />

            <div className='row'>
                {data &&
                    data.map(trip =>
                        <Card key={trip._id}
                            image={trip.ownerObj?.photo || userImg}
                            leftText={trip.milestone} rightText={formatDate(new Date(trip.updatedAt))}
                            setZoomedImage={setZoomedImage}>
                            <div className='mb-2 text-center'>{trip.ownerObj?.username}</div>

                            <TripDestination fromCity={{ name: trip.fromCityObj.name, countryName: trip.fromCountryObj.name }}
                                toCity={{ name: trip.toCityObj.name, countryName: trip.toCountryObj.name }}
                                shrinkText={true} />

                            <div className='mt-3 spaceBetween'>
                                <div>
                                    <img src='/images/calendar.svg' alt='calendar' className='icon' />
                                    {formatDate(new Date(trip.tripDate))}
                                </div>

                                {trip.milestone === milestones[1] &&
                                    <Link className='greenBtn' to={`/Shipments?match=${trip._id}`}>
                                        Matched
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </Link>
                                }

                                {milestones.indexOf(trip.milestone) > 0 &&
                                    <Link className='greenBtn' to={`/shipments?tripID=${trip._id}`}>
                                        Shipments
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </Link>
                                }

                                {/* {[milestones[2], milestones[3], milestones[4]].indexOf(trip.milestone) > -1  &&
                                    <div onClick={() => history.push(`/tripShipments/${trip._id}`)}>
                                        <i className='fas fa-boxes yellowIcon'></i>
                                        
                                        <span>{trip.shipments.length} shipments</span>
                                    </div>
                                } */}
                            </div>

                            <Controls>
                                {trip.milestone === milestones[0] &&
                                    <>
                                        <button title='Accept'
                                            onClick={() => acceptTrip(trip._id)} >
                                            <img src='/images/check.svg' alt='accept' />
                                        </button>
                                        <button title='Reject' className='times'
                                            onClick={() => rejectTrip(trip._id)} >
                                            <img src='/images/times.svg' alt='reject' />
                                        </button>
                                    </>
                                }

                                <button onClick={() => { setViewedTrip(trip); toggleShowViewModal(s => !s) }}>
                                    <img src='/images/view.svg' alt='view' />
                                </button>

                                <button onClick={() => setChatUser({ ID: trip.owner.userId, username: trip.owner.username, photo: trip.owner.photo })}>
                                    <img src='/images/chat.svg' alt='chat' />
                                </button>

                                <button onClick={() => { setEditedTripID(trip._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => onDelete(trip._id)}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            {chatUser && <Chat user={chatUser} onClose={() => setChatUser(null)} />}

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }

            <TripView tripID={viewedTrip?._id} show={showViewModal} reload={reloadTrips}
                openPassportModal={() => { toggleShowViewModal(s => !s); toggleShowPassport(s => !s) }}
                openChat={setChatUser}
                openShipmentDetails={(shipmentID) => { setViewedShipmentID(shipmentID); toggleShowShipmentModal(s => !s); toggleShowViewModal(s => !s) }} />

            <ShipmentView shipmentID={viewedShipmentID} show={showShipmentModal}
                openChat={setChatUser}
            />

            <PassportVerificationView userID={viewedTrip?.owner.userId} show={showPassport} />

            <TripAddEdit tripID={editedTripID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedTripID(null) }} />

            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}