import React, { useState } from 'react';
import { useFetch } from '../../../lib/useFetch';
import ImageModal from '../../../components/imageModal';
import ReactWhatsapp from 'react-whatsapp';
import PutObject from '../../../lib/putObject';
import Popup from '../../../components/popup';
import { confirmAlert } from '../../../components/alert';
import './style.scss';

export default ({ show, hide, userID, setChatUser, reload }) => {
    const { data } = useFetch(`personalsIdentity/${userID}`);
    const [zoomedImage, setZoomedImage] = useState(null)
    const [verifyIsLoading, setVerifyIsLoading] = useState(false)
    const [unverifyIsLoading, setUnverifyIsLoading] = useState(false)
    const [rejectIsLoading, setRejectIsLoading] = useState(false)

    const verifyUser = (userID) => {
        confirmAlert('verify this user?', () => {
            setVerifyIsLoading(true)
            PutObject(`personalsIdentity/${userID}/approve`, {}).then((data) => {
                setVerifyIsLoading(false)
                if (data) {
                    hide()
                    if (reload)
                        reload()
                }
            })
        })
    }

    const unverifyUser = (userID) => {
        confirmAlert('unverify this user?', () => {
            setUnverifyIsLoading(true)
            PutObject(`personalsIdentity/${userID}/unapprove`, {}).then((data) => {
                setUnverifyIsLoading(false)
                if (data) {
                    hide()
                    if (reload)
                        reload()
                }
            })
        })
    }

    const rejectUser = (userID) => {
        confirmAlert('reject this user?', () => {
            setRejectIsLoading(true)
            PutObject(`personalsIdentity/${userID}/reject`, {}).then((data) => {
                setRejectIsLoading(false)
                if (data) {
                    hide()
                    if (reload)
                        reload()
                }
            })
        })
    }

    return (
        <>
            <Popup show={show} modalID='passportView' className='passportModal' title='Passport Verification'>
                {data &&
                    <>
                        <div className='info'>
                            <img src={data.userInfo.photo} alt='profile' className='profile' />

                            <div className='details'>
                                <div className='name'>{data.userInfo.firstName} {data.userInfo.lastName}</div>
                                <div>{data.userInfo.username}</div>
                                <div>
                                    <img src='/images/mail.svg' alt='email' className='icon' />
                                    {data.userInfo.email}
                                </div>

                                {data.userInfo.fullPhoneNumber &&
                                    <div>
                                        <img src='/images/phone.svg' alt='phone' className='icon' />
                                        <span className="mr-1">{data.userInfo.fullPhoneNumber}</span>
                                        <ReactWhatsapp number={data.userInfo.whatsappNumber ? data.userInfo.whatsappCode + data.userInfo.whatsappNumber : data.userInfo.fullPhoneNumber} message=""
                                            element={(e) => <span {...e} className='whatsappIcon' onClick={e.onClick}><i className='fab fa-whatsapp'></i></span>}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='imagesTitle'>Passport Scan</div>

                        <div className='imagesContainer'>
                            <div className='images'>
                                <img src={data.faceImage} onClick={() => setZoomedImage(data.faceImage)}
                                    alt="ID" />
                            </div>
                            <div className='images'>
                                <img src={data.firstShotImage} onClick={() => setZoomedImage(data.firstShotImage)}
                                    alt="Passport" />
                            </div>
                            {/* <div className='images'>
                                <img src={data.secondShotImage} onClick={() => setZoomedImage(data.secondShotImage)}
                                    alt="Passport" />
                            </div> */}
                        </div>


                        <div className='buttons'>
                            <button onClick={() => setChatUser({ ID: data.userInfo._id, username: data.userInfo.username, photo: data.userInfo.photo })}>
                                Chat
                            </button>
                            {["Pending", "Unapproved", "Rejected"].indexOf(data.state) !== -1 &&
                                <button onClick={() => verifyUser(userID)} disabled={verifyIsLoading} className='verifyBtn'>
                                    <span className={verifyIsLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                                    Verify
                                </button>
                            }
                            {data.state === "Approved" &&
                                <button onClick={() => unverifyUser(userID)} disabled={unverifyIsLoading} className='unverifyBtn'>
                                    <span className={unverifyIsLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                                    Unverify
                                </button>
                            }
                            {["Pending", "Unapproved", "Approved"].indexOf(data.state) !== -1 &&
                                <button onClick={() => rejectUser(userID)} disabled={rejectIsLoading}>
                                    <span className={rejectIsLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                                    Reject
                                </button>
                            }
                        </div>
                    </>
                }
            </Popup>

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }
        </>
    );
}