import React, { useState } from 'react';
import Header from '../../components/header';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import Chat from '../../components/chat';
import Tabs from '../../components/typesTabs';
import { formatDate } from '../../lib/helpers';
import UserView from '../users/userView';
import PutObject from '../../lib/putObject';
import PassportVerificationView from '../passportVerification/View';
import { Controls } from '../../components/infoCard';
import { confirmAlert } from '../../components/alert';
import { useLoading } from '../../state/loading';
import './style.scss';

const pageSize = 18

const ChatBlock = ({ username, userImage, lastMsg, unreadMsgs, msgDate, onClick, markAsUnread, togglePin }) =>
    <div className='col-md-6 col-lg-4'>
        <div className='chatBlock' onClick={onClick}>
            <img src={userImage} alt='profile' className='profile' />

            <div className='data'>
                <div className='top'>
                    <span className='name'>{username}</span>
                    <span>{formatDate(new Date(msgDate))} - {new Date(msgDate).getHours()}:{new Date(msgDate).getMinutes()}</span>
                </div>

                <div className='bottom'>
                    <span className='lastMsg'>{lastMsg.length > 30 ? lastMsg.substring(0, 27) + '...' : lastMsg}</span>
                    <span className='unreadMsgs'>{unreadMsgs}</span>
                </div>
            </div>

            <Controls>
                <button onClick={(e) => markAsUnread(e, 1)} title='mark as unread'>
                    <img src='/images/unread.svg' alt='unread' />
                </button>

                <button onClick={(e) => togglePin(e, 1)} title='follow up'>
                    <img src='/images/followUp.svg' alt='follow up' />
                </button>
            </Controls>
        </div>
    </div>

const tabs = ["Unread", "Is Read", "Follow Up"]

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [selectedChatFilter, setSelectedChatFilter] = useState("")
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`adminsToUserChat?page=${page}${selectedChatFilter === tabs[2] ? `&isPinned=true` : selectedChatFilter ? `&adminIsRead=${selectedChatFilter == tabs[0] ? false : true}` : ''}${search ? `&search=${search}` : ''}&sort=updatedAt:-1&limit=${pageSize}`, reload)
    const [showViewModal, toggleShowViewModal] = useState(false)
    const [chatUser, setChatUser] = useState(null)
    const [passportID, setPassportID] = useState(null)
    const [showPassport, toggleShowPassport] = useState(false)
    const { setLoader } = useLoading()

    const openPassportModal = (passportID) => {
        toggleShowViewModal(s => !s)
        toggleShowPassport(s => !s)
        setPassportID(passportID)
    }

    const markAsUnread = (e, ID) => {
        e.stopPropagation()

        confirmAlert('mark this chat as unread?', () => {
            setLoader(l => l + 1)
            PutObject(`adminsToUserChat/${ID}/unread`, {}, true).then(data => {
                setLoader(l => l - 1)
                if (data) {
                    toggleReload(r => !r)
                }
            })
        })
    }

    const togglePin = (e, ID, isPinned) => {
        e.stopPropagation()

        confirmAlert('un/follow up with this chat?', () => {
            if (isPinned && tabs[2] !== selectedChatFilter)
                return

            setLoader(l => l + 1)
            PutObject(`adminsToUserChat/${ID}/togglePin`, {}, true).then(data => {
                setLoader(l => l - 1)
                if (data) {
                    toggleReload(r => !r)
                }
            })
        })
    }

    return (
        <div className='chatLog'>
            <Header title="Chat" setSearch={setSearch} />

            <Tabs tabs={tabs}
                selectedTab={selectedChatFilter} setSelectedTab={setSelectedChatFilter} />

            <div className='row'>
                {data && data.map((chat, i) =>
                    <ChatBlock key={i} username={chat.userInfo?.username} userImage={chat.userInfo?.photo}
                        lastMsg={chat.unReadMessage} unreadMsgs={chat.unReadUserMessageCount} msgDate={chat.updatedAt}
                        onClick={() => setChatUser({ ID: chat._id, username: chat.userInfo?.username, photo: chat.userInfo?.photo })}
                        togglePin={(e) => togglePin(e, chat._id, chat.isPinned)}
                        markAsUnread={(e) => markAsUnread(e, chat._id)}
                    />
                )}

            </div>

            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />

            <PassportVerificationView userID={passportID} show={showPassport} />

            {chatUser && <Chat user={chatUser} onClose={() => setChatUser(null)} openUserDetails={() => toggleShowViewModal(s => !s)} />}

            <UserView userID={chatUser?.ID} show={showViewModal}
                openPassportModal={() => openPassportModal(chatUser?.ID)} />
        </div>
    );
}