import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import UploadFile from "../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { toBase64 } from "../../../lib/helpers";
import { useFetch } from "../../../lib/useFetch";

export default ({ show, hide, countryID, reload }) => {
  const { data, loading } = useFetch(`countries/${countryID}`);
  const [name, setName] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [nameFR, setNameFR] = useState("");
  const [pricePerKg, setPricePerKg] = useState("");
  const [minTravelerReward, setMinTravelerReward] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [currency, setCurrency] = useState("");
  const [feesPercentageOfReward, setFeesPercentageOfReward] = useState("");
  const [callingCode, setCallingCode] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const flagFile = useRef(null)

  useEffect(() => {
    if (data && !loading) {
      setName(data.name);
      setNameAR(data.nameAR);
      setNameFR(data.nameFR);
      setPricePerKg(data.pricePerKg);
      setMinTravelerReward(data.minTravelerReward);
      setCountryCode(data.countryCode);
      setCurrency(data.currency);
      setFeesPercentageOfReward(data.voyshipFeesPercentageOfTheTravelerReward);
      setCallingCode(data.callingCode);
    }
  }, [loading]);

  useEffect(() => {
    if (countryID)
      return

    setName("");
    setNameAR("");
    setNameFR("");
    setPricePerKg("")
    setMinTravelerReward("");
    setCountryCode("");
    setCurrency("");
    setFeesPercentageOfReward("");
    setCallingCode("");
  }, [show, countryID]);

  const saveCountry = async (e) => {
    e.preventDefault();

    if (
      name.trim() === "" || nameAR.trim() === "" || nameFR.trim() === "" ||
      !Number(minTravelerReward) || countryCode.trim() === "" || currency.trim() === "" ||
      !Number(feesPercentageOfReward) || callingCode.trim() === "" || !Number(pricePerKg)
    ) {
      Alert("please fill all fields");
      return;
    }

    let country = {
      name, nameAR, nameFR,
      pricePerKg,
      minTravelerReward, countryCode,
      currency, callingCode,
      voyshipFeesPercentageOfTheTravelerReward: feesPercentageOfReward
    };

    if (countryID) country._id = countryID;

    if (flagFile.current.files.length > 0)
      country.countryFlag = await toBase64(flagFile.current.files[0]);

    confirmAlert(`${countryID ? "edit" : "add"} this country?`, () => {
      setIsloading(true);

      if (!countryID)
        PostObject("countries", country).then(() => {
          afterOperation();
        });
      else
        PutObject("countries", country).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    flagFile.current.value = null;
  };

  return (
    <Popup show={show} className="countryAddEdit" modalID="addEditCountryModal" title={`${countryID ? "Edit" : "Add"} Country`}>
      <UploadFile ref={flagFile} id="flagUpload" />

      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="Name"
              value={name}
              setValue={setName}
              placeholder="write name"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Arabic Name"
              value={nameAR}
              setValue={setNameAR}
              placeholder="write Arabic name"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="French Name"
              value={nameFR}
              setValue={setNameFR}
              placeholder="write French name"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Price Per KG"
              value={pricePerKg}
              setValue={setPricePerKg}
              placeholder="write price per kg"
              image='/images/weight.svg'
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Minimum Reward"
              value={minTravelerReward}
              setValue={setMinTravelerReward}
              placeholder="write minimium reward"
              image='/images/cash.svg'
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Country Code"
              value={countryCode}
              setValue={setCountryCode}
              placeholder="write country code"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Calling Code"
              value={callingCode}
              setValue={setCallingCode}
              placeholder="write calling code"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Currency"
              value={currency}
              setValue={setCurrency}
              placeholder="write currency"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Fees Percentage Of Reward"
              value={feesPercentageOfReward}
              setValue={setFeesPercentageOfReward}
              placeholder="write fees percentage of reward"
            />
          </div>
        </div>

        <button className="saveBtn" onClick={saveCountry} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {countryID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
