
import React, { createContext, useState, useContext } from 'react';

const Context = createContext()

export function useLoading() {
  return useContext(Context);
}

export default ({ children }) => {
  const [loader, setLoader] = useState(0)

  let state = {
    loader, setLoader
  }

  return (
    <Context.Provider value={state}>
      {children}
    </Context.Provider>
  );
};