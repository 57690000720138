import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../../components/popup";
import Field from "../../../../components/field";
import UploadFile from "../../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../../components/alert";
import PostObject from "../../../../lib/postObject";
import PutObject from "../../../../lib/putObject";
import { toBase64 } from "../../../../lib/helpers";
import { useFetch } from "../../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, cityID, reload }) => {
  const { data, loading } = useFetch(`cities/${cityID}`);
  const { data: countries, loading: countriesLoading } = useFetch(`countries/lookup?withUnions=true`);
  const [name, setName] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [nameFR, setNameFR] = useState("");
  const [cityCode, setCityCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const photoFile = useRef();

  useEffect(() => {
    if (data && !loading && countries && !countriesLoading) {
      let defaultCountry = countries.find((c) => c._id === data.countryId);
      setSelectedCountry({ label: defaultCountry.name, value: defaultCountry._id })

      setName(data.name);
      setNameAR(data.nameAR);
      setNameFR(data.nameFR);
      setCityCode(data.cityCode);
    }
  }, [loading, countriesLoading]);

  useEffect(() => {
    if (cityID)
      return

    setName("");
    setNameAR("");
    setNameFR("");
    setCityCode("");
    setSelectedCountry(null);
  }, [show, cityID]);

  const saveCity = async (e) => {
    e.preventDefault();

    if (
      name.trim() === "" ||nameAR.trim() === "" ||
      nameFR.trim() === "" || cityCode.trim() === ""||
      !selectedCountry
    ) {
      Alert("please fill all fields");
      return;
    }

    let city = {
      name,
      nameAR,
      nameFR,
      cityCode,
      countryId: selectedCountry.value,
    };

    if (cityID) city._id = cityID;

    if (photoFile.current.files.length > 0)
      city.photo = await toBase64(photoFile.current.files[0]);

    confirmAlert(`${cityID ? "edit" : "add"} this city?`, () => {
      setIsloading(true);

      if (!cityID)
        PostObject("cities", city).then(() => {
          afterOperation();
        });
      else
        PutObject("cities", city).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoFile.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="cityAddEdit"
      modalID="addEditCityModal"
      title={`${cityID ? "Edit" : "Add"} City`}
    >
      <UploadFile ref={photoFile} id="cityUpload" />

      <form>
        <div className="row">
        <div className="col-sm-6">
            <Field
              label="Name"
              value={name}
              setValue={setName}
              placeholder="write name"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Arabic Name"
              value={nameAR}
              setValue={setNameAR}
              placeholder="write Arabic name"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="French Name"
              value={nameFR}
              setValue={setNameFR}
              placeholder="write French name"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="City Code"
              value={cityCode}
              setValue={setCityCode}
              placeholder="write city code"
            />
          </div>

          <div className='col-sm-6'>
            <div className='label'>Country</div>
            <Select
              className='select'
              value={selectedCountry}
              onChange={setSelectedCountry}
              options={countries?.map(c => ({ label: c.name, value: c._id }))}
            />
          </div>
        </div>

        <button className="saveBtn" onClick={saveCity} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {cityID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
