import { baseUrl } from './constants';
import { Alert } from '../components/alert';
import axios from 'axios';

const PatchObject = async (type, obj, withoutToast) => {
  const authTokens = JSON.parse(localStorage.getItem('tokens'));
  const headers = { Authorization: authTokens, 'Content-Type': 'application/json' };
  const { _id, ...data } = obj

  return new Promise((resolve, reject) => {
    axios({
      url: (_id) ? `${baseUrl}/${type}/${_id}` : `${baseUrl}/${type}`,
      method: 'patch',
      data,
      headers
    }).then(response => {
      if (response.status === 201 || response.status === 200 || response.data.data.status === 200) {
        if (!withoutToast)
          Alert(response.data.message, true)
        resolve(response.data)
      }
    })
      .catch(error => {
        console.log(error.response)
        Alert(error?.response?.data?.message, false)
        resolve(null)
      })
  })
}

export default PatchObject;
