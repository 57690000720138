import React from 'react';
import './style.scss';

export default ({ rate }) =>
    <div className='stars'>
        {[...Array(5)].map((item, i) =>
            <span key={Math.random()}>
                <i className={`fas fa-star ${rate <= i ? 'dimmed' : ''}`}></i>
            </span>
        )}
    </div>
