import React, { useState } from 'react';
import './style.scss';

const displayedPages = 10

const PaginationBar = ({ totalCount, page, setPage, pageSize = 10 }) => {
  const [pagesGroup, setPagesGroup] = useState(0)
  const remainder = (totalCount % pageSize) || 0
  const noOfPages = remainder ? Math.floor(totalCount / pageSize) + 1 : (totalCount / pageSize)

  return (
    totalCount ?
      <div className='pagination'>
        <span className='counts'>
          {pageSize * (page - 1) + 1}-{Math.min(page * pageSize, totalCount)} of {totalCount} items
        </span>

        <div className='paginationBar'>
          <button disabled={pagesGroup === 0} onClick={() => setPagesGroup(p => p - 1)}>
            <img src='/images/leftArrow.svg' alt='arrow' />
          </button>

          <div className='pages'>
            {[...Array(Math.min(noOfPages - pagesGroup * displayedPages < 0 ? displayedPages : noOfPages - pagesGroup * displayedPages, displayedPages))].map((p, i) =>
              <span key={i} className={i + 1 + pagesGroup * displayedPages === page ? 'active' : ''}
                onClick={() => setPage(i + 1 + pagesGroup * displayedPages)}>{i + 1 + pagesGroup * displayedPages}
              </span>
            )}
          </div>

          <button disabled={(pagesGroup + 1) * displayedPages >= noOfPages} onClick={() => setPagesGroup(p => p + 1)}>
            <img src='/images/rightArrow.svg' alt='arrow' />
          </button>
        </div>
      </div> : null
  );
}

export default PaginationBar;