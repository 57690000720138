import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import { userImg } from '../../lib/constants';
import DeleteObject from '../../lib/deleteObject';
import Tabs from '../../components/typesTabs';
import { confirmAlert } from '../../components/alert';
import { useLoading } from '../../state/loading';
import { pageSize } from '../../lib/constants';
import AddEditAdmin from './addEdit';

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [selectedRole, setSelectedRole] = useState(null)
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`admins?page=${page}&role=${selectedRole}&${search ? `search=${search}` : ''}&limit=${pageSize}`, reload)
    const { data: roles, loading } = useFetch('admins/allowableRoles')
    const [editedAdminID, setEditedAdminID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)
    const { setLoader } = useLoading()

    useEffect(() => {
        if (roles && !loading)
            setSelectedRole(roles[0])
    }, [loading])

    // const verifyAdmin = (token) => {
    //     confirmAlert('verify this admin?', () => {
    //         setLoader(l => l + 1)
    //         PutObject('admins/verify', { token: token }).then((data) => {
    //             setLoader(l => l - 1)
    //             if (data)
    //                 toggleReload(r => !r)
    //         })
    //     })
    // }

    const onDelete = (adminID) => {
        confirmAlert('delete this admin?', () => {
            setLoader(l => l + 1)
            DeleteObject('admins', adminID, () => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    return (
        <div className='admins'>
            <Header title="Admins" setSearch={setSearch}>
                <button onClick={() => { setEditedAdminID(null); toggleShowAddEdit(s => !s) }}>
                    Add Admin
                </button>
            </Header>

            {roles && <Tabs tabs={roles}
                selectedTab={selectedRole} setSelectedTab={setSelectedRole} />
            }

            <div className='row'>
                {data &&
                    data.map(admin =>
                        <Card key={admin._id}
                            image={admin.photo || userImg}
                            leftText={admin.roles[0]}>
                            <div className='mb-2 text-center'>{admin.username}</div>
                            <div className='text-center'>{admin.firstName} {admin.lastName}</div>

                            <Controls>
                                {/* {!admin.verified &&
                                    <button title='Verify'
                                        onClick={() => verifyAdmin(admin.verificationToken)}>
                                        <img src='/images/check.svg' alt='check' />
                                    </button>
                                } */}

                                <button onClick={() => { setEditedAdminID(admin._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => onDelete(admin._id)}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            <AddEditAdmin adminID={editedAdminID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedAdminID(null) }} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}