import React, { useState } from "react";
import Popup from "../../components/popup";
import { Alert, confirmAlert } from "../../components/alert";
import PostObject from "../../lib/postObject";
import Select from 'react-select';
import "./style.scss";

const roles = [{ label: 'Shopper', value: 'Shopper' }, { label: 'Traveller', value: 'Traveller' },
{ label: 'All', value: '' }]

export default ({ show, hide, notificationID }) => {
  const [selectedRole, setSelectedRole] = useState(null)
  const [isLoading, setIsloading] = useState(false);

  const send = (e) => {
    e.preventDefault();

    if (!selectedRole) {
      Alert("please fill all fields");
      return;
    }

    const notification = {
      notificationId: notificationID,
      roles: selectedRole.value ? [selectedRole.value] : []
    }

    confirmAlert(`send this notification?`, () => {
      setIsloading(true);
      PostObject('notifications/notifyByUserRole', notification).then(() => {
        setSelectedRole(null)
        setIsloading(false)
        hide()
      });
    });
  }

  return (
    <Popup
      show={show}
      className="rolesNotificationAddEdit"
      modalID="addEditRolesNotificationModal"
      title='Send Notification To Role'
    >
      <form>
        <div className="row">
          <div className="col-12">
            <div className="fieldDiv">
              <div className='label'>Role</div>
              <Select
                className='select'
                value={selectedRole}
                onChange={setSelectedRole}
                options={roles}
                placeholder="Choose Role"
              />
            </div>
          </div>
        </div>

        <button className="saveBtn" onClick={send} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          Send
        </button>
      </form>
    </Popup>
  );
};
