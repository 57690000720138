import React, { useState } from 'react';
import { useFetch } from '../../../lib/useFetch';
import { formatDate } from '../../../lib/helpers';
import PaginationBar from '../../pagination';
import Field from '../../field';
import { Alert, confirmAlert } from '../../../components/alert';
import PostObject from '../../../lib/postObject';
import './style.scss';

const pageSize = 5

const Item = ({ name, value, children, className }) =>
    <div className={`item ${className}`}>
        <div>
            <div className='value'>{value}</div>
            <div>{name}</div>
        </div>
        {children}
    </div>

export default ({ userID }) => {
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { data } = useFetch(`wallets/${userID}`)
    const { data: logs, totalCount } = useFetch(`wallets/${userID}/log?page=${page}&limit=${pageSize}`, reload)
    const [showBonusForm, setShowBonusForm] = useState(false)
    const [bonus, setBonus] = useState('')
    const [isWithdrawable, setIsWithdrawable] = useState(0)
    const [isLoading, setIsloading] = useState(false)

    const addBonus = () => {
        if (!Number(bonus) && bonus > 0)
            Alert('please a valid number')

        confirmAlert(`add bonus to this user?`, () => {
            setIsloading(true)

            PostObject('wallets/addBonus', {
                userId: userID,
                isWithdrawable: isWithdrawable ? true : false,
                amount: bonus
            }).then((data) => {
                setIsloading(false)

                if (data) {
                    toggleReload(r => !r)
                    setShowBonusForm(false)
                }
            })
        })
    }

    return (
        <div className='wallet'>
            {data &&
                <div className='totals'>
                    <Item value={data.pendingBalance} name='On Hold' />
                    <Item value={data.balance} name='Balance' />
                    <Item value={data.requestedBalance} name='Requested' />
                    <Item value={data.refundedBalance} name='Refunded' className='red' />
                    <Item value={data.bonusBalance} name='Bonus' className='green'>
                        <span className='addBonusBtn' onClick={() => setShowBonusForm(s => !s)}>
                            <img src='/images/addIcon.svg' alt='add' />
                        </span>
                    </Item>
                </div>
            }

            {!showBonusForm ?
                logs?.length > 0 &&
                <>
                    <div className='subtitle'>Transactions History</div>
                    {logs.map((log, i) =>
                        <div key={i} className="logRow">
                            <span>{log.action} ${log.amount}</span>
                            <span>{formatDate(log.createdAt)}</span>
                        </div>
                    )}
                </> :
                <div className='bonusForm'>
                    <div className='subtitle'>Add Bonus</div>

                    <div className='options'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <input id="appOnly" type='radio' checked={isWithdrawable === 0} onChange={() => setIsWithdrawable(0)} />
                                <label htmlFor='appOnly'> In App Only</label>
                            </div>

                            <div className='col-sm-6'>
                                <input id='cash' type='radio' checked={isWithdrawable === 1} onChange={() => setIsWithdrawable(1)} />
                                <label htmlFor='cash'>Cash Balance</label>
                            </div>
                        </div>
                    </div>

                    <Field value={bonus} setValue={setBonus} placeholder='type bonus amount' />

                    <div className='buttons'>
                        <button className='cancelBtn' onClick={() => setShowBonusForm(false)}>Cancel</button>
                        <button onClick={addBonus} disabled={isLoading}>
                            <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                            Add
                        </button>
                    </div>
                </div>
            }

            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}