import React from 'react';
import BounceLoader from "react-spinners/BounceLoader";
import './style.scss';

export default ({isLoading}) => {
    return (
        <div className={isLoading? "spinner": ""}  >
            <BounceLoader
                size={100}
                color={"#003362"}
                loading={isLoading}
            />
        </div>
    );
} 