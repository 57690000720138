import React, { useState } from 'react';
import Popup from '../../components/popup';
import PutObject from '../../lib/putObject';
import Select from 'react-select';

const data = []

export default ({ show, hide }) => {
    const [selectedEmail, setSelectedEmail] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const addPilot = () => {
        setIsLoading(true)
        PutObject(`users/${selectedEmail.value}/addRole`, { "role": "Pilot" }).then((data) => {
            setIsLoading(false)
            if (data)
                hide()
        })
    }

    return (
        <Popup className='pilotModal' show={show} modalID='addPilotModal' title='Add Pilot'>
            {data &&
                <Select
                    className='select'
                    value={selectedEmail}
                    options={data && data.map(item => {
                        return ({ label: item.email, value: item._id });
                    })}
                    placeholder="Email"
                    onChange={setSelectedEmail}
                />
            }

            <button className='saveBtn' onClick={addPilot} disabled={isLoading}>
                <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                Add
            </button>
        </Popup>
    );
}