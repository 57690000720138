import React, { useEffect } from 'react';
import { useAuth } from "../../state/auth";
import { Redirect } from "react-router-dom";

const Logout = () => {
  const { setAuthTokens, setAdmin } = useAuth();
  useEffect(() => {
    setAuthTokens(null)
    setAdmin(null)
    localStorage.clear()
  }, []);
  return (
    <Redirect to="/login" />
  );
}

export default Logout;