import React, { useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import { formatDate } from '../../lib/helpers';
import { pageSize } from '../../lib/constants';
import { useLoading } from '../../state/loading';
import SendToBulk from './sendToBulk';
import NotificationAddEdit from './addEdit';
import SendToSpecificEmails from './sendToSpecificEmails';
import SendToRoles from './sendToRoles';
import './style.scss';

export default () => {
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`notifications?page=${page}&limit=${pageSize}`, reload)
    const [showBulkModal, toggleShowBulkModal] = useState(false)
    const [editedNotificationID, setEditedNotificationID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)
    const [clickedNotificationID, setClickedNotificationID] = useState(null)
    const [showEmailsNotification, toggleShowEmailsNotification] = useState(false)
    const [showRolesNotification, toggleShowRolesNotification] = useState(false)
    const { setLoader } = useLoading()

    const onDelete = (notificationID) => {
        confirmAlert('delete this notification?', () => {
            setLoader(l => l + 1)
            DeleteObject('notifications', notificationID, () => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    return (
        <div className='notifications'>
            <Header title="Notifications">
                <button onClick={() => toggleShowBulkModal(s => !s)}>
                    Send Bulk
                </button>

                <button onClick={() => { setEditedNotificationID(null); toggleShowAddEdit(s => !s) }}>
                    Add Notification
                </button>
            </Header>

            <div className='row'>
                {data?.map(n =>
                    <Card key={n._id}
                        image={n.image || '/images/imagePlaceholder.png'}
                        leftText={formatDate(n.createdAt)}>
                        <div className='mb-2 text-center'>{n.title}</div>

                        <div className='contentTitle'>Content</div>
                        <div className='body'>{n.body}</div>

                        <Controls>
                            <button onClick={() => { setEditedNotificationID(n._id); toggleShowAddEdit(s => !s) }}>
                                <img src='/images/edit.svg' alt='edit' />
                            </button>

                            <button title='send to role' onClick={() => { setClickedNotificationID(n._id); toggleShowRolesNotification(s => !s) }}>
                                <img src='/images/userBtn.svg' alt='send' />
                            </button>

                            <button title='send to emails' onClick={() => { setClickedNotificationID(n._id); toggleShowEmailsNotification(s => !s) }}>
                                <img src='/images/mailBtn.svg' alt='send' />
                            </button>

                            <button className='danger' onClick={() => onDelete(n._id)}>
                                <img src='/images/bin.svg' alt='bin' />
                            </button>
                        </Controls>
                    </Card>
                )}
            </div>

            <NotificationAddEdit notificationID={editedNotificationID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedNotificationID(null) }} />

            <SendToSpecificEmails notificationID={clickedNotificationID} show={showEmailsNotification} hide={() => toggleShowEmailsNotification(s => !s)} />
            <SendToRoles notificationID={clickedNotificationID} show={showRolesNotification} hide={() => toggleShowRolesNotification(s => !s)} />

            <SendToBulk show={showBulkModal} hide={() => toggleShowBulkModal(s => !s)} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={12} />
        </div>
    );
}