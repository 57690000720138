
import React, { useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from '../state/auth';
import { ErrorContext } from '../state/error';
import { Alert } from '../components/alert';
import { useLoading } from '../state/loading';
import Spinner from '../components/spinner';

import PrivateRoute from './private-route';
import Login from '../containers/login';
import Logout from '../containers/login/logout';
import Home from '../containers/home';
import AdminUsers from '../containers/adminUsers';
import Users from '../containers/users';
import PassportVerification from '../containers/passportVerification';
import ChatLog from '../containers/chatLog';
import UsersChats from '../containers/usersChats';
import Orders from '../containers/orders';
import Trips from '../containers/trips';
import Shipments from '../containers/shipments';
import Products from '../containers/products';
import Stores from '../containers/stores';
import Countries from '../containers/countries';
import Cities from '../containers/countries/cities';
import Stories from '../containers/stories';
import Reports from '../containers/reports';
import Settings from '../containers/settings';
import Faq from '../containers/settings/faq';
import Categories from '../containers/categories';
import SubCategories from '../containers/categories/sub';
import Notifications from '../containers/notifications';
import Pricing from '../containers/pricing';
import ManualShipments from '../containers/manual/shipments';
import ManualUsers from '../containers/manual/users';
import ManualTrips from '../containers/manual/trips'
import Promocodes from '../containers/promocodes';
import Media from '../containers/media';

export default () => {
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem("adminData")));
  const [isError, setIsError] = useState(false);
  const { loader } = useLoading()
  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }

  const setErrors = (msg, type = 'error') => {
    setIsError(msg);
    console.log(msg)
    if (type === 'success') {
      Alert(msg, true)
    } else {
      Alert(msg, false)
    }
  }

  return (
    <>
      <ErrorContext.Provider value={{ isError, setIsError: setErrors }}>
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, admin, setAdmin }}>
          <Router>
            <>
              <Switch>
                <Route exact path="/" render={() => admin ? <Redirect to="/home" /> : <Redirect to="/login" />} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/login" component={Login} />

                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute path="/admins" component={AdminUsers} />
                <PrivateRoute path="/chatLog" component={ChatLog} />
                <PrivateRoute path="/usersChats" component={UsersChats} />

                <PrivateRoute path="/users" component={Users} />
                <PrivateRoute path="/passportVerification" component={PassportVerification} />
                <PrivateRoute path="/notifications" component={Notifications} />
                <PrivateRoute path="/pricing" component={Pricing} />

                <PrivateRoute exact path="/trips" component={Trips} />
                <PrivateRoute exact path="/trips/:userID" component={Trips} />

                <PrivateRoute exact path="/shipments" component={Shipments} />
                <PrivateRoute path="/shipments/:userID" component={Shipments} />

                <PrivateRoute exact path="/products" component={Products} />
                <PrivateRoute path="/stores" component={Stores} />
                <PrivateRoute exact path="/categories" component={Categories} />
                <PrivateRoute path="/categories/:id/subcategories" component={SubCategories} />
                <PrivateRoute path="/stories" component={Stories} />
                <PrivateRoute path="/countries" component={Countries} />
                <PrivateRoute path="/cities/:countryID" component={Cities} />
                <PrivateRoute path="/reports" component={Reports} />
                <PrivateRoute path="/promocodes" component={Promocodes} />
                <PrivateRoute path="/settings" component={Settings} />
                <PrivateRoute path="/settings/faq" component={Faq} />
                <PrivateRoute path="/media" component={Media} />

                <PrivateRoute path="/manualUsers" component={ManualUsers} />
                <PrivateRoute path="/manualShipments" component={ManualShipments} />
                <PrivateRoute path="/manualTrips" component={ManualTrips} />

                <PrivateRoute path="/orders" component={Orders} />
              </Switch>
            </>
          </Router>
        </AuthContext.Provider>
      </ErrorContext.Provider>

      <Spinner isLoading={loader} />
    </>
  );
};