import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../../components/popup";
import Field from "../../../../components/field";
import UploadFile from "../../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../../components/alert";
import PostObject from "../../../../lib/postObject";
import PutObject from "../../../../lib/putObject";
import { toBase64 } from "../../../../lib/helpers";
import { useFetch } from "../../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, subcategoryID, reload }) => {
  const { data, loading } = useFetch(`categories/sub-category/${subcategoryID}`);
  const { data: categories, loading: categoriesLoading } = useFetch(`categories/lookup`);
  const [name, setName] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [nameFR, setNameFR] = useState("");
  const [pricePerKg, setPricePerKg] = useState("");
  const [primePricePerKg, setPrimePricePerKg] = useState("");
  const [minTravelerReward, setMinTravelerReward] = useState("");
  const [primeMinReward, setPrimeMinReward] = useState("");
  const [weight, setWeight] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const iconFile = useRef(null)
  const photoFile = useRef(null)

  useEffect(() => {
    if (data && !loading && categories && !categoriesLoading) {
      let defaultCategory = categories.find((c) => c._id === data.categoryId);
      setSelectedCategory({ label: defaultCategory.name, value: defaultCategory._id })

      setName(data.name);
      setNameAR(data.nameAR);
      setNameFR(data.nameFR);
      setMinTravelerReward(data.minTravelerReward);
      setPrimeMinReward(data.primeMinReward);
      setWeight(data.weight);
      setPricePerKg(data.pricePerKg);
      setPrimePricePerKg(data.primePricePerKg);
    }
  }, [loading, categoriesLoading]);

  useEffect(() => {
    if (subcategoryID)
      return

    setName("");
    setNameAR("");
    setNameFR("");
    setMinTravelerReward("");
    setPrimeMinReward("");
    setWeight("");
    setSelectedCategory(null);
    setPricePerKg("");
    setPrimePricePerKg("");
  }, [show,subcategoryID]);

  const saveSubcategory = async (e) => {
    e.preventDefault();

    if (
      name.trim() === "" || nameAR.trim() === "" || nameFR.trim() === "" ||
      !Number(pricePerKg) || !Number(primePricePerKg) || !Number(minTravelerReward) ||
      !Number(primeMinReward) || !Number(weight) || !selectedCategory
    ) {
      Alert("please fill all fields");
      return;
    }

    let subcategory = {
      name, nameAR, nameFR,
      pricePerKg, primePricePerKg,
      minTravelerReward, primeMinReward,
      weight,
      categoryId: selectedCategory.value,
    };

    if (subcategoryID) subcategory._id = subcategoryID;

    if (photoFile.current.files.length > 0)
      subcategory.image = await toBase64(photoFile.current.files[0]);
    if (iconFile.current.files.length > 0)
      subcategory.icon = await toBase64(iconFile.current.files[0]);

    confirmAlert(`${subcategoryID ? "edit" : "add"} this subcategory?`, () => {
      setIsloading(true);

      if (!subcategoryID)
        PostObject("categories/sub-category", subcategory).then(() => {
          afterOperation();
        });
      else
        PutObject("categories/sub-category", subcategory).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoFile.current.value = null;
    iconFile.current.value = null;
  };

  return (
    <Popup show={show} className="subcategoryAddEdit" modalID="addEditSubcategoryModal" title={`${subcategoryID ? "Edit" : "Add"} Subcategory`}>
      <div className='images'>
        <div>
          <div className='label'>Image</div>
          <UploadFile ref={photoFile} id="photoUpload" />
        </div>

        <div>
          <div className='label'>Icon</div>
          <UploadFile ref={iconFile} id="iconUpload" />
        </div>
      </div>

      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="Name"
              value={name}
              setValue={setName}
              placeholder="write name"
              image="/images/category.svg"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Arabic Name"
              value={nameAR}
              setValue={setNameAR}
              placeholder="write Arabic name"
              image="/images/category.svg"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="French Name"
              value={nameFR}
              setValue={setNameFR}
              placeholder="write French name"
              image="/images/category.svg"
            />
          </div>

          <div className='col-sm-6'>
            <div className='label'>Category</div>
            <Select
              className='select'
              value={selectedCategory}
              onChange={setSelectedCategory}
              options={categories?.map(c => ({ label: c.name, value: c._id }))}
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Price Per KG"
              value={pricePerKg}
              setValue={setPricePerKg}
              placeholder="write price per kg"
              image='/images/weight.svg'
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Prime Price Per KG"
              value={primePricePerKg}
              setValue={setPrimePricePerKg}
              placeholder="write prime price per kg"
              image='/images/weight.svg'
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Minimum Reward"
              value={minTravelerReward}
              setValue={setMinTravelerReward}
              placeholder="write minimium reward"
              image='/images/cash.svg'
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Prime Minimum Reward"
              value={primeMinReward}
              setValue={setPrimeMinReward}
              placeholder="write prime minimium reward"
              image='/images/cash.svg'
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Weight"
              value={weight}
              setValue={setWeight}
              placeholder="write weight"
              image='/images/weight.svg'
            />
          </div>
        </div>

        <button className="saveBtn" onClick={saveSubcategory} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {subcategoryID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
