import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useFetch } from '../../lib/useFetch';
import Items from "./schema.json";
import './style.scss';

export default () => {
  const [reload, toggleReload] = useState(false)
  const { data } = useFetch(`adminsToUserChat/adminUnreadCount`, reload, true)
  
  useEffect(() => {
    setInterval(() => {
      toggleReload(r => !r)
    }, 240000);
  }, [])

  return (
    <div className='sidebar'>
      <img src='/images/logo.png' alt="logo" className='logo' />

      <div className="side-bar-list">
        {Items.map(({ name, path, icon }, i) => (
          <NavLink key={i} to={path} className='item'>
            <i className={`fas ${icon}`}></i>
            <span className='itemName'>{name}</span>

            {path === '/chatLog' &&
              <span className='unreadCount'>{data}</span>
            }
          </NavLink>
        ))}

        <div className='item'>
          <Link to="/logout" className='logout'>
            <i className='fas fa-sign-out-alt'></i>
            <span className='itemName'>Logout</span>
          </Link>
        </div>
      </div>
    </div>
  )
}