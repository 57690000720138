import React, { useState } from 'react';
import { Card, Button, Form, Dimmer } from "tabler-react";
import { useFetch } from '../../lib/useFetch';
import PutObject from '../../lib/putObject';

const FAQEditModal = ({ recordId, closeMainModal, reload }) => {
  const { data, loading, handleChange } = useFetch(`settings/faq/${recordId}`);
  const [saveLoading, toggleSaveLoading] = useState(false)

  const saveEdit = () => {
    const editedFaq = {
      _id: recordId,
      question: data.question,
      answer: data.answer
    }

    toggleSaveLoading(true)
    PutObject('settings/faq', editedFaq).then((data) => {
      toggleSaveLoading(false)
      if (data) {
        reload()
        closeMainModal()
      }
    });
  }

  return (
    <Card>
      <Dimmer active={loading} loader={loading}>
        <Card.Header>
          <Card.Title>Edit FAQ</Card.Title>
          <Card.Options>
            <Button size="sm" icon="x" onClick={() => closeMainModal()} />
          </Card.Options>
        </Card.Header>

        {!loading && data &&
          <Card.Body>
            <Form>
              <Form.Input
                name='question'
                label='question'
                placeholder=''
                value={data['question']}
                onChange={(e) => handleChange(e)}
              />

              <Form.Input
                name='answer'
                label='answer'
                placeholder=''
                value={data['answer']}
                onChange={(e) => handleChange(e)}
              />
            </Form>
          </Card.Body>
        }
        <Card.Footer>
          <div style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              size="sm"
              className={`ml-2 ${saveLoading ? 'btn-loading' : ''}`}
              onClick={saveEdit}
              disabled={saveLoading}
            >
              Save
            </Button>
          </div>
        </Card.Footer>
      </Dimmer>
    </Card>
  )
}

export default FAQEditModal;