import React from 'react';
import Header from '../../components/header';
import { useFetch } from '../../lib/useFetch';
import { Link } from 'react-router-dom';
import './style.scss';

const Card = ({ name, count, icon, path }) => {
    return (
        <div className='col-sm-6 col-lg-4'>
            <Link to={path} className='statCard'>
                <div className='top'>
                    <span className='statIcon'>
                        <img src={`/images/home/${icon}`} alt="icon" />
                    </span>

                    <span className='curve'>+ 3%</span>
                </div>

                <div className='count'>{count}</div>

                <div className='name'>{name}</div>
            </Link>
        </div>
    );
}

export default () => {
    const { data } = useFetch('reports')

    return (
        <div className='home'>
            <Header title="Home"></Header>

            <div className='row'>
                <Card name='Shipment Payment' count={data?.successfulPayments} icon='payment.svg' path='/shipments?status=3' />
                <Card name='Order Payment' count={data?.orderSuccessfulPayments} icon='payment.svg' path='/orders?status=1' />
                <Card name='Partial Payment' count={data?.partialPayments} icon='payment.svg' path='/shipments?status=2' />

                <Card name='Home-Delivery-Paid Shipments' count={data?.homeDeliveryPaidShipments} icon='ship-only.svg' path='/shipments?status=2&extra=1' />
                <Card name='Placed Trips' count={data?.placedTrips} icon='placed-trips.svg' path='/trips?milestone=0' />
                <Card name='In Progress Trips' count={data?.inProgressTrips} icon='inprogress-trips.svg' path='/trips?milestone=1' />
                <Card name='Completed Trips' count={data?.completedTrips} icon='arriving-trips.svg' path='/trips?milestone=3' />

                <Card name='Shipments Ship Only' count={data?.shipOnlyShipments} icon='ship-only.svg' path='/shipments?type=1' />
                <Card name='Shipments Shop & Ship' count={data?.shopAndShipshipments} icon='shop-ship.svg' path='/shipments?type=2' />
                <Card name='Prime Shipments' count={data?.primeShipments} icon='prime-shipments.svg' path='/shipments?type=3' />

                <Card name='Users' count={data?.users} icon='new-users.svg' path='/users' />
                <Card name='Passport Verification' count={data?.idVerifications} icon='ID.svg' path='/passportVerification' />

                <Card name='Voyship Fees' count={data?.voyshipFees?.toFixed()} icon='payment.svg' path='/home' />
                <Card name='Payment Fees' count={data?.paymentFees?.toFixed()} icon='payment.svg' path='/home' />
            </div>

            <div className='row'>
                <div className='col-md-6 mb-4 mb-0'>
                    <div className='totals'>
                        <div>
                            <span className='title'>Travelers</span>
                            <span>Total</span>
                        </div>
                        <div className='count'>{data?.travelers}</div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='totals'>
                        <div>
                            <span className='title'>Shoppers</span>
                            <span>Total</span>
                        </div>
                        <div className='count'>{data?.shoppers}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}