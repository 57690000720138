import React from 'react';
import './style.scss';

export default ({ label, icon, placeholder, type, value, setValue, image, disabled, isRTL }) => {
    return (
        <div className='fieldDiv'>
            <div className='label'>{label}</div>
            <div className='field'>
                {image && <img src={image} alt="icon" />}
                {icon && <i className={`fas fa-${icon}`}></i>}

                <input value={value} onChange={(e) => setValue(e.target.value)} dir={isRTL ? 'rtl' : 'ltr'}
                    placeholder={placeholder} type={type || 'text'} disabled={disabled} />
            </div>
        </div>
    );
}