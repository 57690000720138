import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import UploadFile from "../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { toBase64 } from "../../../lib/helpers";
import { useFetch } from "../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, storyID, reload }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { data, loading } = useFetch(`stories/${storyID}`);
  const { data: countries, loading: countriesLoading } = useFetch(`countries/lookup`);
  const { data: cities, loading: citiesLoading } = useFetch(`cities/lookup?countryId=${selectedCountry?.value}`);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const photoFile = useRef();

  useEffect(() => {
    if (data && !loading && countries && !countriesLoading) {
      setSelectedCountry({ label: data.countryId.name, value: data.countryId._id })
      setSelectedCity({ label: data.cityId.name, value: data.cityId._id })

      setTitle(data.title);
      setBody(data.body);
    }
  }, [loading, countriesLoading, citiesLoading]);

  useEffect(() => {
    if (storyID)
      return

    setTitle("");
    setBody("");
    setSelectedCountry(null);
    setSelectedCity(null);
  }, [show, storyID]);

  const saveStory = async (e) => {
    e.preventDefault();

    if (
      title.trim() === "" || body.trim() === "" ||
      !selectedCountry || !selectedCity
    ) {
      Alert("please fill all fields");
      return;
    }

    let story = {
      title,
      body,
      countryId: selectedCountry.value,
      cityId: selectedCity.value,
    };

    if (storyID) story._id = storyID;

    if (photoFile.current.files.length > 0)
      story.photo = await toBase64(photoFile.current.files[0]);

    confirmAlert(`${storyID ? "edit" : "add"} this story?`, () => {
      setIsloading(true);

      if (!storyID)
        PostObject("stories", story).then(() => {
          afterOperation();
        });
      else
        PutObject("stories", story).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoFile.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="storyAddEdit"
      modalID="addEditStoryModal"
      title={`${storyID ? "Edit" : "Add"} Story`}
    >
      <UploadFile ref={photoFile} id="storyUpload" />

      <form>
        <div className="row">
          <div className="col-12">
            <Field
              label="Title"
              value={title}
              setValue={setTitle}
              placeholder="write title"
            />
          </div>

          <div className="col-12">
            <div className='label'>Body</div>
            <textarea
              className="field body"
              value={body}
              onChange={e => setBody(e.target.value)}
              placeholder="write body"
            />
          </div>

          <div className='col-sm-6'>
            <div className='label'>Country</div>
            <Select
              className='select'
              value={selectedCountry}
              onChange={setSelectedCountry}
              options={countries?.map(c => ({ label: c.name, value: c._id }))}
            />
          </div>

          <div className='col-sm-6'>
            <div className='label'>City</div>
            <Select
              className='select'
              value={selectedCity}
              onChange={setSelectedCity}
              options={cities?.map(c => ({ label: c.name, value: c._id }))}
            />
          </div>
        </div>

        <button className="saveBtn" onClick={saveStory} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {storyID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
