import React, { useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import { pageSize } from '../../lib/constants';
import Tabs from '../../components/typesTabs';
import AddEditProduct from './addEdit';

const types = [{ name: 'Normal', value: '0' }, { name: 'Prime', value: '1' }]

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [isPrime, setIsPrime] = useState('0');
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`products?${isPrime === '1' ? `isPrime=true&` : ''}&page=${page}${search ? `&search=${search}` : ''}&limit=${pageSize}`, reload)
    const [editedProductID, setEditedProductID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)

    const onDelete = (productID) => {
        DeleteObject('products', productID, () => toggleReload(r => !r))
    }

    return (
        <div className='products'>
            <Header title="Products" setSearch={setSearch}>
                <button onClick={() => { setEditedProductID(null); toggleShowAddEdit(s => !s) }}>
                    Add Product
                </button>
            </Header>

            <Tabs tabs={types} selectedTab={isPrime} setSelectedTab={setIsPrime} />

            <div className='row'>
                {data &&
                    data.map(product =>
                        <Card key={product._id}
                            image={product.image}>
                            <div className='mb-2 text-center'>
                                {product.name.length > 35 ? product.name.substring(0, 33) + '...' : product.name}
                            </div>
                            <div className='text-center'>{product.description}</div>

                            <div className='spaceBetween mt-3 mb-3'>
                                <div>
                                    <img src='/images/link.svg' alt='icon' className='icon' />
                                    <a href={product.url} target="_blank" rel="noopener noreferrer">Visit Link</a>
                                </div>
                                <div>
                                    <img src='/images/cash.svg' alt='cash' className='icon' />
                                    $ {product.price}
                                </div>
                            </div>

                            <Controls>
                                <button onClick={() => { setEditedProductID(product._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => confirmAlert('delete this product?', () => onDelete(product._id))}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            <AddEditProduct productID={editedProductID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedProductID(null) }} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}