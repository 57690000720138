import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import { pageSize } from '../../lib/constants';
import AddEditCountry from './addEdit';

export default ({ openMainModal }) => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`countries?limit=${pageSize}&page=${page}${search ? `&search=${search}` : ''}`, reload)
    const [editedCountryID, setEditedCountryID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)
    const history = useHistory()

    const onDelete = (countryID) => {
        DeleteObject('countries', countryID, () => toggleReload(r => !r))
    }

    return (
        <div className='countries'>
            <Header title="Countries" setSearch={setSearch}>
                <button onClick={() => { setEditedCountryID(null); toggleShowAddEdit(s => !s) }}>
                    Add Country
                </button>
            </Header>

            <div className='row pt-4'>
                {data &&
                    data.map(country =>
                        <Card key={country._id}
                            image={country.countryFlag}
                            leftText={country.currency}
                            rightText={country.numOfCities + ' Cities'}
                            handleRightOnClick={() => history.push(`/cities/${country._id}`)}>
                            <div className='mb-2 text-center'>{country.name}</div>
                            <div className='text-center'>{country.countryCode} ({country.callingCode})</div>

                            <div>
                                <img src='/images/weight.svg' alt='weight' className='icon' />
                                {country.pricePerKg} {country.currency} / kg
                            </div>

                            <div className='mt-3 spaceBetween'>
                                <div>
                                    <img src='/images/cash.svg' alt='cash' className='icon' />
                                    Min Reward ($ {country.minTravelerReward})
                                </div>

                                <div>
                                    <img src='/images/coin.svg' alt='coin' className='icon' />
                                    Fees ({country.voyshipFeesPercentageOfTheTravelerReward}%)
                                </div>
                            </div>

                            <Controls>
                                <button onClick={() => { setEditedCountryID(country._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => confirmAlert('delete this country?', () => onDelete(country._id))}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            <AddEditCountry countryID={editedCountryID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedCountryID(null) }} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}