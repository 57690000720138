import React, {  useState } from 'react';
import { useFetch } from '../../../lib/useFetch';
import Popup, { PopupTabs } from '../../../components/popup';
import UserDetails from '../../../components/userDetails';
import PopupProducts from '../../shipments/shipmentView/popupProducts';
import Invoice from '../../shipments/shipmentView/invoice';
import './style.scss';

const tabs = ["User Details", "Products", "Payment"]

export default ({ show, orderID, openChat, setZoomedImage }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const { data: order } = useFetch(`scrapping/order/${orderID}`)

    const getInvoice = () => {
        return {
            ...order.price,
            total: order.price.actualTotalReward + order.price.shipmentPrice
        }
    }

    return (
        <Popup show={show} className='viewOrder' modalID='orderViewModal' title={tabs[selectedTab]} large={true}>
            <PopupTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

            {order &&
                <>
                    {selectedTab === 0 && <UserDetails userID={order.userObj} openChat={openChat} />}
                    {selectedTab === 1 && <PopupProducts products={order.products} setZoomedImage={setZoomedImage} />}
                    {selectedTab === 2 &&
                        <Invoice actualInvoice={getInvoice()} />
                    }
                </>
            }
        </Popup>
    );
}