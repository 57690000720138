import React, {useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import DeleteObject from '../../lib/deleteObject';
import Tabs from '../../components/typesTabs';
import ImageModal from '../../components/imageModal';
import { confirmAlert } from '../../components/alert';
import { useLoading } from '../../state/loading';
import { pageSize } from '../../lib/constants';
import { formatDate } from '../../lib/helpers';
import Products from '../shipments/products';
import EditOrder from './editState';
import OrderView from './orderView';
import './style.scss';

const states = ["Placed", "Purchased", "On The Way", "Out For Delivery", "Delivered"]

export default ({location}) => {
    const [page, setPage] = useState(1)
    const [selectedStatus, setSelectedStatus] = useState(location.search.includes('status') ? states[location.search.split('?')[1].split('status=')[1]] : '')
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`scrapping/order?page=${page}${selectedStatus ? `&state=${selectedStatus}` : ''}&limit=${pageSize}`, reload)
    const [viewedOrderID, setViewedOrderID] = useState(null)
    const [showOrderModal, toggleShowOrderModal] = useState(false)
    const [editedOrder, setEditedOrder] = useState(null)
    const [showEdit, toggleShowEdit] = useState(false)
    const [zoomedImage, setZoomedImage] = useState(null)
    const { setLoader } = useLoading()

    const onDelete = (orderID) => {
        confirmAlert('delete this order?', () => {
            setLoader(l => l + 1)
            DeleteObject('scrapping/order', orderID, () => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    return (
        <div className='orders'>
            <Header title="Orders" />

            <Tabs tabs={states}
                selectedTab={selectedStatus} setSelectedTab={setSelectedStatus} />

            <div className='row'>
                {data &&
                    data.map(order =>
                        <Card key={order._id}
                            image='/images/order.png'
                            leftText={formatDate(order.createdAt)}
                            rightText={order.state}>

                            <div className='text-center price'>$ {order.price?.actualTotalReward}</div>

                            <Products products={order.products} setZoomedImage={setZoomedImage} />

                            <div className='mt-3'>
                                <i className="fas fa-id-card-alt"></i>
                                {order.orderId}
                            </div>

                            <Controls>
                                <button onClick={() => { setViewedOrderID(order._id); toggleShowOrderModal(s => !s) }}>
                                    <img src='/images/view.svg' alt='view' />
                                </button>

                                <button onClick={() => { setEditedOrder(order); toggleShowEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => onDelete(order._id)}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }

            <OrderView orderID={viewedOrderID} show={showOrderModal}
                setZoomedImage={setZoomedImage}
            />

            <EditOrder order={editedOrder} show={showEdit} hide={() => toggleShowEdit(s => !s)}
                reload={() => toggleReload(r => !r)} states={states} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}