import React, { useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import { useLoading } from '../../state/loading';
import { pageSize } from '../../lib/constants';
import AddEditPromo from './addEdit';
import { formatDate } from '../../lib/helpers';
import Stats from './stats';
import './style.scss';

const Block = ({ title, value }) =>
    <div className='block'>
        <div className='title'>{title}</div>
        <div className='value'>{value}</div>
    </div>

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`promocodes?page=${page}${search ? `&search=${search}` : ''}&limit=${pageSize}`, reload)
    const [editedPromoID, setEditedPromoID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)
    const [statPromoID, setStatPromoID] = useState(null)
    const [showStats, toggleShowStats] = useState(false)
    const { setLoader } = useLoading()

    const onDelete = (adminID) => {
        confirmAlert('delete this promocode?', () => {
            setLoader(l => l + 1)
            DeleteObject('promocodes', adminID, () => {
                setLoader(l => l - 1)
                toggleReload(r => !r)
            })
        })
    }

    return (
        <div className='promocodes'>
            <Header title="Promocodes" setSearch={setSearch}>
                <button onClick={() => { setEditedPromoID(null); toggleShowAddEdit(s => !s) }}>
                    Add Promocode
                </button>
            </Header>

            <div className='row pt-4'>
                {data?.map(promo =>
                    <Card key={promo._id}
                        image='/images/promocode.png'
                        leftText='Statistics'
                        handleLeftOnClick={() => { setStatPromoID(promo._id); toggleShowStats(s => !s) }}>
                        <div className='mb-4 text-center'>{promo.name}</div>

                        <div className='spaceBetween'>
                            <div className='date'>
                                <img src='/images/calendar.svg' alt='calendar' className='icon' />
                                {formatDate(promo.fromDate)}
                            </div>

                            <i className="fas fa-long-arrow-alt-right"></i>

                            <div className='date'>
                                <img src='/images/calendar.svg' alt='calendar' className='icon' />
                                {formatDate(promo.toDate)}
                            </div>
                        </div>

                        <div className='spaceBetween'>
                            <Block title='Discount Type' value={promo.type} />
                            <Block title='Discount Limit' value={'$' + (promo.limit || promo.amount)} />
                        </div>

                        <div className='spaceBetween'>
                            <Block title='Discount Amount' value={promo.type === "Cash" ? '$' + promo.amount : promo.amount + '%'} />
                            <Block title='Minimum Cash' value={'$' + promo.minCash} />
                        </div>

                        <div className='spaceBetween'>
                            <Block title='Frequency of use for everyone' value={promo.countPerAll} />
                            <Block title='Frequency of use per user' value={promo.countPerUser} />
                        </div>

                        <Controls>
                            <button onClick={() => { setEditedPromoID(promo._id); toggleShowAddEdit(s => !s) }}>
                                <img src='/images/edit.svg' alt='edit' />
                            </button>

                            <button className='danger' onClick={() => onDelete(promo._id)}>
                                <img src='/images/bin.svg' alt='bin' />
                            </button>
                        </Controls>
                    </Card>
                )}
            </div>

            <AddEditPromo promoID={editedPromoID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedPromoID(null) }} />
            <Stats promoID={statPromoID} show={showStats} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}