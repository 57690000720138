import React, { useEffect, useState, useRef } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import UploadFile from "../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { toBase64 } from "../../../lib/helpers";
import { useFetch } from "../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, productID, reload }) => {
  const { data, loading } = useFetch(`products/${productID}`);
  const { data: countries, loading: countriesLoading } = useFetch(`countries/lookup?withUnions=true`);
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [price, setPrice] = useState("");
  const [priority, setPriority] = useState("");
  const [isPrime, setIsPrime] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const photoRef = useRef();

  useEffect(() => {
    if (data && !loading && countries && !countriesLoading) {
      setName(data.name);
      setDescription(data.description);
      setURL(data.url);
      setPrice(data.price);
      setPriority(data.priority);
      setIsPrime(data.isPrime || false)

      if (data.isPrime && data.countryId) {
        let defaultCountry = countries.find(c => c._id === data.countryId)
        setSelectedCountry({ label: defaultCountry.name, value: defaultCountry._id })
      }
    }
  }, [loading, countriesLoading]);

  useEffect(() => {
    if (productID)
      return

    setName("");
    setDescription("");
    setURL("");
    setPrice("")
    setPriority("");
    setIsPrime(false)
    setSelectedCountry(null)
  }, [show, productID]);

  const saveProduct = async (e) => {
    e.preventDefault();

    if (
      name.trim() === "" ||
      description.trim() === "" ||
      url.trim() === "" ||
      !Number(price) ||
      !Number(priority) ||
      (!selectedCountry && isPrime)
    ) {
      Alert("please fill all fields");
      return;
    }

    let product = {
      name,
      description,
      url,
      price,
      priority,
      countryId: isPrime ? selectedCountry.value : null,
      isPrime
    };

    if (photoRef.current.files.length > 0)
      product.image = await toBase64(photoRef.current.files[0]);

    if (productID) {
      let productData = product
      product = { productData, _id: productID }
    }

    confirmAlert(`${productID ? "edit" : "add"} this product?`, () => {
      setIsloading(true);

      if (!productID)
        PostObject("products", product).then(() => {
          afterOperation();
        });
      else
        PutObject("products", product).then((data) => {
          setIsloading(false);
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoRef.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="productAddEdit"
      modalID="addEditProductModal"
      title={`${productID ? "Edit" : "Add"} Product`}
    >
      <UploadFile ref={photoRef} id="productUpload" />

      <form>
        <div className="row">
          <div className="col-12">
            <Field
              label="Name"
              value={name}
              setValue={setName}
              placeholder="write name"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Description"
              value={description}
              setValue={setDescription}
              placeholder="write description"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Link"
              value={url}
              setValue={setURL}
              placeholder="write link"
            />
          </div>


          <div className="col-sm-6">
            <Field
              label="Price"
              value={price}
              setValue={setPrice}
              placeholder="write price"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Priority"
              value={priority}
              setValue={setPriority}
              placeholder="write priority"
            />
          </div>

          <div className="col-sm-6">
            <div className="primeDiv">
              <label className='label isPrimeLabel' htmlFor="isPrime">Is Prime</label>
              <input id="isPrime" type='checkbox' checked={isPrime} onChange={e => setIsPrime(e.target.checked)} />
            </div>
          </div>

          {isPrime &&
            <div className="col-sm-6">
              <Select
                className='select'
                value={selectedCountry}
                onChange={setSelectedCountry}
                options={countries?.map(c => ({ label: c.name, value: c._id }))}
                placeholder="Country"
              />
            </div>
          }
        </div>

        <button className="saveBtn" onClick={saveProduct} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {productID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
