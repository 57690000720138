import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import 'bootstrap-daterangepicker/daterangepicker.css';
import './style.scss';

export default ({ startD, endD, single, onApply }) => {
    console.log(startD)
    function formatDate(event, picker) {
        let start = picker.startDate.format('YYYY-MM-DD')
        let end = picker.endDate.format('YYYY-MM-DD')

        onApply(start, end);
    }

    return (
        <DateRangePicker
            initialSettings={{
                autoApply: true,
                singleDatePicker: true,
                showDropdowns: true,
                startDate: startD,
                locale: {
                    format: "YYYY-MM-DD"
                }
            }}
            startDate= {startD}
            onApply={formatDate}
        >
            <div className="datePicker">
                <i className="fas fa-calendar-alt"></i>
                <span>{startD}</span>
            </div>
        </DateRangePicker>
        //     <DateRangePicker
        //     startDate={startD}
        //     endDate={endD}
        //     locale={{
        //         "format": "YYYY-MM-DD"
        //     }}
        //     onApply={formatDate}
        //     alwaysShowCalendars={true}
        //     opens='left'
        //     ranges={{
        //         'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        //         'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        //         'This Month': [moment().startOf('month'), moment().endOf('month')],
        //         'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        //     }}
        // >
        //     <div className="datePicker">
        //         <i className="fas fa-calendar-alt"></i>
        //         <span>{startD + ' - ' + endD}</span>
        //     </div>
        // </DateRangePicker>
    );
}