import React, { useEffect, useState } from 'react';
import Field from '../../../../components/field';
import Select from 'react-select';
import { useFetch } from '../../../../lib/useFetch';
import './style.scss';

export default ({ products, setProducts, isLoading, saveShipment }) => {
    const { data: categories } = useFetch('categories/lookup');
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedSucategory, setSelectedSubcategory] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [product, setProduct] = useState({})
    const { data: subcategories, loading: subcategoryLoading } = useFetch(`categories/${selectedCategory?.value}/lookup`);

    useEffect(() => {
        if (products.length > 0 && categories) {
            setProduct(products[currentIndex])

            let category = categories.find(c => c._id === products[currentIndex].categoryObj?._id)
            setSelectedCategory(category ? { label: category.name, value: category._id } : null)
        }
    }, [products, categories, currentIndex])

    useEffect(() => {
        if (product && !subcategoryLoading) {
            let subcategory = subcategories.find(c => c._id === product.subCategoryObj?._id)
            setSelectedSubcategory(subcategory ? { label: subcategory.name, value: subcategory._id } : null)
        }
    }, [selectedCategory, subcategoryLoading, product])

    const save = (finish) => {
        let tmp = products
        tmp[currentIndex] = {
            ...product,
            subCategoryId: selectedSucategory.value,
            category: { _id: selectedCategory.value }
        }

        // if (products.length === currentIndex + 1) {
        //     tmp[currentIndex + 1] = {}
        // }

        setProducts([...tmp])

        if (finish)
            saveShipment()
        else
            setCurrentIndex(i => i + 1)
    }

    return (
        <div className='productsAddEdit'>
            {product &&
                <>
                    <div className='label'>Item Picture</div>
                    <img src={product.picture} alt='product' className='itemPicture' />

                    <div className='row'>
                        <div className='col-sm-6'>
                            <Field label='Item Link' value={product.link} setValue={(value) => setProduct(p => ({ ...p, link: value }))}
                                placeholder='paste your link here' icon='link' disabled={true} />
                        </div>
                        <div className='col-sm-6'>
                            <Field label='Item Name' value={product.name} setValue={(value) => setProduct(p => ({ ...p, name: value }))}
                                placeholder='write name' image='./images/package.svg' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='label'>Item Category</div>
                            <Select
                                className='select'
                                value={selectedCategory}
                                onChange={setSelectedCategory}
                                options={categories?.map(c => ({ label: c.name, value: c._id }))}
                                placeholder="Choose Category"
                            />
                        </div>
                        <div className='col-sm-6'>
                            <div className='label'>Item Subcategory</div>
                            <Select
                                className='select'
                                value={selectedSucategory}
                                onChange={setSelectedSubcategory}
                                options={subcategories?.map(c => ({ label: c.name, value: c._id }))}
                                placeholder="Choose Subcategory"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-6'>
                            <Field label='Item Details' value={product.details} setValue={(value) => setProduct(p => ({ ...p, details: value }))}
                                placeholder='write details' />
                        </div>
                        <div className='col-sm-6'>
                            <Field label='Weight' value={product.visableWeight} setValue={(value) => setProduct(p => ({ ...p, visableWeight: value }))}
                                placeholder='write weight' image='./images/weight.svg' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-6'>
                            <Field label='Price' value={product.price} setValue={(value) => setProduct(p => ({ ...p, price: value }))}
                                placeholder='write price' image='./images/cash.svg' />
                        </div>
                        <div className='col-sm-6'>
                            <Field label='Quantity' value={product.quantity} setValue={(value) => setProduct(p => ({ ...p, quantity: value }))}
                                placeholder='write quantity' />
                        </div>
                    </div>
                </>
            }

            <div className='buttons'>
                {products.length > currentIndex + 1 &&
                    <button className='saveBtn nextProduct' onClick={save}>
                        Next Product
                    </button>
                }

                <button className='saveBtn' onClick={() => save(true)} disabled={isLoading}>
                    <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                    {/* {shipmentID ? 'Edit' : 'Add'} */}
                    Edit
                </button>
            </div>
        </div>
    );
}