import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import Card, { Controls } from "../../../components/infoCard";
import PaginationBar from "../../../components/pagination";
import { userImg } from "../../../lib/constants";
import DeleteObject from "../../../lib/deleteObject";
import ImageModal from "../../../components/imageModal";
import { confirmAlert } from "../../../components/alert";
import { useLoading } from "../../../state/loading";
import { pageSize } from "../../../lib/constants";
import Tabs from "../../../components/typesTabsManula";
import InfoCardBlock from "../../../components/infoCardBlock";
import UserView from "./userView";
import axios from "axios";
import UserAddEdit from "./addEdit/index";
import "./style.scss";

const types = ["Traveler", "Shopper"];

export default () => {
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState("Traveler");
  const [page, setPage] = useState(1);
  const [reload, toggleReload] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);
  const { setLoader } = useLoading();
  const [users, setUsers] = useState([]);
  const [viewedUser, setViewedUser] = useState(null);
  const [showViewModal, toggleShowViewModal] = useState(false);
  const [chatUser, setChatUser] = useState(null);
  const [totalCountofDocument, setCount] = useState(0);
  const [showAddEdit, toggleShowAddEdit] = useState(false);
  const [editedUserID, setEditedUserID] = useState(null);

  const onDelete = (shipmentID) => {
    if (selectedType === "Shopper") {
      confirmAlert("delete this user?", () => {
        setLoader((l) => l + 1);
        DeleteObject("dashboardShoppers", shipmentID, () => {
          setLoader((l) => l - 1);
          toggleReload((r) => !r);
        });
      });
    } else {
      confirmAlert("delete this user?", () => {
        setLoader((l) => l + 1);
        DeleteObject("dashboardTravellers", shipmentID, () => {
          setLoader((l) => l - 1);
          toggleReload((r) => !r);
        });
      });
    }
  };

  const openView = (shipment, selectedTab) => {};
  useEffect(() => {
    if (selectedType === "Traveler") {
      axios
        .get(`http://localhost:3000/api/v0/dashboardTravellers?page=${page}`)
        .then((res) => {
          console.log(res.data.data.Travelers);
          setUsers(res.data.data.Travelers);
          setCount(res.data.data.totalCount);
        });
    } else {
      axios
        .get(`http://localhost:3000/api/v0/dashboardShoppers?page=${page}`)
        .then((res) => {
          console.log(res.data.data.shoppers);
          setUsers(res.data.data.shoppers);
          setCount(res.data.data.totalCount);
        });
    }
  }, [selectedType, page, types]);

  return (
    <div className="manualUsers">
      <Header title="Manual Users" setSearch={setSearch}>
        <button onClick={() => toggleShowAddEdit((s) => !s)}>Add User</button>
      </Header>
      <Tabs
        name="Types"
        tabs={types}
        selectedTab={selectedType}
        setSelectedTab={setSelectedType}
      />

      <div className="row">
        {users &&
          users.map((user) => (
            <Card
              key={user._id}
              image={userImg}
              setZoomedImage={setZoomedImage}
              leftText={"22, Jun 2022"}
              rightText="History"
            >
              <div className="mb-1 text-center">
                {user.firstName + user.lastName}
              </div>
              <div className="text-center type">{user.type}</div>

              <InfoCardBlock imageIcon="mail" label="Email">
                {user.email}
              </InfoCardBlock>
              <InfoCardBlock imageIcon="phone" label="Mobile">
                {user.phoneNumber + "/" + user.whatsApp}
              </InfoCardBlock>
              
              <Controls>
                <button
                  onClick={() => {
                    setViewedUser(user._id);
                    toggleShowViewModal((s) => !s);
                  }}
                >
                  <img src="/images/view.svg" alt="view" />
                </button>
                <button>
                  <img src="/images/edit.svg" alt="edit" />
                </button>

                <button className="danger" onClick={() => onDelete(user._id)}>
                  <img src="/images/bin.svg" alt="bin" />
                </button>
              </Controls>
            </Card>
          ))}
      </div>

      {zoomedImage && (
        <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
      )}
      <UserView
        userID={viewedUser?._id}
        show={showViewModal}
        openChat={setChatUser}
      />
      <UserAddEdit
        userID={editedUserID}
        show={showAddEdit}
        hide={() => toggleShowAddEdit((s) => !s)}
        reload={() => {
          toggleReload((r) => !r);
          setEditedUserID(null);
        }}
        userType={selectedType}
      />

      <PaginationBar
        page={page}
        totalCount={totalCountofDocument}
        setPage={setPage}
        pageSize={pageSize}
      />
    </div>
  );
};
