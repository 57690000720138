import React, { useEffect, useState } from 'react';
import Popup from '../../../components/popup';
import Field from '../../../components/field';
import { Alert, confirmAlert } from '../../../components/alert';
import PutObject from '../../../lib/putObject';
import Select from 'react-select';
import { useFetch } from '../../../lib/useFetch';
import DatePicker from '../../../components/dateRangePicker';
import moment from 'moment';
import './style.scss';

export default ({ show, hide, tripID, reload }) => {
    const { data, loading } = useFetch(`trips/${tripID}`)
    const [selectedFromCountry, setSelectedFromCountry] = useState(null)
    const [selectedFromCity, setSelectedFromCity] = useState(null)
    const [selectedToCountry, setSelectedToCountry] = useState(null)
    const [selectedToCity, setSelectedToCity] = useState(null)
    const { data: countries } = useFetch(`countries/lookup`);
    const { data: fromCities, loading: fromCitesLoading } = useFetch(`cities/lookup?countryId=${selectedFromCountry?.value}`);
    const { data: toCities, loading: toCitesLoading } = useFetch(`cities/lookup?countryId=${selectedToCountry?.value}`);
    const [flightNo, setFlightNo] = useState('')
    const [airline, setAirline] = useState('')
    const [capacity, setCapacity] = useState('')
    const [tripDate, setTripDate] = useState(null)
    const [isLoading, setIsloading] = useState(false)

    useEffect(() => {
        if (data && !loading && countries) {
            let fromCountry = countries.find(c => c._id === data.fromCity.countryId)
            setSelectedFromCountry({ label: fromCountry.name, value: fromCountry._id })

            let toCountry = countries.find(c => c._id === data.toCity.countryId)
            setSelectedToCountry({ label: toCountry.name, value: toCountry._id })

            setFlightNo(data.bookingInfo.flightNo)
            setAirline(data.bookingInfo.notes)
            setTripDate(moment(data.tripDate).format('YYYY-MM-DD'))
            setCapacity(data.tripCapacity + '')
        }
    }, [loading, countries])

    useEffect(() => {
        if (data && !fromCitesLoading) {
            let city = fromCities.find(c => c._id === data.fromCity.cityId)
            setSelectedFromCity(city ? { label: city.name, value: city._id } : null)
        }
    }, [selectedFromCountry, fromCitesLoading])

    useEffect(() => {
        if (data && !toCitesLoading) {
            let city = toCities.find(c => c._id === data.toCity.cityId)
            setSelectedToCity(city ? { label: city.name, value: city._id } : null)
        }
    }, [selectedToCountry, toCitesLoading])

    useEffect(() => {
        setTripDate(null)
        if (!tripID) {
            setFlightNo('')
            setAirline('')
            setCapacity('')
        }
    }, [show, tripID])

    const saveTrip = async () => {
        if (flightNo.trim() === '' || airline.trim() === '' || !Number(capacity) || !tripDate
            || !selectedFromCity || !selectedToCity) {
            Alert('please fill all fields')
            return
        }

        let trip = {
            bookingInfo: { flightNo, notes: airline },
            tripDate: tripDate,
            tripCapacity: capacity,
            fromCity: {
                cityId: selectedFromCity.value,
                countryId: selectedFromCountry.value,
            },
            toCity: {
                cityId: selectedToCity.value,
                countryId: selectedToCountry.value
            }
        }

        if (tripID)
            trip._id = tripID

        confirmAlert(`${tripID ? 'edit' : 'add'} this trip?`, () => {
            setIsloading(true)

            // if (!tripID)
            //     PostObject('trips', trip).then(() => {
            //         afterOperation()
            //     })
            // else
            PutObject('trips', trip).then((data) => {
                if (data)
                    afterOperation()
            })
        })
    }

    const afterOperation = () => {
        setIsloading(false)
        reload()
        hide()
    }

    return (
        <Popup show={show} className='tripAddEdit' modalID='addEditTripModal' title={`${tripID ? 'Edit' : 'Add'} Trip`}>
            <div className='row'>
                <div className='col-sm-6'>
                    <Field label='Flight ID' value={flightNo} setValue={setFlightNo}
                        placeholder='write flight ID' />
                </div>

                <div className='col-sm-6'>
                    <Field label='Airline' value={airline} setValue={setAirline}
                        placeholder='write airline' />
                </div>

                <div className='col-sm-6'>
                    <Field label='Trip Capacity' value={capacity} setValue={setCapacity}
                        placeholder='write capacity' icon='weight' />
                </div>
                <div className='col-sm-6'>
                    <div className='label'>Departure Date</div>
                    {tripDate &&
                        <DatePicker startD={tripDate} single={true} onApply={setTripDate} />}
                </div>
            </div>

            <div>
                <div className='label'>Pick From</div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <Select
                            className='select'
                            value={selectedFromCountry}
                            onChange={setSelectedFromCountry}
                            options={countries?.map(c => ({ label: c.name, value: c._id }))}
                            placeholder="From Country"
                        />
                    </div>

                    <div className='col-sm-6'>
                        <Select
                            className='select'
                            value={selectedFromCity}
                            onChange={setSelectedFromCity}
                            options={fromCities?.map(c => ({ label: c.name, value: c._id }))}
                            placeholder="From City"
                        />
                    </div>
                </div>
            </div>

            <div>
                <div className='label'>Deliver To</div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <Select
                            className='select'
                            value={selectedToCountry}
                            onChange={setSelectedToCountry}
                            options={countries?.map(c => ({ label: c.name, value: c._id }))}
                            placeholder="To Country"
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Select
                            className='select'
                            value={selectedToCity}
                            onChange={setSelectedToCity}
                            options={toCities?.map(c => ({ label: c.name, value: c._id }))}
                            placeholder="To City"
                        />
                    </div>
                </div>
            </div>

            <button className='saveBtn' onClick={saveTrip} disabled={isLoading}>
                <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                Edit
            </button>
        </Popup>
    );
}