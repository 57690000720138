import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import UploadFile from "../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { toBase64 } from "../../../lib/helpers";
import { useFetch } from "../../../lib/useFetch";
import "./style.scss";

export default ({ show, hide, notificationID, reload }) => {
  const { data, loading } = useFetch(`notifications/${notificationID}`);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const photoRef = useRef();

  useEffect(() => {
    if (data && !loading) {
      setTitle(data.title);
      setBody(data.body);
    }
  }, [loading]);

  useEffect(() => {
    if (notificationID)
      return

    setTitle("");
    setBody("");
  }, [show, notificationID]);

  const saveNotification = async (e) => {
    e.preventDefault();

    if (title.trim() === "" || body.trim() === "") {
      Alert("please fill all fields");
      return;
    }

    let notification = {
      title,
      body
    };

    if (notificationID) notification._id = notificationID;

    if (photoRef.current.files.length > 0)
      notification.image = await toBase64(photoRef.current.files[0]);

    confirmAlert(`${notificationID ? "edit" : "add"} this notification?`, () => {
      setIsloading(true);

      if (!notificationID)
        PostObject("notifications", notification).then(() => {
          afterOperation();
        });
      else
        PutObject("notifications", notification).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoRef.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="notificationAddEdit"
      modalID="addEditNotificationModal"
      title={`${notificationID ? "Edit" : "Add"} Notification`}
    >
      <UploadFile ref={photoRef} id="notificationUpload" />

      <form>
        <div className="row">
          <div className="col-12">
            <Field
              label="Title"
              value={title}
              setValue={setTitle}
              placeholder="write title"
            />
          </div>

          <div className="col-12">
            <div className="fieldDiv">
              <div className="label">Body</div>

              <textarea
                className="field"
                value={body}
                onChange={e => setBody(e.target.value)}
                placeholder="write body"
              />
            </div>
          </div>
        </div>

        <button className="saveBtn" onClick={saveNotification} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {notificationID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
