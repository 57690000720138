import React, { useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import { userImg } from '../../lib/constants';
import { formatDate } from '../../lib/helpers';
import DeleteObject from '../../lib/deleteObject';
import PutObject from '../../lib/putObject';
import Chat from '../../components/chat';
import UsersChat from '../../containers/usersChats/chat';
import Tabs from '../../components/typesTabs';
import ImageModal from '../../components/imageModal';
import TripDestination from '../../components/tripDestination';
import { confirmAlert } from '../../components/alert';
import { useLoading } from '../../state/loading';
import ShipmentView from './shipmentView';
import TripView from '../trips/tripView';
import Products from './products';
import ShipmentAddEdit from './addEdit';
import RefundForm from './refundForm';
import Select from 'react-select';
import { pageSize, baseUrl } from '../../lib/constants';
import './style.scss';

const types = [{ value: '', label: 'All Types' },
{ value: 'ShipmentOnly', label: 'Ship Only' },
{ value: 'PurchaseAndShip', label: 'Purchase and Ship' },
{ value: 'PrimeShipment', label: 'Prime Shipment' }]

const statuses = ["In Negotiation", "Offer Accepted", "Partially Paid", "waiting for picking", "Picked", "Delivered", "InActive"]
const extraFilters = ["", "Home Delivery", "Without Matched", "With Matched", "Without Offers", "With Offers"]

const sortTypes = [{ label: "Action Date", value: "updatedAt" }, { label: "Creation Date", value: "createdAt" }]

export default ({ location }) => {
    const { data: countries } = useFetch(`countries/lookup?withUnions=true`);
    const [selectedFromCountry, setSelectedFromCountry] = useState(null)
    const [selectedToCountry, setSelectedToCountry] = useState(null)
    const [extraFilter, setExtraFilter] = useState(location.search.includes('extra') ? { label: extraFilters[location.search.split('extra=')[1]], value: extraFilters[location.search.split('extra=')[1]] } : { label: 'All', value: '' })
    const [search, setSearch] = useState("")
    const [sortType, setSortType] = useState(sortTypes[0])
    const [page, setPage] = useState(1)
    const [selectedType, setSelectedType] = useState(location.search.includes('type') ? types[location.search.split('?type=')[1]].value : types[0])
    const [selectedStatus, setSelectedStatus] = useState(location.search.includes('status') ? statuses[location.search.split('&')[0].split('status=')[1]] : '')
    const [reload, toggleReload] = useState(false)
    const { userID } = useParams()
    const { data, totalCount } = useFetch(!location.search.includes('match') ? `shipments/v2?page=${page}${selectedType.value ? `&shipmentType=${selectedType.value}` : ''}${selectedStatus ? `&${selectedStatus === statuses[5] ? 'milestone' : 'state'}=${selectedStatus}${selectedStatus === statuses[4] ? '&milestone=In Progress' : ''}` : ''}${location.search.includes('tripID') ? `&tripId=${location.search.split('?tripID=')[1]}` : ''}${userID ? `&userId=${userID}` : ''}${extraFilter.value ? `&extraFilter=${extraFilter.value}` : ''}${search ? `&search=${search}` : ''}${selectedFromCountry ? `&fromCountryId=${selectedFromCountry.value}` : ''}${selectedToCountry ? `&toCountryId=${selectedToCountry.value}` : ''}&sort=${sortType.value}:-1&limit=${pageSize}&all=true` : `trips/${location.search.split('?match=')[1]}/matched-shipments/v2?page=${page}`, reload)
    const [chatUser, setChatUser] = useState(null)
    const [usersChatID, setUsersChatID] = useState(null)
    const [zoomedImage, setZoomedImage] = useState(null)
    const [viewedShipment, setViewedShipment] = useState(null)
    const [showViewModal, toggleShowViewModal] = useState(false)
    const [viewedTripID, setViewedTripID] = useState(null)
    const [showTripModal, toggleShowTripModal] = useState(false)
    const [showAddEdit, toggleShowAddEdit] = useState(false)
    const [editedShipmentID, setEditedShipmentID] = useState(null)
    const [showRefundForm, toggleShowRefundForm] = useState(false)
    const [refundedShipmentID, setRefundedShipmentID] = useState(null)
    const [selectedTab, setSelectedTab] = useState(0)
    const { setLoader } = useLoading()
    const orderIdsRef = useRef([]);

    const onDelete = (shipmentID) => {
        confirmAlert('delete this shipment?', () => {
            setLoader(l => l + 1)
            DeleteObject('shipments', shipmentID, (success) => {
                setLoader(l => l - 1)

                if (success)
                    toggleReload(r => !r)
            })
        })
    }

    // const updateShipmentDate = (shipmentID) => {
    //     confirmAlert('update this shipment date?', () => {
    //         setLoader(l => l + 1)
    //         PutObject(`shipments/${shipmentID}/setCurrentDates`, {}).then(() => {
    //             setLoader(l => l - 1)
    //             toggleReload(r => !r)
    //         })
    //     })
    // }

    const payShipment = (shipmentID, i) => {
        confirmAlert('update this shipment status to waiting for picking?', () => {
            setLoader(l => l + 1)
            PutObject(`shipments/${shipmentID}/passShipmentPayment`, { orderId: orderIdsRef.current[i].value }).then((data) => {
                setLoader(l => l - 1)
                if (data)
                    toggleReload(r => !r)
            })
        })
    }

    const partiallyPayShipment = (shipmentID, i) => {
        confirmAlert('update this shipment status to partially paid?', () => {
            setLoader(l => l + 1)
            PutObject(`shipments/${shipmentID}/passPartialPayment`, { orderId: orderIdsRef.current[i].value }).then((data) => {
                setLoader(l => l - 1)
                if (data)
                    toggleReload(r => !r)
            })
        })
    }

    const pickShipment = (shipmentID) => {
        confirmAlert('update this shipment status to picked?', () => {
            setLoader(l => l + 1)
            PutObject(`shipments/${shipmentID}/pick`, {}).then((data) => {
                setLoader(l => l - 1)
                if (data)
                    toggleReload(r => !r)
            })
        })
    }

    const deliverShipment = (shipmentID) => {
        confirmAlert('update this shipment status to delivered?', () => {
            setLoader(l => l + 1)
            PutObject(`shipments/${shipmentID}/delivered`, {}).then((data) => {
                setLoader(l => l - 1)

                if (data)
                    toggleReload(r => !r)
            })
        })
    }

    const openView = (shipment, selectedTab) => {
        setViewedShipment(shipment)
        setSelectedTab(selectedTab)
        toggleShowViewModal(s => !s)
    }

    const exportReport = () => {
        window.open(`${baseUrl}/shipments/v2?Export=true&all=true${selectedType.value ? `&shipmentType=${selectedType.value}` : ''}${selectedStatus ? `&${selectedStatus === statuses[5] ? 'milestone' : 'state'}=${selectedStatus}${selectedStatus === statuses[4] ? '&milestone=In Progress' : ''}` : ''}${location.search.includes('tripID') ? `&tripId=${location.search.split('?tripID=')[1]}` : ''}${userID ? `&userId=${userID}` : ''}${extraFilter.value ? `&extraFilter=${extraFilter.value}` : ''}${search ? `&search=${search}` : ''}${selectedFromCountry ? `&fromCountryId=${selectedFromCountry.value}` : ''}${selectedToCountry ? `&toCountryId=${selectedToCountry.value}` : ''}`, '_blank')
    }

    return (
        <div className='shipments'>
            <Header title="Shipments" setSearch={setSearch}>
                <Select
                    className='select'
                    value={selectedFromCountry}
                    onChange={setSelectedFromCountry}
                    options={countries?.map(c => ({ label: c.name, value: c._id }))}
                    placeholder="From Country"
                />
                <Select
                    className='select'
                    value={selectedToCountry}
                    onChange={setSelectedToCountry}
                    options={countries?.filter(c => !c.isUnion).map(c => ({ label: c.name, value: c._id }))}
                    placeholder="To Country"
                />

                <Select
                    className='select'
                    value={extraFilter}
                    onChange={setExtraFilter}
                    options={extraFilters.map(f => ({ label: f || 'All', value: f }))}
                />

                <Select
                    className='select'
                    value={selectedType}
                    onChange={setSelectedType}
                    options={types}
                />

                <Select
                    className='select'
                    value={sortType}
                    onChange={setSortType}
                    options={sortTypes}
                />

                <button onClick={exportReport} className='exportBtn'>
                    Export
                </button>
            </Header>

            <Tabs tabs={statuses}
                selectedTab={selectedStatus} setSelectedTab={setSelectedStatus} />

            <div className='row'>
                {data &&
                    data.map((shipment, i) =>
                        <Card key={shipment._id}
                            image={shipment.ownerObj?.photo || userImg}
                            setZoomedImage={setZoomedImage}
                            leftText={shipment.state}
                            rightText={formatDate(new Date(shipment.createdAt))}>
                            <div className='mb-2 text-center'>{shipment.ownerObj?.username}</div>

                            {shipment.paidByWallet &&
                                <img src='/images/walletPatch.png' alt='wallet' className='walletPatch' />
                            }
                            
                            <TripDestination fromCity={{ name: shipment.fromCityObj.name, countryName: shipment.fromCountryObj.name }}
                                toCity={{ name: shipment.toCityObj.name, countryName: shipment.toCountryObj.name }}
                                shrinkText={true} />

                            <Products products={shipment.products} setZoomedImage={setZoomedImage} />

                            <div className='mt-3 spaceBetween'>
                                {shipment.offerAcceptedDate &&
                                    <div>
                                        <i className='fas fa-check-double'></i>
                                        {formatDate(shipment.offerAcceptedDate, true)}
                                    </div>
                                }

                                {shipment.isHomeDelivery &&
                                    <div>
                                        <i className="fas fa-shipping-fast"></i>
                                        Home Delivery
                                    </div>
                                }
                            </div>

                            {(shipment.state === statuses[1] || shipment.state === statuses[2]) &&
                                <div className='mt-3 spaceBetween'>
                                    <div className='orderIDField'>
                                        <i className='fas fa-shopping-cart'></i>
                                        <input ref={el => orderIdsRef.current[i] = el} />
                                    </div>
                                    {shipment.state === statuses[1] &&
                                        <div onClick={() => partiallyPayShipment(shipment._id, i)}>
                                            <i className="fas fa-angle-double-right partialBtn"></i>
                                        </div>
                                    }
                                </div>
                            }

                            {shipment.partialPaymentRef &&
                                <div className='mt-3 spaceBetween'>
                                    <div>
                                        <i className="fas fa-shopping-basket"></i>
                                        {shipment.partialPaymentRef}
                                    </div>
                                    <div>
                                        <img src='/images/clock.svg' alt='clock' className='icon' />
                                        {formatDate(shipment.partialPaymentDate, true)}
                                    </div>
                                </div>
                            }

                            {shipment.orderId &&
                                <div className='mt-3 spaceBetween'>
                                    <div>
                                        <i className='fas fa-shopping-cart'></i>
                                        {shipment.orderId}
                                    </div>
                                    <div>
                                        <img src='/images/clock.svg' alt='clock' className='icon' />
                                        {formatDate(shipment.paymentDate, true)}
                                    </div>
                                </div>
                            }

                            {/* <div className='mt-3 spaceBetween'>
                                <div>
                                <i className="fas fa-check-double"></i>
                                {formatDate(new Date(shipment.shipmentArrivalDate))}
                                </div>
                            </div> */}
                            <div className='mt-3 spaceBetween'>
                                <div>
                                    <img src='/images/calendar.svg' alt='calendar' className='icon' />
                                    {formatDate(shipment.shipmentArrivalDate)}
                                </div>

                                {shipment.state === statuses[0] &&
                                    <Link className='greenBtn' to={`/Trips?match=${shipment._id}`}>
                                        Matched
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </Link>
                                }

                                {statuses.indexOf(shipment.state) > 0 && statuses.indexOf(shipment.state) < 6 &&
                                    <span className='greenBtn'
                                        onClick={() => openView(shipment, 2)}
                                    >
                                        View Invoice
                                    </span>
                                }
                            </div>

                            {statuses.slice(3, 5).indexOf(shipment.state) > -1 && shipment.milestone !== 'Delivered' &&
                                <button className='refundBtn' onClick={() => { setRefundedShipmentID(shipment._id); toggleShowRefundForm(s => !s) }}>Refund</button>
                            }

                            <Controls>
                                <button onClick={() => openView(shipment, 0)}>
                                    <img src='/images/view.svg' alt='view' />
                                </button>

                                {statuses.indexOf(shipment.state) > 0 &&
                                    <button title='view trip'
                                        onClick={() => { setViewedTripID(shipment.tripId); toggleShowTripModal(s => !s) }}>
                                        <i className="fas fa-plane viewTripIcon"></i>
                                    </button>
                                }

                                <button onClick={() => setChatUser({ ID: shipment.owner.userId, username: shipment.owner.username, photo: shipment.owner.photo })}>
                                    <img src='/images/chat.svg' alt='chat' />
                                </button>

                                {(statuses[1] === shipment.state || statuses[2] === shipment.state) &&
                                    <button title='pay shipment'
                                        onClick={() => payShipment(shipment._id, i)}>
                                        <i className="fas fa-angle-double-right"></i>
                                    </button>
                                }

                                {statuses[3] === shipment.state &&
                                    <button title='pick shipment'
                                        onClick={() => pickShipment(shipment._id)}>
                                        <i className="fas fa-angle-double-right"></i>
                                    </button>
                                }

                                {statuses[4] === shipment.state &&
                                    <button title='deliver shipment'
                                        onClick={() => deliverShipment(shipment._id)}>
                                        <i className="fas fa-angle-double-right"></i>
                                    </button>
                                }

                                {/* <button onClick={() => updateShipmentDate(shipment._id)}>
                                    <i className="fas fa-clock clockIcon"></i>
                                </button> */}

                                <button onClick={() => { setEditedShipmentID(shipment._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                {(shipment.state === statuses[3] || shipment.state === statuses[4]) ?
                                    <button title='users chat' onClick={() => setUsersChatID(shipment._id + '_' + shipment.tripId)}>
                                        <i className='fas fa-comment-dots'></i>
                                    </button> :
                                    <button className='danger' onClick={() => onDelete(shipment._id)}>
                                        <img src='/images/bin.svg' alt='bin' />
                                    </button>
                                }
                            </Controls>
                        </Card>
                    )}
            </div>

            {chatUser && <Chat user={chatUser} onClose={() => setChatUser(null)} />}
            {usersChatID && <UsersChat chatID={usersChatID} onClose={() => setUsersChatID(null)} />}

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }

            <ShipmentView shipmentID={viewedShipment?._id} show={showViewModal}
                defaultTab={selectedTab} setZoomedImage={setZoomedImage}
                openChat={setChatUser}
                openTripDetails={(tripID) => { setViewedTripID(tripID); toggleShowTripModal(s => !s); toggleShowViewModal(s => !s) }} />

            <TripView tripID={viewedTripID} show={showTripModal} openChat={setChatUser} />

            <ShipmentAddEdit shipmentID={editedShipmentID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedShipmentID(null) }} />

            <RefundForm show={showRefundForm} shipmentID={refundedShipmentID} reload={() => { toggleReload(r => !r); toggleShowRefundForm(s => !s) }} />

            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}