import axios from 'axios'
import { baseUrl } from './constants';
import { Alert } from '../components/alert';

const DeleteObject = (api, id, callBack) => {
  const authTokens = JSON.parse(
    localStorage.getItem('tokens')
  );
  const payload = { headers: { Authorization: authTokens } }

  axios.delete(`${baseUrl}/${api}/${id}`, payload)
    .then(response => {
      if (response.status === 200 || response.data.data.status === 200) {
        Alert(response.data.message, true)
        callBack(true)
      }
    }).catch(error => {
      Alert(error.message, false)
      callBack(false)
    });
}

export default DeleteObject;