import React, { useState } from 'react';
import { Card, Button, Form } from "tabler-react";
import { useForm } from '../../lib/useForm'
import PostObject from '../../lib/postObject';

const FAQCreateModal = ({ closeMainModal, reload }) => {
  const [faq, handleChange] = useForm({ question: '', answer: '' })
  const [saveLoading, toggleSaveLoading] = useState(false)

  const saveFaq = () => {
    toggleSaveLoading(true)
    PostObject('settings/faq', faq).then(() => {
      toggleSaveLoading(false)
      reload()
      closeMainModal()
    });
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Create FAQ</Card.Title>
        <Card.Options>
          <Button
            size="sm"
            icon="x"
            onClick={() => closeMainModal()}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.FieldSet>
            <Form.Input
              name='question'
              label='question'
              placeholder=''
              value={faq['question']}
              onChange={handleChange}
            />

            <Form.Input
              name='answer'
              label='answer'
              placeholder=''
              value={faq['answer']}
              onChange={handleChange}
            />
          </Form.FieldSet>
        </Form>
      </Card.Body>
      <Card.Footer>
      <div style={{ textAlign: 'right' }}>
          <Button
            color="primary"
            size="sm"
            className={`ml-2 ${saveLoading ? 'btn-loading' : ''}`}
            type="submit"
            onClick={saveFaq}
            disabled={saveLoading}
          >
            Save
          </Button>
          <Button
            color="secondary"
            size="sm"
            className="ml-2"
            onClick={() => closeMainModal()}
            disabled={saveLoading}
          >
            cancel
          </Button>
        </div>
      </Card.Footer>
    </Card>
  )
}

export default FAQCreateModal;