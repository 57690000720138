import { baseUrl } from './constants';
import { Alert } from '../components/alert';
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const PostObject = async (url, obj, withoutToast) => {
  const authTokens = JSON.parse(localStorage.getItem('tokens'));
  const headers = { Authorization: authTokens };
  const data = obj
console.log(data)
  return new Promise((resolve, reject) => {
    axios({
      url: `${baseUrl}/${url}`,
      method: 'post',
      data,
      headers
    }).then(response => {
      if (response.status === 201 || response.status === 200 || response.data.data.status === 200) {
        if (!withoutToast)
          Alert(response.data.message, true)

        resolve(response.data)
      }
      // resolve was here
    })
      .catch(error => {
        console.log(error.response)
        Alert(error?.response?.data?.data, false)
        resolve(null)
      });
  });
}


export default PostObject;
