import React from 'react';
import Modal from 'react-modal';
import { mainModalStyle } from '../../lib/constants'
import FAQEditModal from '../../containers/settings/faq-edit'

import FAQCreateModal from '../../containers/settings/faq-create'
import ConfirmDeleteModal from './confirm-delete'

const style = { ...mainModalStyle }
const MainModal = ({
  closeMainModal,
  modalVisible,
  recordId,
  modalVariant,
  reload
}) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={modalVisible}
      onRequestClose={() => closeMainModal()}
      overlayClassName="overlay"
      style={style}
    >

      {modalVariant === 'faq-edit' && recordId &&
        <FAQEditModal
          recordId={recordId}
          closeMainModal={closeMainModal}
          reload={reload}
        />
      }

      {modalVariant === 'faq-create' &&
        <FAQCreateModal
          closeMainModal={closeMainModal}
          reload={reload}
        />
      }

      {modalVariant && modalVariant.split('--')[1] === 'delete' &&
        <ConfirmDeleteModal
          closeMainModal={closeMainModal}
          type={modalVariant.split('--')[0]}
          recordId={recordId}
          reload={reload}
        />
      }
    </Modal>
  )
}
export default MainModal;