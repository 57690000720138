import React, { useEffect, useRef } from 'react';
import './style.scss';

export const PopupTabs = ({ tabs, selectedTab, setSelectedTab, isChild }) =>
    <div className={`popupTabs ${isChild ? 'child' : ''}`}>
        {tabs.map((tab, i) =>
            <div key={i} className={`tab ${i === selectedTab ? 'active' : ''}`}
                onClick={() => setSelectedTab(i)}>{tab}</div>
        )}
    </div>

export default ({ modalID, show, className, title, isSmall, large, children }) => {
    const btnRef = useRef()
    const initialized = useRef(false)

    useEffect(() => {
        if (initialized.current)
            btnRef.current.click()
        initialized.current = true
    }, [show])

    return (
        <>
            <button className='modalBtn' data-toggle="modal" data-target={`#${modalID}`}
                ref={btnRef}></button>

            <div className={`modal fade ${className} ${large ? 'large' : ''}`} id={modalID} aria-hidden="true">
                <div className={`modal-dialog  modal-dialog-centered ${isSmall ? 'modal-sm' : 'modal-md'}`}>
                    <div className="modal-content">
                        <div className='modal-body'>
                            <div className='headline'>
                                <span>{title}</span>
                                <span data-dismiss="modal" className='clickable'><i className='fas fa-times'></i></span>
                            </div>

                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}