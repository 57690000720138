import React, { useState, useEffect } from 'react';
import { Card, Button, Tab, TabbedCard, Form, Dimmer } from "tabler-react";
import { useFetch } from '../../lib/useFetch';
import putObject from '../../lib/putObject';
import RecordsTable from './recordsTable';
import CKEditor from "react-ckeditor-component";

const settingsHeaders = ["Question", "Answer"];

const Settings = ({ openMainModal }) => {
  const [reload, toggleReload] = useState(false)
  const { data, loading, handleChange } = useFetch(`settings`, reload);
  const [saveLoading, toggleSaveLoading] = useState(false)
  const [aboutUs, setAboutUs] = useState('')
  const [privacyPolicy, setPrivacyPolicy] = useState('')

  useEffect(() => {
    if (!loading && data) {
      setAboutUs(data.settings['aboutUs'])
      setPrivacyPolicy(data.settings['privacyPolicy'])
    }
  }, [loading])

  const saveEdit = () => {
    const settings = {
      aboutUs: aboutUs,
      privacyPolicy: privacyPolicy,
      deliveryFees: data.settings.deliveryFees,
      paymentFees: data.settings.paymentFees,
      percentageFromTotalFees: data.settings.percentageFromTotalFees,
      fixedValueAddedToTotalFees: data.settings.fixedValueAddedToTotalFees,
      fromUsdToEgp: data.settings.fromUsdToEgp,
      appVersion: data.settings.appVersion
    }

    toggleSaveLoading(true)
    putObject('settings', { ...settings }).then((data) => {
      if (data)
        toggleSaveLoading(false)
    })
  }

  return (
    <Card className="cardsettings">
      <Dimmer active={loading} loader={loading}>
        <TabbedCard initialTab="Settings">
          <Tab title="Settings">
            <Card.Header>
              <Card.Title>Settings </Card.Title>
            </Card.Header>

            {!loading && data &&
              <Card.Body>
                <Form>
                  <Form.Label>About Us</Form.Label>
                  <CKEditor
                    content={aboutUs}
                    events={{
                      "change": (e) => setAboutUs(e.editor.getData())
                    }}
                  />

                  <Form.Label>Privacy Policy</Form.Label>
                  <CKEditor
                    content={privacyPolicy}
                    events={{
                      "change": (e) => setPrivacyPolicy(e.editor.getData())
                    }}
                  />

                  <Form.Input
                    name='settings.deliveryFees'
                    label='Delivery Fees'
                    placeholder=''
                    value={data.settings['deliveryFees']}
                    onChange={(e) => handleChange(e)}
                  />

                  <Form.Input
                    name='settings.paymentFees'
                    label='Payment Fees'
                    placeholder=''
                    value={data.settings['paymentFees']}
                    onChange={(e) => handleChange(e)}
                  />

                  <Form.Input
                    name='settings.percentageFromTotalFees'
                    label='Percentage From Total Fees'
                    placeholder=''
                    value={data.settings['percentageFromTotalFees']}
                    onChange={(e) => handleChange(e)}
                  />

                  <Form.Input
                    name='settings.fixedValueAddedToTotalFees'
                    label='Fixed Value Added To Total Fees'
                    placeholder=''
                    value={data.settings['fixedValueAddedToTotalFees']}
                    onChange={(e) => handleChange(e)}
                  />

                  <Form.Input
                    name='settings.fromUsdToEgp'
                    label='From USD to EGP'
                    placeholder=''
                    value={data.settings['fromUsdToEgp']}
                    onChange={(e) => handleChange(e)}
                  />

                  <Form.Input
                    name='settings.appVersion'
                    label='App Version'
                    placeholder=''
                    value={data.settings['appVersion']}
                    onChange={(e) => handleChange(e)}
                  />
                </Form>
              </Card.Body>
            }

            <Card.Footer>
              <div style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  size="sm"
                  className={`ml-2 ${saveLoading ? 'btn-loading' : ''}`}
                  onClick={saveEdit}
                  disabled={saveLoading}
                >
                  Save
                </Button>
              </div>
            </Card.Footer>
          </Tab>
          <Tab title="FAQ">
            <RecordsTable
              headers={settingsHeaders}
              records={data}
              openMainModal={openMainModal}
              reload={() => toggleReload(r => !r)}
            />
          </Tab>
        </TabbedCard>
      </Dimmer>
    </Card>
  )
}

export default Settings;