import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import UploadFile from "../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { toBase64 } from "../../../lib/helpers";
import { useFetch } from "../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, userID, reload }) => {
  const { data, loading } = useFetch(`users/${userID}`);
  const { data: countries, loading: countriesLoading } =
    useFetch(`countries/lookup`);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCode, setSelectedCode] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const photoRef = useRef();

  useEffect(() => {
    if (data && !loading && countries && !countriesLoading) {
      let defaultCountry = countries.find(
        (c) => c.callingCode === data.user.countryCode
      );
      console.log(data.user.countryCode);
      console.log(countries);
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
      setEmail(data.user.email);
      setSelectedCode({
        label: defaultCountry.callingCode,
        value: defaultCountry.callingCode,
        flag: defaultCountry.countryFlag,
      });
      setPhoneNumber(data.user.phoneNumber);
    }
  }, [loading, countriesLoading]);

  useEffect(() => {
    if (userID)
      return

    setFirstName("");
    setLastName("");
    setEmail("");
    setSelectedCode(null);
    setPhoneNumber("");
  }, [show, userID]);

  const saveUser = async (e) => {
    e.preventDefault();

    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      phoneNumber.trim() === "" ||
      !selectedCode
    ) {
      Alert("please fill all fields");
      return;
    }

    let user = {
      firstName,
      lastName,
      email,
      phoneNumber,
      countryCode: selectedCode.value,
    };

    if (userID) user._id = userID;

    if (photoRef.current.files.length > 0)
      user.photo = await toBase64(photoRef.current.files[0]);

    confirmAlert(`${userID ? "edit" : "add"} this user?`, () => {
      setIsloading(true);

      if (!userID)
        PostObject("users", user).then(() => {
          afterOperation();
        });
      else
        PutObject("users", user).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoRef.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="userAddEdit"
      modalID="addEditUserModal"
      title={`${userID ? "Edit" : "Add"} User`}
    >
      <UploadFile ref={photoRef} id="userUpload" />

      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="First Name"
              value={firstName}
              setValue={setFirstName}
              placeholder="write first name"
              icon="user"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Last Name"
              value={lastName}
              setValue={setLastName}
              placeholder="write last name"
              icon="user"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Email Address"
              value={email}
              setValue={setEmail}
              placeholder="write email address"
              icon="envelope"
            />
          </div>
          <div className="col-sm-6">
            <div className="fieldDiv">
              <div className="label">Phone Number</div>

              <Select
                className="select"
                value={selectedCode}
                onChange={setSelectedCode}
                options={countries?.map((c) => ({
                  label: c.callingCode,
                  value: c.callingCode,
                  flag: c.countryFlag,
                }))}
                placeholder="Code"
                formatOptionLabel={(country) => (
                  <div className="countryList">
                    <img src={country.flag} alt="flag" />
                    <span>{country.label}</span>
                  </div>
                )}
              />

              <div className="field phoneField">
                <input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="number"
                />
              </div>
            </div>
          </div>
        </div>

        <button className="saveBtn" onClick={saveUser} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {userID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
