import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import { formatDate } from '../../lib/helpers';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import { pageSize } from '../../lib/constants';
import AddEditCategory from './addEdit';
import './style.scss';

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`categories?page=${page}${search ? `&search=${search}` : ''}&limit=${pageSize}`, reload)
    const [editedCategoryID, setEditedCategoryID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)

    const onDelete = (categoryID) => {
        DeleteObject('categories', categoryID, () => toggleReload(r => !r))
    }

    return (
        <div className='categories'>
            <Header title="Categories" setSearch={setSearch}>
                <button onClick={() => { setEditedCategoryID(null); toggleShowAddEdit(s => !s) }}>
                    Add Category
                </button>
            </Header>

            <div className='row pt-4'>
                {data &&
                    data.map(category =>
                        <Card key={category._id}
                            image={category.icon}>
                            <div className='mb-3 text-center'>{category.name}</div>

                            <div className='mb-3 spaceBetween'>
                                <div>
                                    <img src='/images/category.svg' alt='categories' className='icon' />
                                    Subcategories
                                </div>

                                <Link to={`/categories/${category._id}/subcategories`}>
                                    <i className="fas fa-long-arrow-alt-right arrow"></i>
                                </Link>
                            </div>

                            <div>
                                <img src='/images/calendar.svg' alt='calendar' className='icon' />
                                {formatDate(new Date(category.createdAt))}
                            </div>

                            <Controls>
                                <button onClick={() => { setEditedCategoryID(category._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => confirmAlert('delete this category?', () => onDelete(category._id))}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            <AddEditCategory categoryID={editedCategoryID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedCategoryID(null) }} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}