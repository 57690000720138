import React from 'react';
import { useFetch } from '../../lib/useFetch';
import Invoice from '../../containers/shipments/shipmentView/invoice';
import { formatDate } from '../../lib/helpers';
import { confirmAlert } from '../../components/alert';
import DeleteObject from '../../lib/deleteObject';
import './style.scss';
import { useState } from 'react';

export default ({ shipmentID, tripID, openTripDetails, openShipmentDetails }) => {
    const [reload, toggleReload] = useState(false)
    const { data } = useFetch(`offers/v2?${shipmentID ? `shipmentId=${shipmentID}` : `tripId=${tripID}`}`, reload)

    const deleteOffer = (offerID) => {
        confirmAlert('delete this offer?', () => {
            DeleteObject('offers', offerID, () => {
                toggleReload(r => !r)
            })
        })
    }

    return (
        <div className='accordion' id="shipmentOffers">
            {data && data.map((offer, i) =>
                <div key={i} className="offer">
                    <div className={`head ${offer.status === 'Paid' ? 'green' : offer.acceptDate ? 'highlighted' : ''}`}>
                        <img src={offer.ownerObj?.photo} alt='profile' className='profile' />

                        <div className='spaceBetween'>
                            <div>
                                <div className='name'>{offer.ownerObj?.firstName} {offer.ownerObj?.lastName} ({offer.ownerRole})</div>
                                <div className='reward'>$ {offer.reward}</div>
                            </div>

                            <div>
                                {formatDate(new Date(offer.createdAt))}
                                {openTripDetails &&
                                    <div className='greenBtn' onClick={() => openTripDetails(offer.tripId)}>
                                        Trip Details
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </div>}

                                {openShipmentDetails &&
                                    <div className='greenBtn' onClick={() => openShipmentDetails(offer.shipmentId)}>
                                        Shipment Details
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </div>}
                            </div>

                            {offer.status !== 'Paid' &&
                                <button className='deleteBtn' onClick={() => deleteOffer(offer._id)}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            }

                            <img src='/images/collapseArrowDown.svg' alt='arrow' className='arrow'
                                data-toggle="collapse" data-target={`#offer${i}`} aria-expanded="false" />
                        </div>
                    </div>

                    <div id={`offer${i}`} className="collapse" data-parent="#shipmentOffers">
                        <div className='mt-4'>
                            <Invoice offerID={offer._id} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}