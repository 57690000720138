import React, { useRef, useState } from 'react';
import Popup from '../../../components/popup';
import UploadFile from '../../../components/uploadFile';
import { confirmAlert } from '../../../components/alert';
import PutObject from '../../../lib/putObject';
import { toBase64 } from '../../../lib/helpers';
import './style.scss';

export default ({ show, hide, passportID, onSuccess }) => {
    const [isLoading, setIsloading] = useState(false)
    const passportPhotoRef = useRef()
    const facePhotoRef = useRef()

    const save = async (e) => {
        e.preventDefault();

        let editedPassport = {
            _id: passportID
        }

        if (passportPhotoRef.current.files.length > 0)
            editedPassport.firstShotImage = await toBase64(passportPhotoRef.current.files[0])
        if (facePhotoRef.current.files.length > 0)
            editedPassport.faceImage = await toBase64(facePhotoRef.current.files[0])

        confirmAlert(`edit this passport?`, () => {
            setIsloading(true)

            PutObject('personalsIdentity', editedPassport).then((data) => {
                if (data) {
                    setIsloading(false)
                    onSuccess()
                    hide()
                    passportPhotoRef.current.value = null
                    facePhotoRef.current.value = null
                }
            })
        })
    }

    return (
        <Popup show={show} className='passportEdit' modalID='passportUserModal' title={'Edit Passport'}>

            <div className='imagesDiv'>
                <div>
                    <UploadFile ref={passportPhotoRef} id="passportUpload" accept='image/*' />
                    <div className='imageLabel'>Passport</div>
                </div>
                <div>
                    <UploadFile ref={facePhotoRef} id="faceUpload" accept='image/*' />
                    <div className='imageLabel'>Face</div>
                </div>
            </div>

            <button className='saveBtn' onClick={save} disabled={isLoading}>
                <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                Edit
            </button>
        </Popup>
    );
}