import React from 'react';
import './style.scss';

export default ({ currentStep, setCurrentStep }) =>
    <div className='shipmentStepper'>
        <div className='top'>
            <button className={`step ${currentStep >= 1 ? 'active' : ''}`} onClick={() => setCurrentStep(1)} disabled={currentStep <= 1}></button>
            <div className={`line ${currentStep > 1 ? 'active' : ''}`}></div>
            <button className={`step ${currentStep >= 2 ? 'active' : ''}`} onClick={() => setCurrentStep(2)} disabled={currentStep <= 2}></button>
            {/* <div className={`line ${currentStep > 2 ? 'active' : ''}`}></div>
            <button className={`step ${currentStep === 3 ? 'active' : ''}`} onClick={() => setCurrentStep(3)}></button> */}
        </div>

        <div className='bottom'>
            <span className='stepName'>Pickup</span>
            <span className='stepName'>Add Items</span>
            {/* <span className='stepName'>Confirm</span> */}
        </div>

    </div>