import React, { useState } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import { useFetch } from '../../../lib/useFetch';
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import { toBase64 } from '../../../lib/helpers';
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, currentPage, reload, isBanner }) => {
  const { data: mobPages } = useFetch(`mobPages?isBannerNavigation=true`)
  const [selectedPage, setSelectedPage] = useState(null)
  const [name, setName] = useState('');
  const [link, setLink] = useState('')
  const [file, setFile] = useState(null);
  const [fileIsSelected, setFileIsSelected] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const uploadMedia = async (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      Alert("please enter a name");
      return;
    }

    if (!file && link.trim() === "") {
      Alert("please upload a video/photo or paste link");
      return;
    }

    let media = { isBanner, name }

    if (isBanner) {
      media.relatedPage = currentPage
      media.navigationPage = selectedPage?.value
    }

    if (!link)
      media.file = await toBase64(file)

    confirmAlert(`add this ${isBanner ? 'banner' : 'video'}?`, () => {
      setIsloading(true);

      if (link)
        PostObject("media/link", { ...media, path: link }).then(() => {
          afterOperation()
        });
      else {
        PostObject("media", media).then(() => {
          setFileIsSelected(false)
          afterOperation()
        });
      }
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    setFile(null)
    setName('')
    setLink('')
  }

  const onMediaDropped = (e) => {
    preventDefaults(e)
    setFile(e.dataTransfer.files[0])
    setFileIsSelected(true)
    return false
  }

  const onDragOver = (e) => {
    preventDefaults(e)

  }

  const onDragLeave = (e) => {
    preventDefaults(e)
  }

  const onDragEnter = (e) => {
    preventDefaults(e)
  }

  const preventDefaults = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const browseFile = (file) => {
    if (!file)
      return

    setFile(file)
    setFileIsSelected(true)
  }

  return (
    <Popup
      show={show}
      className="mediaAdd"
      modalID="addMediaModal"
      title={`Add ${isBanner ? 'Banner' : 'Video'}`}
    >
      <div className='line'></div>

      <form>
        <div className="maxW">
          <Field
            value={name}
            setValue={setName}
            placeholder="write name"
          />

          {fileIsSelected ?
            <div className="selectedMedia">
              <span>{file?.name?.length > 32 ? file.name.substring(0, 30) + '...' : file.name}</span>
              <div onClick={() => setFileIsSelected(false)}>
                <i className="fas fa-times"></i>
              </div>
            </div> :
            <>
              <div className="mediaDropDiv" onDrop={onMediaDropped} onDragOver={onDragOver}
                onDragLeave={onDragLeave} onDragEnter={onDragEnter}>

                <label htmlFor="fileElem">
                  <img src={`/images/${isBanner ? 'uploadPhoto' : 'uploadVideo'}.png`} alt="upload media" />

                  browse your
                  {isBanner ? ' photo ' : ' video '}
                  or drop it here
                </label>

                <input type="file" id="fileElem" multiple accept={isBanner ? "image/*" : "video/*"}
                  onChange={(e) => browseFile(e.target?.files[0])} />
              </div>

              {!isBanner &&
                <>
                  <div className="optionTitle">
                    Or paste video link
                  </div>

                  <Field
                    value={link}
                    setValue={setLink}
                    icon="link"
                    placeholder="paste media link"
                  />
                </>
              }
            </>
          }

          {isBanner &&
            <Select
              className='select'
              placeholder="Select navigation page"
              value={selectedPage}
              onChange={setSelectedPage}
              options={mobPages.map(p => ({ label: p.name, value: p._id }))}
            />
          }
        </div>
        <button className="saveBtn" onClick={uploadMedia} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          Upload
        </button>
      </form>
    </Popup>
  );
};