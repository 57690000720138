import React from 'react';
import Popup from '../../../components/popup';
import UserDetails from '../../../components/userDetails';

export default ({ show, userID, openChat, openPassportModal }) => {
    console.log(show, userID, openChat, openPassportModal)

    return (
        <Popup show={show} modalID='viewUserModal' title='User Info'>
            <UserDetails userID={userID} openChat={openChat} showVerificationContainer={true}
                showPayouts={true} openPassportModal={openPassportModal} />
        </Popup>
    );
}