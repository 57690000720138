import React, { useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import ImageModal from '../../components/imageModal';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import AddEditStory from './addEdit';
import './style.scss';

const ReadMoreData = ({ data }) => {
    const [readMore, setReadMore] = useState(false)

    return (
        <div className='description'>
            {!readMore && data.length > 220 ? data.substring(0, 217) + '...' : data}
            <span onClick={() => setReadMore(r => !r)}>Read {readMore ? 'less' : 'more'}</span>
        </div>
    );
}

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`stories?page=${page}${search ? `&search=${search}` : ''}`, reload)
    const [zoomedImage, setZoomedImage] = useState(null)
    const [editedStoryID, setEditedStoryID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)

    const onDelete = (storyID) => {
        DeleteObject('stories', storyID, () => toggleReload(r => !r))
    }

    return (
        <div className='stories'>
            <Header title="Stories" setSearch={setSearch}>
                <button onClick={() => { setEditedStoryID(null); toggleShowAddEdit(s => !s) }}>
                    Add Story
                </button>
            </Header>

            <div className='row pt-4'>
                {data &&
                    data.map(story =>
                        <Card key={story._id}
                            image={story.photo}
                            setZoomedImage={setZoomedImage}>
                            <div className='mb-2 text-center'>{story.title}</div>
                            <div className='text-center'>{story.countryId.name}, {story.cityId.name}</div>

                            <ReadMoreData data={story.body} />

                            <Controls>
                                <button onClick={() => { setEditedStoryID(story._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => confirmAlert('delete this story?', () => onDelete(story._id))}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            <AddEditStory storyID={editedStoryID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedStoryID(null) }} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} />

            {zoomedImage &&
                <ImageModal photo={zoomedImage} close={() => setZoomedImage(null)} />
            }
        </div>
    );
}