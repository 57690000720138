import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../state/auth';
import './style.scss';

export default ({ title, children, setSearch }) => {
  const [searchText, handleSearchText] = useState()
  const { admin } = useAuth()
  const requestsDelay = useRef(null)
  const lastSearch = useRef(null)

  useEffect(() => {
    if (!setSearch)
      return

    lastSearch.current = searchText
    if (requestsDelay.current)
      return

    requestsDelay.current = setTimeout(() => {
      requestsDelay.current = null

      setSearch(lastSearch.current)
    }, 1500);
  }, [searchText])

  return (
    <div id="header">
      <img src={admin?.photo} alt='profile' className='profile' />

      <div className='notification'>
        <img src='/images/header/bill.png' alt='bill' />
      </div>

      {setSearch &&
        <div className='search'>
          <img src='/images/header/search.svg' alt='search' />
          <input value={searchText} onChange={(e) => handleSearchText(e.target.value)} placeholder='Search' />
        </div>
      }

      <div className='left mt-2 mt-md-0'>
        <span className='pageTitle'>{title}</span>

        <div className='actions mr-0 mr-md-3'>{children}</div>
      </div>
    </div>
  )
}
