import React, { useState } from 'react';
import Header from '../../components/header';
import Card, { Controls } from '../../components/infoCard';
import PaginationBar from '../../components/pagination';
import { useFetch } from '../../lib/useFetch';
import DeleteObject from '../../lib/deleteObject';
import { confirmAlert } from '../../components/alert';
import { pageSize } from '../../lib/constants';
import Tabs from '../../components/typesTabs';
import AddEditStore from './addEdit';

const types = [{ name: 'Normal', value: 'false' }, { name: 'Prime', value: 'true' }]

export default () => {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [isPrime, setIsPrime] = useState('false')
    const [reload, toggleReload] = useState(false)
    const { data, totalCount } = useFetch(`stores?isPrime=${isPrime}&page=${page}${search ? `&search=${search}` : ''}&limit=${pageSize}`, reload)
    const [editedStoreID, setEditedStoreID] = useState(null)
    const [showAddEdit, toggleShowAddEdit] = useState(false)

    const onDelete = (storeID) => {
        DeleteObject('stores', storeID, () => toggleReload(r => !r))
    }

    return (
        <div className='stores'>
            <Header title="Stores" setSearch={setSearch}>
                <button onClick={() => { setEditedStoreID(null); toggleShowAddEdit(s => !s) }}>
                    Add Store
                </button>
            </Header>

            <Tabs tabs={types} selectedTab={isPrime} setSelectedTab={setIsPrime} />

            <div className='row'>
                {data &&
                    data.map(store =>
                        <Card key={store._id}
                            image={store.image}>
                            <div className='mb-3 text-center'>{store.name}</div>

                            <div className='mb-3'>
                                <img src='/images/link.svg' alt='icon' className='icon' />
                                <a href={store.url} target="_blank" rel="noopener noreferrer">Visit Link</a>
                            </div>

                            <Controls>
                                <button onClick={() => { setEditedStoreID(store._id); toggleShowAddEdit(s => !s) }}>
                                    <img src='/images/edit.svg' alt='edit' />
                                </button>

                                <button className='danger' onClick={() => confirmAlert('delete this store?', () => onDelete(store._id))}>
                                    <img src='/images/bin.svg' alt='bin' />
                                </button>
                            </Controls>
                        </Card>
                    )}
            </div>

            <AddEditStore storeID={editedStoreID} show={showAddEdit} hide={() => toggleShowAddEdit(s => !s)}
                reload={() => { toggleReload(r => !r); setEditedStoreID(null) }} />
            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}