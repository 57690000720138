import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../../components/popup";
import UploadFile from "../../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../../components/alert/index";
import PostObject from "../../../../lib/postObject";
import PutObject from "../../../../lib/putObject";
import { toBase64 } from "../../../../lib/helpers";
import { useFetch } from "../../../../lib/useFetch";
import Select from "react-select";
import DatePicker from "../../../../components/dateRangePicker";
import "./style.scss";
import Field from "../../../../components/field";

export default ({ show, hide, userID, reload }) => {
  const { data, loading } = useFetch(`dashboardTrips/${userID}`);
  const { data: countries, loading: countriesLoading } =
    useFetch(`countries/lookup`);
  const { data: travelers, loading: travelersLoading } =
    useFetch(`dashboardTravellers`);
  const { data: cities, loading: citiesIsLoading } = useFetch(`cities`);

  const [TravelerName, setTravelerName] = useState("");
  const [selectedFromCountry, setFromCountry] = useState(null);
  const [selectedToCountry, setToCountry] = useState(null);
  const [selectedFromCity, setFromCity] = useState(null);
  const [selectedTraveler, setTravelers] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [selectedToCity, setToCity] = useState(null);
  const [tripCapacity, setTripCapacity] = useState(0);
  const [maxWeight, setMaxWeight] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const photoRef = useRef();

  useEffect(() => {
    if (data && !loading && countries && !countriesLoading) {
      let defaultCountry = countries.find(
        (c) => c.callingCode === data.user.countryCode
      );
      setTravelerName(data.Trips.userName);
      setTravelers(data.Trips.lastName);
    }
  }, [loading, countriesLoading, citiesIsLoading, travelersLoading]);

  useEffect(() => {
    setTravelerName("");
    setFromCountry("");
    setToCountry("");
    setFromCity(null);
    setToCity("");
    setTravelers("");
    setArrivalDate("");
    setMaxWeight(0);
    setTripCapacity(0);
  }, [show]);
  
  const saveUser = async (e) => {
    e.preventDefault();
    console.log(
      selectedTraveler,
      selectedFromCountry,
      selectedToCountry,
      selectedFromCity,
      arrivalDate,
      selectedToCity
    );

    if (
      !selectedTraveler ||
      !selectedFromCountry ||
      !selectedToCountry ||
      !selectedFromCity ||
      !arrivalDate ||
      !selectedToCity
    ) {
      Alert("please fill all fields");
      return;
    }

    let trip = {
      ownerObj: selectedTraveler.value,
      userName: TravelerName.value,
      fromCityId: selectedFromCity.value,
      toCityId: selectedToCity.value,
      fromCountryId: selectedFromCountry.value,
      toCountryId: selectedToCountry.value,
      tripDate: arrivalDate,
      maxWeight,
      tripCapacity,
    };

    if (userID) trip._id = userID;

    if (photoRef.current.files.length > 0)
      trip.ticketImage = await toBase64(photoRef.current.files[0]);

    confirmAlert(`${userID ? "edit" : "add"} this trip?`, () => {
      setIsloading(true);
      if (!userID)
        PostObject("dashboardTrips", trip).then(() => {
          afterOperation();
        });
      else
        PutObject("dashboardTrips", trip).then((data) => {
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoRef.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="userAddEdit"
      modalID="addEditUserModal"
      title={`${userID ? "Edit" : "Add"} User`}
    >
      <UploadFile ref={photoRef} id="userUpload" />

      <form>
        <div className="row">
          <div className="col-sm-6">
            <div className="label">Arrival Date</div>
            <DatePicker
              // startD={arrivalDate}
              single={true}
              onApply={(date) => setArrivalDate(date)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="maxWeight"
              value={maxWeight}
              setValue={setMaxWeight}
              placeholder="write first name"
              icon="user"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="tripCapacity"
              value={tripCapacity}
              setValue={setTripCapacity}
              placeholder="write first name"
              icon="user"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <Select
              className="select"
              value={selectedFromCountry}
              onChange={setFromCountry}
              options={countries?.map((c) => ({
                label: c.name,
                value: c._id,
                flag: c.countryFlag,
              }))}
              placeholder="from country"
              formatOptionLabel={(country) => (
                <div className="countryList">
                  <img src={country.flag} alt="flag" />
                  <span>{country.label}</span>
                </div>
              )}
            />
          </div>
          <div className="col-sm-3">
            <Select
              className="select"
              value={selectedToCountry}
              onChange={setToCountry}
              options={countries?.map((c) => ({
                label: c.name,
                value: c._id,
                flag: c.countryFlag,
              }))}
              placeholder="to country"
              formatOptionLabel={(country) => (
                <div className="countryList">
                  <img src={country.flag} alt="flag" />
                  <span>{country.label}</span>
                </div>
              )}
            />
          </div>
          <div className="col-sm-3">
            <Select
              className="select"
              value={selectedFromCity}
              onChange={setFromCity}
              options={cities?.map((c) => ({
                label: c.name,
                value: c._id,
                flag: c.photo,
              }))}
              placeholder="from city"
            />
          </div>
          <div className="col-sm-3">
            <Select
              className="select"
              value={selectedToCity}
              onChange={setToCity}
              options={cities?.map((c) => ({
                label: c.name,
                value: c._id,
                flag: c.photo,
              }))}
              placeholder="to city"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Select
              className="select"
              value={selectedTraveler}
              onChange={setTravelers}
              options={travelers?.Travelers?.map((c) => ({
                label: c.firstName + c.firstName,
                value: c._id,
              }))}
              placeholder="traveler"
            />
          </div>
        </div>
        <button className="saveBtn" onClick={saveUser} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {userID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
