import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../../components/popup";
import Field from "../../../../components/field";
import UploadFile from "../../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../../components/alert/index";
import PostObject from "../../../../lib/postObject";
import PutObject from "../../../../lib/putObject";
import { toBase64 } from "../../../../lib/helpers";
import { useFetch } from "../../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, userID, reload, userType }) => {
  const { data, loading } = useFetch(`users/${userID}`);
  const { data: countries, loading: countriesLoading } =
    useFetch(`countries/lookup`);
  const [firstName, setFirstName] = useState("");
  const [type, settype] = useState("");

  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [whatsApp, setwhatsApp] = useState("");
  const [selectedCode, setSelectedCode] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const photoRef = useRef();
  const passportRef = useRef();

  useEffect(() => {
    if (data && !loading && countries && !countriesLoading) {
      let defaultCountry = countries.find(
        (c) => c.callingCode === data.user.countryCode
      );
      console.log(data.user.countryCode);
      console.log(countries);
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
      settype(data.user.type);
      setEmail(data.user.email);
      setSelectedCode({
        label: defaultCountry.callingCode,
        value: defaultCountry.callingCode,
        flag: defaultCountry.countryFlag,
      });
      setPhoneNumber(data.user.phoneNumber);
      setwhatsApp(data.user.whatsApp);
    }
  }, [loading, countriesLoading]);

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setSelectedCode(null);
    settype("");
    setPhoneNumber("");
    setwhatsApp("");
  }, [show]);

  const saveUser = async (e) => {
    e.preventDefault();

    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      phoneNumber.trim() === "" ||
      !selectedCode
    ) {
      Alert("please fill all fields");
      return;
    }

    let user = {
      firstName,
      lastName,
      email,
      phoneNumber,
      countryCode: selectedCode.value,
      whatsApp,
      type,
    };

    if (userID) user._id = userID;

    if (photoRef.current.files.length > 0)
      user.photo = await toBase64(photoRef.current.files[0]);

    confirmAlert(`${userID ? "edit" : "add"} this user?`, async () => {
      setIsloading(true);
      if (userType === "Traveler") {
        if (passportRef.current.files.length > 0)
          user.passportImage = await toBase64(passportRef.current.files[0]);
        if (!userID)
          PostObject("dashboardTravellers", user).then(() => {
            afterOperation();
          });
        else
          PutObject("dashboardTravellers", user).then((data) => {
            if (data) afterOperation();
          });
      } else {
        if (!userID)
          PostObject("dashboardShoppers", user).then(() => {
            afterOperation();
          });
        else
          PutObject("manualUsers", user).then((data) => {
            if (data) afterOperation();
          });
      }

      console.log(user);
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoRef.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="userAddEdit"
      modalID="addEditUserModal"
      title={`${userID ? "Edit" : "Add"} User`}
    >
      <UploadFile ref={photoRef} id="userUpload" />

      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="First Name"
              value={firstName}
              setValue={setFirstName}
              placeholder="write first name"
              icon="user"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Last Name"
              value={lastName}
              setValue={setLastName}
              placeholder="write last name"
              icon="user"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="select type "
              value={type}
              setValue={settype}
              placeholder="traveler/shopper"
              icon="user"
            />
          </div>
          <div className="col-sm-6">
            <Field
              label="Email Address"
              value={email}
              setValue={setEmail}
              placeholder="write email address"
              icon="envelope"
            />
          </div>
          <div className="col-sm-6">
            <div className="fieldDiv">
              <div className="label">Phone Number</div>

              <Select
                className="select"
                value={selectedCode}
                onChange={setSelectedCode}
                options={countries?.map((c) => ({
                  label: c.callingCode,
                  value: c.callingCode,
                  flag: c.countryFlag,
                }))}
                placeholder="Code"
                formatOptionLabel={(country) => (
                  <div className="countryList">
                    <img src={country.flag} alt="flag" />
                    <span>{country.label}</span>
                  </div>
                )}
              />

              <div className="field phoneField">
                <input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="number"
                />
              </div>

              <div className="field whatsAppField">
                <input
                  value={whatsApp}
                  onChange={(e) => setwhatsApp(e.target.value)}
                  placeholder="whatsApp Number"
                />
              </div>
            </div>
          </div>

          {userType === "Traveler" ? (
            <UploadFile
              label="passport Image"
              ref={passportRef}
              id="userUpload"
            />
          ) : null}
        </div>

        <button className="saveBtn" onClick={saveUser} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {userID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
