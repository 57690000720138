import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import { PopupTabs } from '../popup';
import Payouts from './payouts';
import Reviews from './reviews';
import { useFetch } from '../../lib/useFetch';
import PostObject from '../../lib/postObject';
import { confirmAlert } from '../alert';
import Stars from './stars';
import Wallet from './wallet';
import './style.scss';

const tabs = ["Personal Details", "Payouts", "Wallet", "Reviews"]

const VerifiedBox = ({ name, verified }) =>
    <div className='verifiedBox' key={Math.random()}>
        {verified ?
            <i className='fas fa-check-circle'></i> :
            <i className='fas fa-times-circle'></i>
        }
        {name}
    </div>

export default ({ userID, showVerificationContainer, openChat, showPayouts, isChild, openPassportModal }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const { data } = useFetch(`users/${userID}`)

    const sendRate = () => {
        confirmAlert('send rate notification to this user?', () => {
            PostObject(`users/${userID}/sendRate`, {})
        })
    }

    return (
        data &&
        <div className='userDetails'>
            {showPayouts &&
                <PopupTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} isChild={isChild} />
            }

            {selectedTab === 0 &&
                <>
                    <div className='info'>
                        <img src={data.user.photo} alt='profile' className='profile' />

                        <div className='details'>
                            <div className='name'>
                                <span className='username'>{data.user.firstName} {data.user.lastName}</span>
                                <Stars rate={data.user.ratingAsTraveller || data.user.ratingAsShopper} />
                            </div>
                            <div>
                                {data.user.roles.map((role, i) =>
                                    <span key={i}>{role} {i === data.user.roles.length - 1 ? '' : '-'} </span>
                                )}
                            </div>
                            <div>{data.user.username}</div>
                            <div>$ {data.user.balance}</div>
                        </div>
                    </div>

                    <div className='spaceBetween mb-2'>
                        <div>
                            <img src='/images/mail.svg' alt='email' className='icon' />
                            {data.user.email}
                        </div>

                        {data.user.fullPhoneNumber &&
                            <div>
                                <img src='/images/phone.svg' alt='phone' className='icon' />
                                <span className="mr-1">{data.user.fullPhoneNumber}</span>
                            </div>
                        }
                    </div>

                    {data.user.country &&
                        <div>
                            <img src={data.user.country.countryFlag} alt='flag' className='flag' />
                            <span>{data.user.country.name}</span>
                        </div>
                    }

                    <div className='spaceBetween'>
                        <Link to={`/trips/${data.user._id}`} target="_blank" rel="noopener noreferrer"
                            className='greenLink'>
                            Trips
                            <i className="fas fa-long-arrow-alt-right"></i>
                        </Link>

                        <Link to={`/shipments/${data.user._id}`} target="_blank" rel="noopener noreferrer"
                            className='greenLink'>
                            Shipments
                            <i className="fas fa-long-arrow-alt-right"></i>
                        </Link>
                    </div>

                    {openPassportModal &&
                        <div className='viewPassportBtn' onClick={openPassportModal}>
                            View passport
                            <i className="fas fa-long-arrow-alt-right"></i>
                        </div>
                    }

                    {showVerificationContainer &&
                        <div className='spaceBetween verificationContainer'>
                            <div>
                                <VerifiedBox name='Passport Verification' verified={data.user.isPassportVerified} />
                                <VerifiedBox name='Mobile Verification' verified={data.user.isPhoneVerified} />
                            </div>
                            <div>
                                <VerifiedBox name='Email Verification' verified={true} />
                                <VerifiedBox name='Payment Verification' verified={data.user.isPaymentVerified} />
                            </div>
                        </div>
                    }

                    <div className='buttons'>
                        {openChat &&
                            <button onClick={() => openChat({ ID: data.user._id, username: data.user.username, photo: data.user.photo })}>
                                <i className='fas fa-comments'></i>
                                Chat
                            </button>
                        }

                        {data.user.fullPhoneNumber &&
                            <ReactWhatsapp number={data.user.whatsappNumber ? data.user.whatsappCode + data.user.whatsappNumber : data.user.fullPhoneNumber} message=""
                                element={(e) => <button className='green' onClick={e.onClick}>
                                    <i className='fab fa-whatsapp'></i>
                                    Whatsapp
                                </button>}
                            />}

                        <button>
                            <i className="fas fa-envelope"></i>
                            Email
                        </button>
                    </div>

                    <div className='buttons'>
                        <button onClick={sendRate}>
                            <i className="fas fa-star"></i>
                            Send Rate Notification
                        </button>
                    </div>
                </>
            }

            {selectedTab === 1 &&
                <Payouts userID={data.user._id} />
            }

            {selectedTab === 2 &&
                <Wallet userID={data.user._id} />
            }

            {selectedTab === 3 &&
                <Reviews userID={data.user._id} />
            }
        </div>
    );
}