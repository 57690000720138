import React from 'react';
import './style.scss';

const Block = ({ city, country, shrinkText }) =>
    <div className='block'>
        <div className='city'>
            {city.length > 9 && shrinkText ? city.substring(0, 7) + '...' : city}
        </div>
        <div className='country'>{country}</div>
    </div>


export default ({ fromCity, toCity, shrinkText }) => {
    return (
        <div className='tripDestination'>
            <Block city={fromCity.name} country={fromCity.countryName} shrinkText={shrinkText} />

            <img src='/images/tripPlane.svg' alt='plane' />

            <Block city={toCity.name} country={toCity.countryName} shrinkText={shrinkText} />
        </div>
    );
}