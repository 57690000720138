import React, { useState, useRef } from 'react';
import Popup from '../../../components/popup';
import Field from '../../../components/field';
import { Alert, confirmAlert } from '../../../components/alert';
import UploadFile from '../../../components/uploadFile';
import PostObject from '../../../lib/postObject';
import './style.scss';

export default ({ show, hide }) => {
    const [title, setTitle] = useState('')
    const [titleAR, setTitleAR] = useState('')
    const [body, setBody] = useState('')
    const [bodyAR, setBodyAR] = useState('')
    const [isLoading, setIsloading] = useState(false)
    const excelFile = useRef(null)

    const sendBulk = async () => {
        if (title.trim() === '' || titleAR.trim() === '' || body.trim() === '' || bodyAR.trim() === '') {
            Alert('please fill all fields')
            return
        }

        let files = excelFile.current.files
        if (files[0]?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            Alert('only excel sheets are allowed')
            return
        }

        let formdata = new FormData()
        formdata.append("file", excelFile.current.files[0])
        formdata.append("title", title)
        formdata.append("titleAR", titleAR)
        formdata.append("body", body)
        formdata.append("bodyAR", bodyAR)

        confirmAlert(`send this bulk?`, () => {
            setIsloading(true)
            PostObject('notifications/sendBulk', formdata).then(() => {
                setIsloading(false)
                hide()
                setTitle('')
                setTitleAR('')
                setBody('')
                setBodyAR('')
                excelFile.current.value = null
            })
        })
    }

    return (
        <Popup show={show} className='bulkNotification' modalID='bulkNotificationModal' title="Send Bulk">
            <UploadFile ref={excelFile} id="notificationsSheet" accept='.csv,.xlsx' />

            <div className='row'>
                <div className='col-md-6'>
                    <Field setValue={setTitle} value={title} label="Title" placeholder="write title" />

                    <div className='label'>Body</div>
                    <textarea value={body} onChange={(e) => setBody(e.target.value)} placeholder="write body" />
                </div>
                <div className='col-md-6'>
                    <Field setValue={setTitleAR} value={titleAR} label="Arabic Title" placeholder="اكتب العنوان" isRTL={true} />

                    <div className='label'>Arabic Body</div>
                    <textarea value={bodyAR} onChange={(e) => setBodyAR(e.target.value)} placeholder="اكتب الرسالة" dir='rtl' />
                </div>
            </div>

            <button className='saveBtn' onClick={sendBulk} disabled={isLoading}>
                <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                Send</button>
        </Popup>
    );
}