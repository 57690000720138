import React from 'react';
import { render } from 'react-dom'
import App from './App';
import * as serviceWorker from './serviceWorker';
import './scss/index.css';
import 'tabler-react/dist/Tabler.css';
import './scss/App.scss';

render( <App /> , document.getElementById('root'));
serviceWorker.unregister();
