import React, { useEffect, useState, useRef } from "react";
import Popup from "../../../components/popup";
import Field from "../../../components/field";
import UploadFile from "../../../components/uploadFile";
import { Alert, confirmAlert } from "../../../components/alert";
import PostObject from "../../../lib/postObject";
import PutObject from "../../../lib/putObject";
import { toBase64 } from "../../../lib/helpers";
import { useFetch } from "../../../lib/useFetch";
import Select from "react-select";
import "./style.scss";

export default ({ show, hide, storeID, reload }) => {
  const { data, loading } = useFetch(`stores/${storeID}`);
  const { data: countries, loading: countriesLoading } = useFetch(`countries/lookup?withUnions=true`);
  const [selectedCountry, setSelectedCountry] = useState(null)
  const { data: categories, loading: categoriesLoading } = useFetch(`categories/lookup`);
  const [name, setName] = useState("");
  const [url, setURL] = useState("");
  const [priority, setPriority] = useState("");
  const [isPrime, setIsPrime] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const photoFile = useRef();
  const logoFile = useRef();

  useEffect(() => {
    if (data && !loading && categories && !categoriesLoading && countries && !countriesLoading) {
      let defaultCategory = categories.find((c) => c._id === data.categoryId._id);
      setSelectedCategory({ label: defaultCategory.name, value: defaultCategory._id })

      setName(data.name);
      setURL(data.url);
      setPriority(data.priority);
      setIsPrime(data.isPrime || false)

      if (data.isPrime && data.countryId) {
        let defaultCountry = countries.find(c => c._id === data.countryId)
        setSelectedCountry({ label: defaultCountry.name, value: defaultCountry._id })
      }
    }
  }, [loading, categoriesLoading, countriesLoading]);

  useEffect(() => {
    if (storeID)
      return

    setName("");
    setURL("");
    setPriority("");
    setSelectedCategory(null);
    setIsPrime(false)
    setSelectedCountry(null)
  }, [show, storeID]);

  const saveStore = async (e) => {
    e.preventDefault();

    if (
      name.trim() === "" ||
      url.trim() === "" ||
      !Number(priority) ||
      !selectedCategory ||
      (!selectedCountry && isPrime)
    ) {
      Alert("please fill all fields");
      return;
    }

    let store = {
      name,
      url,
      priority,
      categoryId: selectedCategory.value,
      countryId: isPrime ? selectedCountry.value : null,
      isPrime,
    };

    if (photoFile.current.files.length > 0)
      store.image = await toBase64(photoFile.current.files[0]);
    if (logoFile.current.files.length > 0)
      store.logo = await toBase64(logoFile.current.files[0]);

    if (storeID) store._id = storeID;

    confirmAlert(`${storeID ? "edit" : "add"} this store?`, () => {
      setIsloading(true);

      if (!storeID)
        PostObject("stores", store).then(() => {
          afterOperation();
        });
      else
        PutObject("stores", store).then((data) => {
          setIsloading(false);
          if (data) afterOperation();
        });
    });
  };

  const afterOperation = () => {
    setIsloading(false);
    reload();
    hide();
    photoFile.current.value = null;
    logoFile.current.value = null;
  };

  return (
    <Popup
      show={show}
      className="storeAddEdit"
      modalID="addEditStoreModal"
      title={`${storeID ? "Edit" : "Add"} Store`}
    >
      <div className='images'>
        <div>
          <div className='label'>Image</div>
          <UploadFile ref={photoFile} id="photoUpload" />
        </div>

        <div>
          <div className='label'>Logo</div>
          <UploadFile ref={logoFile} id="logoUpload" />
        </div>
      </div>

      <form>
        <div className="row">
          <div className="col-sm-6">
            <Field
              label="Name"
              value={name}
              setValue={setName}
              placeholder="write name"
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Link"
              value={url}
              setValue={setURL}
              placeholder="write link"
            />
          </div>

          <div className='col-sm-6'>
            <div className='label'>Category</div>
            <Select
              className='select'
              value={selectedCategory}
              onChange={setSelectedCategory}
              options={categories?.map(c => ({ label: c.name, value: c._id }))}
            />
          </div>

          <div className="col-sm-6">
            <Field
              label="Priority"
              value={priority}
              setValue={setPriority}
              placeholder="write priority"
            />
          </div>

          <div className="col-sm-6">
            <div className="primeDiv">
              <label className='label isPrimeLabel' htmlFor="isPrime">Is Prime</label>
              <input id="isPrime" type='checkbox' checked={isPrime} onChange={e => setIsPrime(e.target.checked)} />
            </div>
          </div>

          {isPrime &&
            <div className="col-sm-6">
              <Select
                className='select'
                value={selectedCountry}
                onChange={setSelectedCountry}
                options={countries?.map(c => ({ label: c.name, value: c._id }))}
                placeholder="Country"
              />
            </div>
          }
        </div>

        <button className="saveBtn" onClick={saveStore} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          {storeID ? "Edit" : "Add"}
        </button>
      </form>
    </Popup>
  );
};
