export async function validatePhoto(file) {
  if (!file || !file[0].type.includes('image'))
    return false

  return await toBase64(file[0])
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const formatDate = (date, includeTime) => {
  let tmpDate = new Date(date)
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return `${tmpDate.getDate()}, ${months[tmpDate.getMonth()]} ${tmpDate.getFullYear()}` + 
  (includeTime? `  ${tmpDate.getHours()}:${tmpDate.getMinutes()}`:'')
}