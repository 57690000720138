import React, { useEffect, useState } from "react";
import Popup from "../../../components/popup";
import { confirmAlert } from "../../../components/alert";
import PatchObject from "../../../lib/patchObject";
import Select from "react-select";
import './style.scss';

export default ({ show, hide, reload, order, states }) => {
  const [selectedState, setSelectedState] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (order)
      setSelectedState({ label: order.state, value: order.state })
  }, [order?.state])

  const saveEdit = async (e) => {
    e.preventDefault();

    confirmAlert("edit state of this order?", () => {
      setIsloading(true);

      PatchObject("scrapping/order", { _id: order._id, state: order.state }).then((data) => {
        setIsloading(false);
        if (data) {
          hide()
          reload()
        }
      });
    });
  };

  return (
    <Popup
      show={show}
      className="orderEdit"
      modalID="orderEditModal"
      title="Edit Order"
    >
      <hr />

      <form>
        <div className="mb-3">Order Status</div>
        <Select
          className='select'
          value={selectedState}
          onChange={setSelectedState}
          options={states.map(s => ({ label: s, value: s }))}
        />

        <button className="saveBtn" onClick={saveEdit} disabled={isLoading}>
          <span
            className={isLoading ? "spinner-border spinner-border-sm" : ""}
            role="status"
            aria-hidden="true"
          ></span>
          Edit
        </button>
      </form>
    </Popup>
  );
};
