import React, { useState } from 'react';
import { useFetch } from '../../../lib/useFetch';
import { formatDate } from '../../../lib/helpers';
import PaginationBar from '../../pagination';
import Stars from '../stars';
import './style.scss';

const pageSize = 3

export default ({ userID }) => {
    const [page, setPage] = useState(1)
    const { data, totalCount } = useFetch(`ratings?user=${userID}&page=${page}&limit=${pageSize}`)

    const format = (date) => {
        let diff = Math.ceil((new Date() - new Date(date)) / (1000 * 60 * 60 * 24))

        if (diff < 30) {
            return diff + ' days ago'
        }

        return formatDate(date, true)
    }

    return (
        <div className='reviews'>
            {data && data.map((review, i) =>
                <div key={i} className="review">
                    <div className='userData'>
                        <img src={review.ownerObj?.photo} alt="profile" />

                        <div className='data'>
                            <div className='username'>{review.ownerObj?.username}</div>
                            <div className='date'>{format(review.createdAt)}</div>
                        </div>
                    </div>

                    <div className='comment'>{review.comment}</div>

                    <Stars rate={review.rate} />

                    <hr />
                </div>
            )}

            <PaginationBar page={page} totalCount={totalCount} setPage={setPage} pageSize={pageSize} />
        </div>
    );
}