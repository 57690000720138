import React from 'react';
import './style.scss';

export default ({ payoutInfo }) =>
    <div className='payoutInfo'>
        <div className='mt-4 row'>
            <div className='col-6'>
                <div className='label'>Holder Name</div>
                <div className='data'>{payoutInfo.holderName}</div>
            </div>
            <div className='col-6'>
                <div className='label'>Account Number</div>
                <div className='data'>{payoutInfo.eWalletNo || payoutInfo.accountNo}</div>
            </div>
        </div>

        {payoutInfo.payoutMethod !== 'Wallet' &&
            <>
                <div className='mt-4 row'>
                    <div className='col-6'>
                        <div className='label'>Bank Name</div>
                        <div className='data'>{payoutInfo.bankName}</div>
                    </div>
                    <div className='col-6'>
                        <div className='label'>IBAN</div>
                        <div className='data'>{payoutInfo.iban}</div>
                    </div>
                </div>
                <div className='mt-4 row'>
                    <div className='col-6'>
                        <div className='label'>Swift Code</div>
                        <div className='data'>{payoutInfo.bankCode}</div>
                    </div>

                    <div className='col-6'>
                        <div className='label'>Country</div>
                        <div className='data'>{payoutInfo.country?.name}</div>
                    </div>
                </div>
            </>
        }
    </div>